import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { faker } from '@faker-js/faker';
import { UserDetails } from '../../models/user/user-details.model';
import { Settings } from '../../models/settings/settings.model';
import { Site } from '../../models/sites/site.model';
import { GetUserResponse } from '../../models/user/get-user-response.model';
import moment from 'moment';
import { LocalStorageService } from '../common/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardServiceMock {

  // Services
  localStorageService: LocalStorageService = inject(LocalStorageService);

  getSitesForDashboard(): Observable<Site[]> {

    const sites: Site[] = [];

    for (let i = 0; i < 5; ++i) {
      const site: Site = {
        Id: i,
        Name: faker.location.city(),
        Sequence: i,
        IsDefault: i === 3 ? true : false
      }

      sites.push(site);
    };

    return of(sites)
  }

  getUserDetails(): Observable<UserDetails> {

    const user: GetUserResponse = {
      User: {
        UserId: faker.number.int(),
        UserName: faker.internet.email(),
        DisplayName: faker.person.fullName(),
        MasterCompanyId: faker.number.int(),
        TimeZoneId: 'GMT Standard Time',
      },
      CompanyDateFormat: {
        Id: 1,
        Long: "dd MMMM yyy",
        Medium: "dd MMM yyyy",
        MomentLong: "DD MMMM YYYY",
        MomentMedium: "DD MMM YYYY",
        MomentShort: "DD/MM/YYYY",
        MomentTiny: "DD/MM",
        Name: "dd/mm/yyyy",
        PrimeLong: "dd MM yy",
        PrimeMedium: "dd M yy",
        PrimeShort: "dd/mm/yy",
        PrimeTiny: "dd/mm",
        Short: "dd/MM/yyyy",
        Tiny: "dd/MM",
      },
      CompanyTimeFormat: {
        Id: 1,
        Long: "HH:mm:ss",
        Name: "24 Hour",
        Short: "HH:mm"
      }
    }

    return of(this.logUserDetails(user));
  }

  logUserDetails(userDetails: GetUserResponse): UserDetails {
    // get list of logged users from local storage
    let existingUsers: UserDetails[] = this.localStorageService.getItem('users');

    let newUserDetails: UserDetails;
    let existingUser: UserDetails;

    if (existingUsers) {

      // See if the user that just logged in exists in the existing users list
      existingUser = existingUsers.find((user: UserDetails) => {
        return user.UserId === userDetails.User.UserId;
      }) as UserDetails;

      // If it exists, log the user in local storage with previous settings
      // ELSE - create new user settings and then log user and add them to users list
      if (existingUser) {
        this.localStorageService.setItem('user', existingUser);
        return existingUser;
      } else {
        newUserDetails = this.setUserDetails(userDetails);
        existingUsers.push(newUserDetails);
        this.localStorageService.setItem('users', existingUsers);
        return newUserDetails;
      }

    } else {
      newUserDetails = this.setUserDetails(userDetails);
      existingUsers = [newUserDetails];
      this.localStorageService.setItem('users', existingUsers);
      return newUserDetails
    }
  }

  setUserDetails(loginDetails: GetUserResponse): UserDetails {
    const userDetails: UserDetails = {
      UserId: loginDetails.User.UserId,
      UserName: loginDetails.User.UserName,
      DisplayName: loginDetails.User.DisplayName,
      LastLogin: moment().toDate(),
      MasterCompanyId: loginDetails.User.MasterCompanyId,
      TimeZoneId: loginDetails.User.TimeZoneId,
      Settings: new Settings({
        ThemeId: 1,
        FontSizeId: 2,
        HighContrast: false,
        HapticFeedback: true,
        Sounds: true,
        DateFormat: loginDetails.CompanyDateFormat,
        TimeFormat: loginDetails.CompanyTimeFormat
      }),
    };

    this.localStorageService.setItem('user', userDetails);
    return userDetails;
  }
}
