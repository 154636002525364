import { inject, Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { AuthData } from '../../models/auth/auth-data.model';
import { faker } from '@faker-js/faker';
import { UserDetails } from '../../models/user/user-details.model';
import { LocalStorageService } from '../common/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationServiceMock {

  // Services
  localStorageService: LocalStorageService = inject(LocalStorageService);

  confirmResetPassword(): Observable<null> {
    return of(null);
  }

  beginResetPassword(): Observable<null> {
    return of(null);
  }

  login(username: string, password: string): Observable<AuthData> {
    if (password !== 'factory123') {
      return throwError(() => new Error());
    }

    const auth: AuthData = {
      access_token: faker.string.uuid(),
      expires: moment().add(1, 'days').toDate(),
      userName: username,
      issued: moment().toDate(),
      trial: false,
      error: null,
      error_description: null,
    };

    this.localStorageService.setItem('authData', auth);
    return of(auth);
  }

  pinLogin(userDetails: UserDetails, pin: number): Observable<AuthData> {
    if (pin !== 1379) {
      return throwError(() => new Error());
    }

    const auth: AuthData = {
      access_token: faker.string.uuid(),
      expires: moment().add(1, 'days').toDate(),
      userName: userDetails.UserName,
      issued: moment().toDate(),
      trial: false,
      error: null,
      error_description: null,
    };

    this.localStorageService.setItem('authData', auth);
    return of(auth);
  }
}
