<div class="flex" id="noIconAccordion">
  <p-accordion class="w-full flex flex-column gap-3"
               [activeIndex]="0">
    @for (trolley of trolleyItems; track trolley.ProductId; let i = $index) {
    <p-accordionTab id="{{ pageSourceName + '_' + trolley?.Id }}_tab"
                    class="cybake-lot-tab bottom-border-radial flex flex-row cursor-pointer bg-white items-center relative border border-slate-300 justify-content-between">
      <ng-template pTemplate="header">
        <div id="{{ pageSourceName + '_' + trolley.Id }}_trolleyCard"
             class="flex flex-row cursor-pointer w-full items-center relative justify-content-between">
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center gap-2">
              <p id="{{ pageSourceName + '_' + trolley.Id }}_productCardTitle"
                 class="font-semibold"
                 cybake-text-style="body-small">
                {{ trolley.Name }}
              </p>

              <i (click)="deleteItem(i)"
                 class="fa-solid fa-trash-can text-red-600"></i>
            </div>
            <div class="flex flex-row align-items-center gap-2">
              <p id="{{
                    pageSourceName + '_' + trolley.Id
                  }}_trolleyCardSubtitle"
                 class="italic ellipsis"
                 cybake-text-style="body-xsmall">
                {{ trolley.ProductCode }}
              </p>
              @if (trolley.Weight || trolley.Measure === 'singles') {
              <div class="flex flex-row align-items-center gap-2">
                <p class="text-4xl">·</p>
                <p class="flex flex-row align-items-center gap-2">
                  <span cybake-text-style="body-small">{{ 'TrolleyList.Total'  | translate }}</span>
                  <span class="font-semibold" cybake-text-style="body-small">
                    {{
                      calculateTotalWeight(trolley)
                    }}{{
                        trolley.Measure === 'singles' ? ' ' : ''
                    }}{{ trolley.Measure }}
                  </span>
                </p>
              </div>
              }
              @if (trolley?.IsTraceable) {
              <cybake-factory-tag [pageSourceName]="pageSourceName"
                                  [identifier]="'cybakeCardTag'"
                                  value="TrolleyList.TraceableLabel">
              </cybake-factory-tag>
              }
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <cybake-factory-image [pageSourceName]="productPhotoImage.PageSourceName"
                                  [identifier]="productPhotoImage.Identifier"
                                  [value]="trolley.ImageUrl"
                                  [width]="productPhotoImage.Width"
                                  [height]="productPhotoImage.Height"
                                  [formatDataURI]="productPhotoImage.ImageFormatDataURI">
            </cybake-factory-image>
          </div>
        </div>
      </ng-template>
      @for (location of trolley.Locations; track location.Id; let j = $index) {
      <div class=" px-3 pb-3">
        <p class="p-2 border-round-sm bg-gray-50 text-900 font-bold">{{ 'TrolleyList.Location' | translate }}: {{ location?.Name }}</p>
      </div>
      <div class="flex flex-column gap-2 px-3 pb-3">
        <div class="flex flex-row align-items-center justify-content-between bg-white px-2">
          @if (
          checkTrolleyHasPropertyOccurrence(location.Lots, 'SystemLotNumber')
          ) {
          <p cybake-text-style="body-xsmall" class="font-bold">
            {{ 'TrolleyList.LotNumber' | translate }}
          </p>
          }
          @if (
          checkTrolleyHasPropertyOccurrence(location.Lots, 'ContainerName')
          ) {
          <p cybake-text-style="body-xsmall" class="font-bold">
            {{
                'TrolleyList.Container' | translate
            }}
          </p>
          }
          <div class="flex flex-row gap-4 align-items-center">
            <p cybake-text-style="body-xsmall" class="font-bold">{{ 'TrolleyList.Quantity' | translate }}</p>
          </div>
        </div>
        @for (lot of location.Lots; track lot; let k = $index) {
        <div class="flex flex-row {{ selectingMultipleStockLines?'gap-1':''}} align-items-center">
          @if (selectingMultipleStockLines){
          <p-checkbox [(ngModel)]="selectedStockLines"
                      name="lotLine"
                      variant="filled"
                      [value]="lot" />
          }
          <cybake-factory-trolley-card class="w-full" [lot]="lot"
                                       (editEvent)="editLot(i,j, k, lot)"
                                       (deleteEvent)="deleteLot(lot)"></cybake-factory-trolley-card>

        </div>
        }
      </div>
      }
    </p-accordionTab>
    }
  </p-accordion>
</div>
