import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { CyBakeButtonComponent } from '../button/button.component';
import { BarcodeService } from '../../services/barcode.service';
import { BarcodeData } from '../../models/barcode/barcode-data.model';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataService } from '../../services/common/data.service';
import { ToastMessage } from '../../models/cybake/toast/toast-message.model';
import { ToastSeverity } from '../../models/cybake/toast/toast-severity.enum';

@Component({
  selector: 'cybake-factory-barcode-scan',
  standalone: true,
  imports: [
    ZXingScannerModule,
    CyBakeButtonComponent,
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  templateUrl: './barcode-scan.component.html',
  styleUrl: './barcode-scan.component.scss'
})
export class BarcodeScanComponent {

  // Inputs/Outputs
  @Input() pageSourceName: string = '';
  @Input() identifier: string = '';
  @Input() loading: boolean = false;

  @Input() outputFunction!: string;

  @Output() barcodeScannedEvent: EventEmitter<boolean> = new EventEmitter();

  // Services
  dataService: DataService = inject(DataService);
  barcodeService: BarcodeService = inject(BarcodeService);

  // Variables
  torchAvailable: boolean = false;
  enableTorch: boolean = false;
  scannerEnabled: boolean = true;
  desiredDevice: MediaDeviceInfo | undefined = undefined;
  scanResult?: string;
  availableDevices: MediaDeviceInfo[] = [];
  hasDevices?: boolean;
  hasPermission: boolean =  true;
  torchCompatible: boolean =  false;

  scanningResultButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'barcodeResult',
    TranslationKey: 'BarcodeScanner.CancelScanningResultBtn',
    Loading: true,
    Class: ButtonClassEnum.default
  });

  enableTorchButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'toggleTorch',
    IconKey: ['fa-duotone', 'fa-bolt'],
    IconClass: 'barcode-torch-icon-toggle',
    Rounded: true,
    Class: ButtonClassEnum.override
  });

  switchCamerasButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'toggleCameras',
    IconKey: ['fa-duotone', 'fa-arrows-rotate'],
    Rounded: true,
    Class: ButtonClassEnum.override
  });

  // Barcode Formats
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  onTorchCompatible(torchCompatible: boolean) {
    console.log('onTorchCompatible', torchCompatible);
    this.torchCompatible = torchCompatible;
  }

  onCamerasFound(devices: MediaDeviceInfo[]) {
    console.log('availableDevices', devices);
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  camerasNotFoundHandler($event: unknown) {
    console.log('camerasNotFoundHandler', $event);
  }

  scanSuccessHandler(scanResult: string) {
    this.loading = true;

    this.scanResult = scanResult;
    
    this.barcodeService.readBarcode(scanResult).subscribe({
      next: (response: BarcodeData) => {
        console.log('readBarcode response', response);
        if (!response.IsValid) {
          this.loading = false;
          this.dataService.openToast(new ToastMessage({
            Severity: ToastSeverity.warn,
            Detail: response.ValidationMessage
          }))
          return;
        }
        this.dataService.barcodeOutput(response);
      },
      error: () => {
        this.loading = false;
      },
    });

    console.log('scanSuccessHandler', scanResult);
    this.handleResult();
  }

  scanErrorHandler($event: unknown) {
    console.log('scanErrorHandler', $event);
  }

  scanFailureHandler($event: unknown) {
    console.log('scanFailureHandler', $event);
  }

  scanCompleteHandler($event: unknown) {
    console.log('scanCompleteHandler', $event);
  }

  onHasPermission(has: boolean) {
    console.log('onHasPermission', has);
    this.hasPermission = has;
  }

  handleResult() {

  }

  cancelBarcodeRequest() {

  }

  retryBarcodeScan() {

  }

  onDeviceSelectChange() {
    if (this.availableDevices.length) {
      if (this.availableDevices.length > 1) {
        if (this.desiredDevice === this.availableDevices[0]) {
          this.desiredDevice = this.availableDevices[1];
        } else {
          this.desiredDevice = this.availableDevices[0];
        }        
      } else {
        this.desiredDevice = this.availableDevices[0];
      }
    }
  }

  toggleTorch() {
    this.enableTorch = !this.enableTorch;
  }

}
