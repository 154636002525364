import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StockLocationType } from '../../_shared/models/stock-transfers/stocks/stock-location-types.model';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { StockLocation } from '../../_shared/models/stock-transfers/stocks/stock-location.model';
import { AccordionComponent } from '../../_shared/components-review/accordion/accordion.component';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { CyBakeInputTextComponent } from '../../_shared/components/input-text/input-text.component';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { CyBakeInputText } from '../../_shared/models/cybake/input/input-text.model';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';
import { CardStyleEnum } from '../../_shared/models/cybake/card/card-style.enum';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'cybake-factory-select-transfer-source',
  standalone: true,
  imports: [
    CyBakeButtonComponent,
    AccordionComponent,
    CyBakeInputTextComponent,
    CyBakeCardComponent,
    CheckboxModule
  ],
  templateUrl: './select-transfer-source.component.html',
  styleUrl: './select-transfer-source.component.scss'
})
export class SelectTransferSourceComponent {
  pageSourceName: string = 'selectTransferSource';
  searchText: string = '';

  confirmButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'selecrTransferSourceBtn',
    Class: ButtonClassEnum.default,
    TranslationKey: 'StockTransferSource.Confirm',
    Disabled: true,
    FullWidth: true,
  });

  // Text Input Model
  searchTextInput: CyBakeInputText = new CyBakeInputText({
    Value: null,
    PageSourceName: this.pageSourceName,
    Identifier: 'sourceSearch',
    TranslationKey: 'StockTransferSource.SearchLocation',
    IconKey: ['fa-duotone', 'fa-magnifying-glass'],
    Placeholder: true,
    FullWidth: true,
    Autofocus: true
  });


  // card component config
  breadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'selectTransferBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light,
    HideCardHeaderhideCardHeader: true,
  });

  // card component config
  locationCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'selectLocationCard',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'p-4'
  });


  @Input() showSearch: boolean = false;
  @Input() stockLocationTypes: StockLocationType[] = [];
  @Input() loadingLocations: boolean = true;
  @Input() selectedtransferLocation? : number = 0;
  @Output() selectLocationEvent: EventEmitter<StockLocation> = new EventEmitter<StockLocation>();
  @Output() continueEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectLocation(location: StockLocation) {
    this.selectLocationEvent.emit(location);
  }

  onContinue() {
    this.continueEvent.emit(true);
  }

}
