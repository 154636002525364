<cybake-factory-dialog [pageSourceName]="pageSourceName"
                       [identifier]="identifier"
                       [title]="title"
                       [type]="dialogType"
                       [(visible)]="visible"
                       [closable]="closable"
                       (hideEvent)="this.visibleChange.emit(false)"
                       [width]="dialogWidth"
                       [dialogContentTemplate]="dialogSelectContentTemplate">

  <ng-template #dialogSelectContentTemplate>

    <div class="grid m-0">

      <div class="col-12 p-0 pb-2">
        <cybake-factory-listbox [pageSourceName]="pageSourceName"
                                [identifier]="identifier"
                                [options]="options"
                                [(value)]="value"
                                [loading]="loading"
                                [failed]="failed"
                                [optionLabel]="optionLabel"
                                [optionLabel2]="optionLabel2"
                                [optionValue]="optionValue"
                                [filter]="filterListbox"
                                [filterBy]="filterListboxBy"
                                [height]="listboxHeight">
        </cybake-factory-listbox>
      </div>

      @if (additionalButton) {
      <div class="col-12 p-0 pt-2">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               [identifier]="additionalButton.Identifier"
                               [translationKey]="additionalButton.TranslationKey"
                               [type]="additionalButton.Type"
                               [class]="additionalButton.Class"
                               [iconKey]="additionalButton.IconKey"
                               [fullWidth]="true"
                               (clickEvent)="additionalButton1ClickEvent.emit()">
        </cybake-factory-button>
      </div>
      }

      <div class="col-12 p-0 pt-2">
        <cybake-factory-button [pageSourceName]="confirmButton.PageSourceName"
                               [identifier]="confirmButton.Identifier"
                               [translationKey]="confirmButton.TranslationKey"
                               [type]="confirmButton.Type"
                               [class]="confirmButton.Class"
                               [fullWidth]="true"
                               [disabled]="(value === null || value === undefined)"
                               (clickEvent)="confirm(value)">
        </cybake-factory-button>
      </div>

    </div>

  </ng-template>

</cybake-factory-dialog>
