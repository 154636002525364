<div [routerLink]="!disabled ? '/' + link : null"
     id="{{pageSourceName}}_{{identifier}}_tile"
     class="cybake-tile bottom-border-radial flex flex-row cursor-pointer h-28 bg-white pl-2 md:pl-3 items-center relative border justify-content-between"
     [ngClass]="{'disabled-tile': disabled}">

  <div class="flex flex-row gap-2 md:gap-3 items-center">

    <i id="{{pageSourceName}}_{{identifier}}_tile_icon"
       class="fa-icon {{iconKey}} text-2xl text-black"></i>
    <p class="text-base font-semibold">{{ title | translate }}</p>

  </div>

  @if (!disabled) {
  <span class="arrow-span mr-2">
    <i id="{{pageSourceName}}_{{identifier}}_tile_chevron"
       class="fa-icon fa-thin fa-angle-right text-4xl text-gray-600"></i>
  </span>
  }

</div>
