import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true
})

export class SortByPipe implements PipeTransform {
  transform<T>(array: T[], field: keyof T, ascending: boolean = true): T[] {

    if (!Array.isArray(array) || !field) {
      return array;
    }

    return array.sort((a, b) => {
      if (a[field] < b[field]) {
        return ascending ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  }
}
