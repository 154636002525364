import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { SkeletonModule } from 'primeng/skeleton';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { SentenceCasePipe } from '../../pipes/sentence-case.pipe';
import { ButtonFloatEnum } from '../../models/cybake/button/button-float.enum';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'cybake-factory-button',
  standalone: true,
  imports: [
    SkeletonModule,
    CommonModule,
    FormsModule,
    SentenceCasePipe,
    TranslateModule,
    TooltipModule,
    RippleModule,
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class CyBakeButtonComponent implements OnInit, OnChanges {

  // Children
  @ViewChild('cybakeButton') buttonRef!: HTMLButtonElement;

  // Services
  translate = inject(TranslateService);

  // Inputs/Outputs
  @Input() pageSourceName: string = 'pageSourceNameNotSet';
  @Input() identifier: string = 'identifierNotSet';

  @Input() translationKey?: string;
  @Input() translationFromData?: boolean = false;
  @Input() translationParams: object = {};
  @Input() prefix?: string;

  @Input() loading: boolean | undefined = false;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() hidden: boolean = false;

  @Input() failed: boolean = false;
  @Output() failedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = 'top';
  @Input() tooltipFromData: boolean = false;

  @Input() float: ButtonFloatEnum | undefined;
  @Input() class: ButtonClassEnum | string = ButtonClassEnum.default;
  @Input() customClass?: string;
  @Input() type: ButtonTypeEnum = ButtonTypeEnum.default;
  @Input() rounded: boolean = false;
  @Input() pulse: boolean = false;
  @Input() overrideCenterAlign: boolean = false;
  @Input() raisedButton: boolean = true;

  @Input() iconKey?: string[];
  @Input() bigIcon?: boolean = false;
  @Input() iconClass?: string;

  @Output() clickEvent: EventEmitter<null> = new EventEmitter();
  @Output() disabledClickEvent: EventEmitter<null> = new EventEmitter();

  @Input() countIndicatorValue: number = 0;

  // Variables
  button: CyBakeButton = new CyBakeButton({
    Class: ButtonClassEnum.success,
    TranslationKey: '',
    PageSourceName: '',
    Identifier: '',
    FullWidth: false,
    Type: ButtonTypeEnum.default,
    Loading: false,
  });
  prefixText: string = '';
  buttonClassPrefix: string = '';
  floatClass?: string;
  floatButtonClass: string = '';

  // Fixed icon keys for different button types
  addType = 'fa-solid';
  addKey = 'fa-plus';

  editType = 'fa-solid';
  editKey = 'fa-pencil';

  deleteType = 'fa-solid';
  deleteKey = 'fa-trash-can';

  searchType = 'fa-solid';
  searchKey = 'fa-magnifying-glass';

  outputType = 'fa-solid';
  outputKey = 'fa-print';

  saveType = 'fa-solid';
  saveKey = 'fa-floppy-disk';

  undoType = 'fa-solid';
  undoKey = 'fa-rotate-left';

  ngOnInit() {
    this.setIcon();

    if (this.float) {
      this.floatButtonClass = 'p-3';
      switch (this.float) {
        case 'topLeft':
          this.floatClass = 'fixed top-1 left-1';
          break;
        case 'topRight':
          this.floatClass = 'fixed top-1 right-1';
          break;
        case 'bottomLeft':
          this.floatClass = 'fixed bottom-1 left-1';
          break;
        case 'bottomRight':
          this.floatClass = 'fixed bottom-1 right-1';
          break;
      }
    } else {
      this.floatButtonClass = '';
      this.floatClass = '';
    }
  }

  ngOnChanges() {
    this.setIcon();
  }

  executeClickEvent() {
    this.failedChange.emit(false);
    this.clickEvent.emit();
  }

  setIcon() {
    this.prefixText = '';
    switch (this.type!.toString()) {
      case 'add':
        this.iconKey = [];
        this.iconKey.push(this.addType);
        this.iconKey.push(this.addKey);
        this.class = ButtonClassEnum.success;
        this.prefixText = 'CyBakeTemplates.Button.AddButtonPrefix';
        break;
      case 'edit':
        this.iconKey = [];
        this.iconKey.push(this.editType);
        this.iconKey.push(this.editKey);
        this.class = ButtonClassEnum.warning;
        this.prefixText = 'CyBakeTemplates.Button.EditButtonPrefix';
        break;
      case 'delete':
        this.iconKey = [];
        this.iconKey.push(this.deleteType);
        this.iconKey.push(this.deleteKey);
        this.class = ButtonClassEnum.danger;
        break;
      case 'search':
        this.iconKey = [];
        this.iconKey.push(this.searchType);
        this.iconKey.push(this.searchKey);
        break;
      case 'output':
        this.iconKey = [];
        this.iconKey.push(this.outputType);
        this.iconKey.push(this.outputKey);
        break;
      case 'save':
        this.iconKey = [];
        this.iconKey.push(this.saveType);
        this.iconKey.push(this.saveKey);
        this.class = ButtonClassEnum.success;
        break;
      case 'undo':
        this.iconKey = [];
        this.iconKey.push(this.undoType);
        this.iconKey.push(this.undoKey);
        this.class = ButtonClassEnum.warning;
        break;
      default:
        break;
    }
  }
}
