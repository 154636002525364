<div class="pt-2">

  <cybake-factory-card [title]="'Production.ProductionPlanRecipeLineCard.RecordedMeasuesLabel'"
                       [pageSourceName]="pageSourceName"
                       [identifier]="'productionPlanItemRecipeLineRecordedMeasures'"
                       [cardContentTemplate]="recordedMeasuresContentTemplate"
                       [toggleableContent]="true">
  </cybake-factory-card>

  <ng-template #recordedMeasuresContentTemplate>
    <div class="production-recordedMeasures-list">

      @for ((measure of measures);track measure;let index = $index) {

      <div class="inline-flex w-full production-recorded-measure pr-2 pl-2 text-lg"
           style="height: 50px"
           (click)="this.editRecipeLineUsedEvent.emit(measure)">

        @if (selectedUsedLineId === measure.Id) {
        <div class="pr-2">
          <i class="fa-duotone fa-solid fa-pencil c-warning vertically-center"></i>
        </div>
        }

        @if (measure.Id && measure.SystemLotNumber) {
        <div class="inline-flex w-full">

          <div class="vertically-center-left-align">
            {{ measure.SystemLotNumber }}
          </div>
        </div>
        }

        <div [ngClass]="{ 'w-full': !measure.Id || !measure.SystemLotNumber  }">
          <div class="vertically-center-left-align">
            <span class="pr-2">{{ measure.Quantity }}</span>{{ parentRecipeLine.Measure }}
          </div>
        </div>

        @if (!parentRecipeLine.Completed) {
        <div class="pl-3">
          <i class="fa-icon fa-thin fa-angle-right text-gray-600 vertically-center text-4xl"></i>
        </div>
        }

      </div>

      }

      @if (!measures.length) {
      <div class="text-center w-full p-3">
        {{'Production.ProductionPlanRecipeLineCard.NoMeasuresRecordedLabel' | translate}}
      </div>
      }

    </div>
  </ng-template>
</div>
