import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Listbox, ListboxChangeEvent, ListboxModule } from 'primeng/listbox';
import { CyBakeInputTextComponent } from '../input-text/input-text.component';
import { CyBakeInputText } from '../../models/cybake/input/input-text.model';

@Component({
  selector: 'cybake-factory-listbox',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ListboxModule,
    TranslateModule,
    CyBakeInputTextComponent,
  ],
  templateUrl: './listbox.component.html',
  styleUrl: './listbox.component.scss',
})
export class ListboxComponent {
  // Children
  @ViewChild('cybakeListbox') listboxRef!: Listbox;

  // Inputs/Outputs
  @Input() options: unknown[] = [];
  @Input() value!: unknown;
  @Output() valueChange: EventEmitter<unknown> = new EventEmitter();

  @Input() pageSourceName: string = '';
  @Input() identifier: string = '';

  @Input() height?: number = 210;

  @Input() translationKey: string = '';
  @Input() translationParams: object = {};

  @Input() emptyTranslationKey: string = '';
  @Input() emptyTranslationParams: object = {};
  @Input() emptyIcon: string = '';

  @Input() loading?: boolean = false;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() placeholder: boolean = false;
  @Input() checkbox: boolean = false;
  @Input() radioButtons: boolean = false;
  @Input() filter?: boolean = false;
  @Input() group: boolean = false;
  @Input() required: boolean = false;

  @Input() failed?: boolean = false;
  @Output() failedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() optionLabel?: string = 'Name';
  @Input() optionLabel2?: string;
  @Input() optionValue?: string;
  @Input() optionDisabled: string = '';
  @Input() filterBy?: string;
  @Input() class: string = '';
  @Input() optionGroupLabel: string = '';
  @Input() optionGroupChildren: string = '';

  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = 'top';
  @Input() tooltipFromData: boolean = false;

  @Output() changeEvent: EventEmitter<ListboxChangeEvent> = new EventEmitter();

  // Variables
  filterValue = '';

  listboxFilterTextInput: CyBakeInputText = new CyBakeInputText({
    Value: null,
    PageSourceName: this.pageSourceName,
    Identifier: 'listboxSearch',
    TranslationKey: 'CyBakeTemplates.Listbox.SearchInputPlaceholder',
    IconKey: ['fa-solid', 'fa-magnifying-glass'],
    Placeholder: true
  });

  // Functions

  filterListbox(filterValue: string) {
    this.listboxRef.filterValue = filterValue;
  }
}
