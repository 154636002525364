<p-card
  styleClass="p-2 m-2 pt-0 mt-4 user-details-card"
  [ngClass]="{ 'user-details-card-no-body': !hasSites && !loadingSites }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex w-full text-end">
      <div class="w-full pt-3 pb-2 pl-1" style="max-width: calc(100% - 52px);">
        <div class="vertically-center-left-align font-semibold text-2xl" [innerHTML]="'UserDetails.WelcomeLabel' | translate: { displayName: userDetails.DisplayName }"></div>
      </div>

      @if (!hasSites && !loadingSites) {
        <div>
          <cybake-factory-button
            [pageSourceName]="pageSourceName"
            [identifier]="settingsButton.Identifier"
            [class]="settingsButton.Class"
            [iconKey]="settingsButton.IconKey"
            [iconClass]="settingsButton.IconClass"
            [raisedButton]="false"
            (clickEvent)="openSettings()"
          ></cybake-factory-button>
        </div>
      }

      <div style="padding-bottom: 0.75rem !important">
        <p-avatar
          label="{{ getInitials(userDetails.DisplayName) }}"
          styleClass="ml-1"
          size="large"
          [style]="{
            'background-color': '#2196F3',
            color: '#ffffff',
            'margin-top': '-15px'
          }"
        ></p-avatar>
      </div>
    </div>
  </ng-template>

  @if (hasSites || loadingSites) {
    <div class="user-details-card-footer">
      <div
        class="w-full"
        [ngClass]="{ 'inline-flex': hasSites || loadingSites, 'text-right': !hasSites }"
      >
        @if (loadingSites) {
          <div class="w-full">
            <p-skeleton class="w-full pr-2 vertically-center" height="38px" />
          </div>
        }

        @if (hasSites && !loadingSites) {
          <div class="w-full">
            <cybake-factory-button
              [pageSourceName]="pageSourceName"
              [identifier]="locationButton.Identifier"
              [prefix]="!isMobilePortrait ? locationButton.Prefix : undefined"
              [translationKey]="deviceSite ? deviceSite.Name : ''"
              [translationFromData]="true"
              [class]="locationButton.Class"
              [iconKey]="locationButton.IconKey"
              [iconClass]="locationButton.IconClass"
              [raisedButton]="false"
              (clickEvent)="openLocations()"
            ></cybake-factory-button>
          </div>
        }

        <div>
          <cybake-factory-button
            [pageSourceName]="pageSourceName"
            [identifier]="settingsButton.Identifier"
            [class]="settingsButton.Class"
            [iconKey]="settingsButton.IconKey"
            [iconClass]="settingsButton.IconClass"
            [raisedButton]="false"
            (clickEvent)="openSettings()"
          ></cybake-factory-button>
        </div>
      </div>
    </div>
  }
</p-card>

<cybake-factory-dialog-select [pageSourceName]="pageSourceName"
                              [identifier]="sitesDialogSelect.Identifier"
                              [options]="sitesDialogSelect.Options"
                              [title]="sitesDialogSelect.Title"
                              [closable]="!missingSetLocation"
                              [loading]="sitesDialogSelect.Loading"
                              [failed]="sitesDialogSelect.Failed"
                              [(visible)]="sitesDialogSelect.Visible"
                              [(value)]="deviceSiteId"
                              [optionLabel]="sitesDialogSelect.OptionLabel"
                              [optionValue]="sitesDialogSelect.OptionValue"
                              (confirmEvent)="comfirmLocation()">
</cybake-factory-dialog-select>

<!--<cybake-factory-dialog
  [(visible)]="displayLocations"
  [identifier]="'sites'"
  [width]="25"
  
  [type]="userLocationsPrompOptions.Type"
  [title]="userLocationsPrompOptions.Title"
  [dialogContentTemplate]="confirmationPromptContentTemplate"
>
  <ng-template #confirmationPromptContentTemplate>
    <cybake-factory-user-location
      [currentSite]="deviceSite"
      (comfirmSite)="locationSet($event)"
    >
    </cybake-factory-user-location>
  </ng-template>
</cybake-factory-dialog>-->
