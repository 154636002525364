import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { environment } from '../../../../environments/environment';
import { CyBakeButtonComponent } from '../../../_shared/components/button/button.component';
import { CyBakeCardComponent } from '../../../_shared/components/card/card.component';
import { StockTransferLotContainer } from '../../../_shared/models/stock-transfers/product/container.model';
import { Trolley } from '../../../_shared/models/stock-transfers/product/trolley.model';
import { StockTransfersServiceMock } from '../../../_shared/services/mock-services/stock-transfers.service.mock.';
import { StockTransfersService } from '../../../_shared/services/stock-transfers.service';
import { LotCardComponent } from '../lot-card/lot-card.component';
import { TrolleyItem } from '../../../_shared/models/stock-transfers/product/trolley-item.model';
import { CyBakeCard } from '../../../_shared/models/cybake/card/card.model';

@Component({
  selector: 'cybake-factory-lots-list',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    CyBakeButtonComponent,
    TranslateModule,
    LotCardComponent,
    CyBakeCardComponent
  ],
  templateUrl: './lots-list.component.html',
  styleUrl: './lots-list.component.scss',
})
export class LotsListComponent {

  // Inputs/Outputs
  @Input() lots: TrolleyItem[] = [];
  @Input() containers: StockTransferLotContainer[] = [];
  @Output() transferEvent: EventEmitter<TrolleyItem> = new EventEmitter<TrolleyItem>();
  @Input() basketItems: Trolley[] = [];
  @Input() loading: boolean = false
  @Input() pageSourceName: string = '';


  // card component config
  loadingCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'lotListLoadingCard',
    Title: ''
  });

  // Services
  translateService: TranslateService = inject(TranslateService);
  stockTransfersService: StockTransfersService | StockTransfersServiceMock = environment.mock ? inject(StockTransfersServiceMock) : inject(StockTransfersService);

  handleValueChange(item: TrolleyItem) {
    this.transferEvent.emit(item);
  }

  // Returns the lot items for the lot in basket
  returnItem(_lot: TrolleyItem) {
    if (!this.basketItems || (this.basketItems && !this.basketItems.length)) {
      return undefined;
    }
    const indexedProduct = this.basketItems.find((product) =>
      product.Lots?.some((lot) => lot.SystemLotNumber === _lot.SystemLotNumber),
    );
    return indexedProduct;
  }
}
