<div class="flex flex-column gap-3">

  @if(containers.length) {

  <div (click)="openPopup()"
       class="px-3 active:bg-gray-100"
       id="{{ pageSourceName }}_selectToggle">

    <div class="flex flex-row cursor-pointer justify-content-between border-1 border-slate-300 rounded-lg p-2 align-items-center">
      <p class="text-black" cybake-text-style="body-small">
        {{ selectedContainer ? selectedContainer.ContainerDisplayName : ('StockTransfer.SelectOption' | translate) }}
      </p>
      <i class="fa-solid fa-chevron-down text-black font-semibold"></i>
    </div>

  </div>

  <!--Container Select Dialog-->
  <cybake-factory-dialog-select [pageSourceName]="containerDialogSelect.PageSourceName"
                                [identifier]="containerDialogSelect.Identifier"
                                [options]="containerDialogSelect.Options"
                                [title]="containerDialogSelect.Title"
                                [(visible)]="containerDialogSelect.Visible"
                                [(value)]="selectedContainerId"
                                [optionLabel]="containerDialogSelect.OptionLabel"
                                [optionValue]="containerDialogSelect.OptionValue"
                                (confirmEvent)="confirmContainer()">
  </cybake-factory-dialog-select>
  }

  <cybake-factory-input-number [(value)]="count"
                               (changeEvent)="handleCountValueChange($event)"
                               [pageSourceName]="valueNumberInput.PageSourceName"
                               [identifier]="valueNumberInput.Identifier"
                               [min]="valueNumberInput.Min"
                               [showSteppers]="valueNumberInput.ShowSteppers">
  </cybake-factory-input-number>
  <div class="px-3 pb-4 flex flex-column gap-2">
    <cybake-factory-button [translationKey]="inCart() ? 'LotCard.UpdateTransfer': addToTransferButton.TranslationKey"
                           [fullWidth]="!!addToTransferButton.FullWidth"
                           [identifier]="addToTransferButton.Identifier"
                           [type]="inCart() ? defaultButtonType:addToTransferButton.Type"
                           [class]="addToTransferButton.Class"
                           [pageSourceName]="addToTransferButton.PageSourceName"
                           (clickEvent)="onTransferClicked()"
                           [disabled]="!count">
    </cybake-factory-button>
    @if(inCart()){
    <cybake-factory-button
                           [translationKey]="cancelButton.TranslationKey"
                           [fullWidth]="!!cancelButton.FullWidth"
                           [identifier]="cancelButton.Identifier"
                           (clickEvent)="removeItem()"
                           [pageSourceName]="cancelButton.PageSourceName"
                           [class]="cancelButton.Class"
                           [disabled]="!!cancelButton.Disabled">
    </cybake-factory-button>
    }
  </div>
</div>
