import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ProductionPlan } from '../../_shared/models/production/plans/production-plan.model';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CyBakeProgressPieComponent } from '../../_shared/components/progress-pie/progress-pie.component';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';

@Component({
  selector: 'cybake-factory-production-plan-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeCardComponent,
    CyBakeProgressPieComponent
  ],
  templateUrl: './production-plan.component.html',
  styleUrl: './production-plan.component.scss'
})
export class ProductionPlanCardComponent implements OnInit {

  // Inputs/Outputs
  @Input() plan!: ProductionPlan;
  @Input() index!: number;

  @Output() viewPlanNoteEvent: EventEmitter<null> = new EventEmitter();
  @Output() viewPlanEvent: EventEmitter<null> = new EventEmitter();

  // Services
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  // Variables
  pageSourceName: string = 'productionPlanCard';

  productionPlanCard!: CyBakeCard;

  // Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  ngOnInit() {
    this.productionPlanCard = new CyBakeCard({
      PageSourceName: this.pageSourceName,
      Identifier: 'productionPlan' + this.plan.Id,
      Title: this.plan.Name,
      SubTitle: this.plan.Note,
      RouterLink: true
    });

    // Breakpoint Observer
    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((state: BreakpointState) => {
        this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
        this.isMobileLandscape =
          state.breakpoints[Breakpoints.HandsetLandscape];
        this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
        this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
      });
  }
}
