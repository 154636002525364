<div class="pb-2">
  @if (stockTransferOptions?.selectedProduct) {
  <cybake-factory-card [title]="locationCard.Title"
                       [subTitle]="locationCard.SubTitle"
                       [pageSourceName]="locationCard.PageSourceName"
                       [image]="locationCard.Image"
                       (longPressEvent)="viewProductInfo(stockTransferOptions?.selectedProduct?.ImageUrl || '')"
                       (imageClickEvent)="viewProductInfo(stockTransferOptions?.selectedProduct?.ImageUrl || '')"
                       [overrideImageClick]="locationCard.OverrideImageClick"
                       [pImageClass]="locationCard.ImageClass"
                       [identifier]="locationCard.Identifier"
                       [tagValue]="locationCard.TagValue">
  </cybake-factory-card>
  }

</div>
  @if (stockTransferOptions?.selectedProduct &&
  !stockTransferOptions?.selectedProduct?.IsTraceable && !loadingContainers) {


<div id="{{ pageSourceName + '_lot' }}_tab"
     class="cybake-lot-tab bottom-border-radial pt-4 flex flex-column cursor-pointer bg-white items-center relative border border-slate-300 justify-content-between">

  <cybake-factory-lot-card (transferEvent)="handleValueChange($event)"
                           [basketLot]="basketLot"
                           [containers]="containers"
                           class="w-full">
  </cybake-factory-lot-card>
</div>
  }
@if ( stockTransferOptions?.selectedProduct && stockTransferOptions?.selectedProduct?.IsTraceable) {
<cybake-factory-lots-list [lots]="lots"
                          [loading]="loadingContainers"
                          [pageSourceName]="pageSourceName"
                          [containers]="containers"
                          (transferEvent)="handleValueChange($event)"
                          [basketItems]="basketItems!">
</cybake-factory-lots-list>
}
@if(!stockTransferOptions?.selectedProduct?.IsTraceable && loadingContainers){
<div class="flex flex-col gap-2">
  @for ( item of [1,2,3,4]; track item ) {
  <cybake-factory-card [loading]="loadingCard.Loading" [identifier]="loadingCard.Identifier" [pageSourceName]="loadingCard.PageSourceName"></cybake-factory-card>
  }
</div>
}
