<!--<div class="toast-alert-icon toast-alert-success toast-alert-animate-success-icon" style="display: flex;">
  <div class="toast-alert-success-circular-line-left" style="background-color: transparent;"></div>
  <span class="toast-alert-success-line-tip"></span>
  <span class="toast-alert-success-line-long"></span>
  <div class="toast-alert-success-ring"></div>
  <div class="toast-alert-success-fix" style="background-color: transparent;"></div>
  <div class="toast-alert-success-circular-line-right" style="background-color: transparent;"></div>
</div>-->
<!--<div class="toast-alert-icon toast-alert-error toast-alert-animate-error-icon" style="display: flex;">
  <span class="toast-alert-x-mark">
    <span class="toast-alert-x-mark-line-left"></span>
    <span class="toast-alert-x-mark-line-right"></span>
  </span>
</div>-->
<!--<p-toast position="bottom-center"></p-toast>-->

<p-toast position="bottom-center"
         key="cybakeToast"
         [preventDuplicates]="true"
         [baseZIndex]="5000">

  <ng-template let-message pTemplate="message">

    <div class="w-full">

      <!--Success-->
      @if(!toastMessage.CustomIconKey && message.severity === 'success') {
      <div class="">
        <div class="vertically-center">
          <div class="toast-alert-icon toast-alert-success toast-alert-animate-success-icon" style="display: flex; font-size: 50%;">
            <div class="toast-alert-success-circular-line-left" style="background-color: transparent; font-size: 100%;"></div>
            <span class="toast-alert-success-line-tip" style="font-size: 100%;"></span>
            <span class="toast-alert-success-line-long" style="font-size: 100%;"></span>
            <div class="toast-alert-success-ring" style="font-size: 100%;"></div>
            <div class="toast-alert-success-fix" style="font-size: 100%;"></div>
            <div class="toast-alert-success-circular-line-right" style="font-size: 100%;"></div>
          </div>
        </div>
      </div>
      }

      <!--Error-->
      @if(!toastMessage.CustomIconKey && message.severity === 'error') {
      <div style="font-size: .5rem;">
        <div class="toast-alert-icon toast-alert-error toast-alert-animate-error-icon m-auto" style="display: flex;">
          <span class="toast-alert-x-mark">
            <span class="toast-alert-x-mark-line-left"></span>
            <span class="toast-alert-x-mark-line-right"></span>
          </span>
        </div>
      </div>
      }

      <!--Warning-->
      @if(!toastMessage.CustomIconKey && message.severity === 'warn') {
      <div style="font-size: .5rem;">
        <div class="sa" style=" background: transparent;">
          <div class="sa-warning m-auto">
            <div class="sa-warning-body"></div>
            <div class="sa-warning-dot"></div>
          </div>
        </div>
      </div>
      }

      <!--Info-->
      @if(!toastMessage.CustomIconKey && message.severity === 'info') {
      <div style="font-size: .5rem;">
        <div class="sa toast-sa-info sa-icon-show">
          <div class="sa-info-content">i</div>
        </div>
      </div>
      }

      <!--Custom-->
      @if(message.severity === 'custom') {
      105

      @if(toastMessage.CustomIconKey) {
      <div>
        <i class="fa-icon {{toastMessage.CustomIconKey[0]}} {{toastMessage.CustomIconKey[1]}}"></i>
      </div>
      }

      }

      <!--Toast Text-->
      <div class="w-full c-{{message.severity}}">

        <div class="w-full text-xl pt-3"
             [ngClass]="{'vertically-center': !message.summary, 'pb-3': toastMessage.RetryOutputMethod}">
          {{message.detail}}
        </div>

      </div>

      @if (toastMessage.Closable && !toastMessage.RetryOutputMethod) {
      <div class="cursor-pointer" (click)="closeToast(message.key)">
        <i class="fa-icon fa-solid fa-xmark"></i>
      </div>
      }

    </div>

    @if(toastMessage.RetryOutputMethod) {
    <div class="w-full inline-flex">
      <div class="w-full pr-1">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               [identifier]="'toastRetry'"
                               [translationKey]="'Toast.RetryButton'"
                               [iconKey]="['fa-solid','fa-rotate-right']"
                               [customClass]="'toast-button'"
                               [class]="'success'"
                               [fullWidth]="true"
                               (clickEvent)="retryMethod()">
        </cybake-factory-button>
      </div>

      <div class="w-full pl-1">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               [identifier]="'toastClose'"
                               [translationKey]="'Toast.CloseButton'"
                               [fullWidth]="true"
                               [customClass]="'toast-button'"
                               (clickEvent)="closeToast(message.key)">
        </cybake-factory-button>
      </div>
    </div>
    }

  </ng-template>

</p-toast>
