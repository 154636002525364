import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { ProductionPlanProduct } from '../../_shared/models/production/plans/production-plan-product.model';
import { CyBakeInputNumberComponent } from '../../_shared/components/input-number/input-number.component';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';

@Component({
  selector: 'cybake-factory-print-labels',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeInputNumberComponent,
    CyBakeButtonComponent
  ],
  templateUrl: './print-labels.component.html',
  styleUrl: './print-labels.component.scss'
})
export class PrintLabelsComponent {

  // Inputs/Outputs
  @Input() product!: ProductionPlanProduct;
  @Input() expiryDate!: string;

  @Output() comfirmPrintLabels: EventEmitter<number> = new EventEmitter<number>();

  // Variables
  pageSourceName: string = 'printLabels';
  labelQuantity: number = 1;

  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    TranslationKey: 'Production.PrintLabels.PrintLabelsButton',
    Identifier: 'confirm',
    Type: ButtonTypeEnum.output,
    Class: ButtonClassEnum.default,
  })

}
