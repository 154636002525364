import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductionPlanProductMix } from '../../_shared/models/production/plans/production-plan-recipe-line-mix.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeProgressPieComponent } from '../../_shared/components/progress-pie/progress-pie.component';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';

@Component({
  selector: 'cybake-factory-production-plan-product-mix-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeCardComponent,
    CyBakeProgressPieComponent
  ],
  templateUrl: './production-plan-product-mix.component.html',
  styleUrl: './production-plan-product-mix.component.scss'
})
export class ProductionPlanProductMixCardComponent implements OnInit {

  // Inputs/Outputs
  @Input() mix!: ProductionPlanProductMix;
  @Input() index!: number;

  @Output() viewProductionPlanProductRecipeMixEvent: EventEmitter<null> = new EventEmitter();

  // Variables
  pageSourceName: string = 'productionPlanProductMixCard';
  productionPlanProductMixCard!: CyBakeCard;

  ngOnInit() {
    this.productionPlanProductMixCard = new CyBakeCard({
      Title: 'Production.Mixes.MixCardTitle',
      TitleParams: { mixNumber: this.mix.MixNumber, totalWeight: this.mix.TotalWeight, measure: this.mix.Measure },
      PageSourceName: this.pageSourceName,
      Identifier: 'productionPlanItemMix' + this.mix.Id,
      RouterLink: true
    });
  }
}
