<div class="grid m-0">

  <div class="col-12 pb-0 inline-flex">
    <div class="font-semibold">{{'Production.PrintLabels.ProductNameLabel' | translate}}</div>: <div class="pl-1">{{product.ProductName}}</div>
  </div>

  <div class="col-12 pb-0 inline-flex">
    <div class="font-semibold">{{'Production.PrintLabels.ProductCodeLabel' | translate}}</div>: <div class="pl-1">{{product.ProductCode}}</div>
  </div>

  <div class="col-12 pb-0 inline-flex">
    <div class="font-semibold">{{'Production.PrintLabels.QuantityLabel' | translate}}</div>: <div class="pl-1">{{product.TotalNumberOfLines}}</div>
  </div>

  <div class="col-12 pb-0 inline-flex">
    <div class="font-semibold">{{'Production.PrintLabels.CybakeLotLabel' | translate}}</div>: <div class="pl-1">8472938462384</div>
  </div>

  <div class="col-12 pb-0 inline-flex">
    <div class="font-semibold">{{'Production.PrintLabels.ExpiryLabel' | translate}}</div>: <div class="pl-1">{{expiryDate}}</div>
  </div>

  <div class="col-12 pb-2 pt-2">
    <cybake-factory-input-number [pageSourceName]="pageSourceName"
                                 [identifier]="'labelQuantity'"
                                 [(value)]="labelQuantity"
                                 [label]="'Production.PrintLabels.NumberOfLabelsLabel'"
                                 [min]="1"
                                 [max]="99"
                                 [showSteppers]="true">
    </cybake-factory-input-number>
  </div>

  <div class="col-12">
    <cybake-factory-button [pageSourceName]="pageSourceName"                           
                           [identifier]="confirmButton.Identifier"
                           [translationKey]="confirmButton.TranslationKey"
                           [class]="confirmButton.Class"
                           [iconClass]="confirmButton.IconClass"
                           [fullWidth]="true"
                           (clickEvent)="this.comfirmPrintLabels.emit(labelQuantity)">
    </cybake-factory-button>
  </div>

</div>
