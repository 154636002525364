import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { environment } from '../../../../environments/environment';
import { DialogSelectComponent } from '../../../_shared/components/dialog-select/dialog-select.component';
import { CyBakeButtonComponent } from '../../../_shared/components/button/button.component';
import { CyBakeInputNumberComponent } from '../../../_shared/components/input-number/input-number.component';
import { ButtonClassEnum } from '../../../_shared/models/cybake/button/button-class.enum';
import { ButtonTypeEnum } from '../../../_shared/models/cybake/button/button-type.enum';
import { CyBakeButton } from '../../../_shared/models/cybake/button/button.model';
import { StockTransferLotContainer } from '../../../_shared/models/stock-transfers/product/container.model';
import { Trolley } from '../../../_shared/models/stock-transfers/product/trolley.model';
import { StockTransferOptions } from '../../../_shared/models/stock-transfers/stocks/stock-transfer-options.model';
import { DataService } from '../../../_shared/services/common/data.service';
import { StockTransfersServiceMock } from '../../../_shared/services/mock-services/stock-transfers.service.mock.';
import { StockTransfersService } from '../../../_shared/services/stock-transfers.service';
import { DialogSelectModel } from '../../../_shared/models/cybake/dialog-select/dialog-select.model';
import { TrolleyItem } from '../../../_shared/models/stock-transfers/product/trolley-item.model';
import { CyBakeInputNumber } from '../../../_shared/models/cybake/input/input-number.model';

@Component({
  selector: 'cybake-factory-lot-card',
  standalone: true,
  imports: [
    AccordionModule,
    CyBakeButtonComponent,
    TranslateModule,
    DialogSelectComponent,
    NgTemplateOutlet,
    CyBakeInputNumberComponent,
  ],
  templateUrl: './lot-card.component.html',
  styleUrl: './lot-card.component.scss',
})

export class LotCardComponent implements OnInit, AfterViewInit {

  // Children
  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<unknown>;

  // Services
  dataService: DataService = inject(DataService);
  translateService: TranslateService = inject(TranslateService);
  cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  stockTransfersService: StockTransfersService | StockTransfersServiceMock = environment.mock ? inject(StockTransfersServiceMock) : inject(StockTransfersService);

  // Inputs/Outputs
  @Input() containers: StockTransferLotContainer[] = [];
  @Input() basketLot?: Trolley;
  @Input() lot!: TrolleyItem;
  @Input() pageSourceName: string = '';
  @Input() lotIndex: number = 0;

  @Output() transferEvent: EventEmitter<TrolleyItem> = new EventEmitter<TrolleyItem>();

  // Variables
  selectedContainer?: StockTransferLotContainer;
  selectedContainerId?: number;
  filtered!: Trolley[];
  basketItems!: Trolley[];
  count: number = 0;
  stockTransferOptions!: StockTransferOptions;
  defaultButtonType: ButtonTypeEnum = ButtonTypeEnum.default;
  visible: boolean = false;

  // Cancel Button
  cancelButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'RemoveItemBtn',
    Class: ButtonClassEnum.danger,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
    TranslationKey: 'LotCard.RemoveFromTransfer',
    Disabled: false,
    FullWidth: true
  });

  // Add to Transfer button
  addToTransferButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.add,
    PageSourceName: this.pageSourceName,
    Identifier: 'addToTransferBtn',
    Class: ButtonClassEnum.success,
    TranslationKey: 'LotCard.ToTransfer',
    Disabled: true,
    FullWidth: true,
  });

  // Dialog Select Component
  containerDialogSelect: DialogSelectModel = new DialogSelectModel({
    PageSourceName: this.pageSourceName,
    Identifier: 'LotCardContainet',
    Options: [],
    Title: 'LotCard.SelectContainer',
    Visible: false,
    OptionLabel: 'ContainerDisplayName',
    OptionValue: 'ProductToContainerId'
  });



  // Text Input Model
  valueNumberInput: CyBakeInputNumber = new CyBakeInputNumber({
    PageSourceName: this.pageSourceName,
    Identifier: 'ItemCountInput',
    Value:0,
    Min: 0,
    ShowSteppers: true,
    Placeholder: true,
    FullWidth: true,
  });

  ngOnInit() {
    this.containerDialogSelect.Options = this.containers;
  }

  ngAfterViewInit() {
    // Subscribe to the basket items
    this.dataService.basketItems$.subscribe((basketLot: Trolley[] | null) => {
      this.basketItems = basketLot || [];
      this.loadLot()
    });
  }

  loadLot() {
    // Get stock transfer options
    this.dataService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.stockTransferOptions = stockTransferOptions;
        }
      },
    );

    if (this.containers.length && !this.selectedContainer) {
      // if no items are found, set the first container as selected value
      this.selectedContainer = this.containers[0];
      this.selectedContainerId = this.selectedContainer!.ProductToContainerId;
    }

    //console.log(this.basketLot)

    const filteredTrolleyItem = this.basketLot?.Lots?.find((item: TrolleyItem) =>
        this.lot?.Id == (item?.LotId ?? null) &&
        item?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
        item?.ContainerId == (this.selectedContainer?.ProductToContainerId ? this.selectedContainer?.ProductToContainerId : null) &&
        item?.SourceLocationId == this.stockTransferOptions?.selectedLocation?.Id
    );
    //console.log(filteredTrolleyItem)

    // ---- Logic to set the count value ----

    // If the lot is in the basket, set the count to the lot quantity
    if (filteredTrolleyItem) {

      if (
        this.lot?.Id == (filteredTrolleyItem?.LotId ?? null) &&
        filteredTrolleyItem?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
        filteredTrolleyItem?.SourceLocationId == this.stockTransferOptions?.selectedLocation?.Id &&
        filteredTrolleyItem?.ContainerId == (this.selectedContainer?.ProductToContainerId ? this.selectedContainer?.ProductToContainerId : null)
      ) {
        this.count = filteredTrolleyItem.Quantity || 0;
      }
    }

    //if the lot is not in the basket, set the count to 0

    if (this.containers.length && !this.selectedContainer) {
      this.selectedContainer = this.containers[0];
    }
    this.cdRef.detectChanges();
  }

  // Check the count value
  checkCount() {
    // get the filtered trolley
    const filteredTrolley = this.basketItems?.find(
      (item: Trolley) =>
        item.ProductId == this.stockTransferOptions?.selectedProduct?.Id,
    );
    // check for the item in the filtered trolley
    const filteredTrolleyItem =
      filteredTrolley &&
      filteredTrolley.Lots.find(
        (item: TrolleyItem) =>
          this.lot?.Id == (item?.LotId ?? null) &&
          item?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
          item?.ContainerId == (this.selectedContainer?.ProductToContainerId || null) &&
          item?.SourceLocationId == this.stockTransferOptions?.selectedLocation?.Id
      );
    this.count = (filteredTrolleyItem ? filteredTrolleyItem.Quantity : 0) || 0;
    this.addToTransferButton.Disabled = !this.count;
    this.cdRef.detectChanges();

  }

  // Check if the lot is in the basket
  inCart() {
    let status = false;
    status = this.doesObjectExist(this.basketItems!);
    return status;
  }

  // Check if the lot exists in the basket || non - traceable
  doesObjectExist(array: Trolley[]) {
     
    return array?.some((item) => {
      return item.Lots.some((lot) => {
        return (
          this.lot?.Id == (lot?.LotId ?? null) &&
          item?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
          lot?.ContainerId == (this.selectedContainer?.ProductToContainerId ? this.selectedContainer?.ProductToContainerId: null) &&
          lot?.SourceLocationId == this.stockTransferOptions?.selectedLocation?.Id
        );
      });
    });
  }

  // Remove the lot from the basket
  removeItem() {
    const updatedItems = this.removeLotIfExists(this.basketItems!);
    this.dataService.updateBasketItemsSource(updatedItems);
    this.count = 0;
  }

  // Remove the lot from the basket || non - traceable
  removeLotIfExists(array: Trolley[]): Trolley[] {
    const lotIndexInfo = this.getLotIndex();
    // If no matching lot is found, return the original array
    if (lotIndexInfo === -1) {
      return array;
    }

    const { trolleyIndex, lotIndex } = lotIndexInfo;

    return array
      .map((item, tIndex) => {
        if (tIndex === trolleyIndex) {
          // Remove the lot at the found index
          const newLots = item.Lots.filter((_, lIndex) => lIndex !== lotIndex);
          return { ...item, Lots: newLots };
        }
        return item; // Return the other trolleys unchanged
      })
      .filter((item) => item.Lots.length > 0); // Remove trolleys with no lots
  }


  openPopup() {
    this.containerDialogSelect.Visible = true;
  }

  // Remove the lot from the basket || traceable
  removeLotIfExists2(array: Trolley[], obj: TrolleyItem) {
    return array.map((item) => {
      const newLots = item.Lots.filter(
        (lot) =>
          !(
            lot.Id === obj.Id &&
            lot.ContainerId === this.selectedContainer?.ProductToContainerId
          ),
      );
      return { ...item, Lots: newLots }
    })
      .filter((item) => item.Lots.length > 0); // Remove trolleys with no lots
  }

  // Get the index of the lot in the basket
  getLotIndex(): { trolleyIndex: number; lotIndex: number } | -1 {
    let trolleyIndex = -1;
    let lotIndex = -1;

    const found = this.basketItems?.some((trolley, tIndex) => {
      return trolley.Lots.some((lot, lIndex) => {
        const isMatch =
          this.lot?.Id == (lot?.LotId ?? null) && trolley?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
          lot?.ContainerId == this.selectedContainer?.ProductToContainerId &&
          lot?.SourceLocationId == this.stockTransferOptions?.selectedLocation?.Id
        if (isMatch) {
          trolleyIndex = tIndex; // Store the trolley index
          lotIndex = lIndex; // Store the lot index
          return true; // Stop iteration once a match is found
        }

        return false;
      });
    });

    return found ? { trolleyIndex, lotIndex } : -1;
  }

  confirmContainer() {

    this.selectedContainer = (this.containerDialogSelect.Options as StockTransferLotContainer[]).find((container: StockTransferLotContainer) => {
      return container.ProductToContainerId === this.selectedContainerId;
    }) as StockTransferLotContainer;

    if (this.lot) {
      this.lot.ContainerId = this.selectedContainer!.ProductToContainerId;
      this.lot.ContainerName = this.selectedContainer!.ContainerDisplayName;
    };

    this.checkCount()
  }

  // Handle the count value change
  handleCountValueChange(value: number) {
    this.addToTransferButton.Disabled = !value;
    this.count = value;
  }

  // Handle the add to transfer button click
  onTransferClicked() {
    const lot: TrolleyItem = {
      Id: this.lot?.Id ?? undefined,
      LotId: this.lot?.Id ?? null,
      SystemLotNumber: this.lot?.SystemLotNumber ?? '',
      ExpiryDate: this.lot?.ExpiryDate ?? '',
      ContainerId: this.selectedContainer?.ProductToContainerId ?? null,
      Weight: this.selectedContainer?.Weight ? (this.selectedContainer?.Weight * this.count) : 0,
      //'Single' below is a default value if no container name, no need to be translated
      ContainerName: this.selectedContainer?.ContainerDisplayName ?? 'Single',
      Quantity: this.count,
      ProductId: this.stockTransferOptions?.selectedProduct?.Id
    }
    if (this.count == 0 && this.inCart()) {
      this.removeItem();
    } else {
      this.transferEvent.emit(lot);
    }
  }
}
