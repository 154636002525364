import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Image, ImageModule } from 'primeng/image';

@Component({
  selector: 'cybake-factory-image',
  standalone: true,
  imports: [
    ImageModule,
    TranslateModule,
    CommonModule
  ],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent {
  // Services
  translate = inject(TranslateService);

  // Children
  @ViewChild('image') imageRef: Image | undefined;

  // Inputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() value!: string | SafeResourceUrl | undefined;
  @Input() altText?: string;
  @Input() pImageClass?: string;
  @Input() verticalAlign?: string;

  @Input() width: number = 43;
  @Input() height: number = 43;

  @Input() preview: boolean = true;
  @Input() overrideClick?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() failed?: boolean = false;
  @Input() formatDataURI: boolean = false;

  @Output() clickEvent: EventEmitter<null> = new EventEmitter();
  @Output() failedClickEvent: EventEmitter<null> = new EventEmitter();

  imageClick() {
    if (this.failed) {
      this.failedClickEvent.emit();
      return;
    }

    if (this.overrideClick) {
      this.clickEvent.emit();
    }
  }
}
