<div class="cybake-page-background h-full overflow-y-auto">

  <div class="">
    <cybake-factory-user-details [hasSites]="hasSites"
                                 [refreshSites]="refreshSites"
                                 [loadingSites]="loadingSites">
    </cybake-factory-user-details>
  </div>

  <div class="inline-flex w-full flex-wrap">
    @for (menuTile of menuTiles | sort:'Sequence'; track menuTile) {
    <div class="col-6 md:col-4">
      <cybake-factory-tile [pageSourceName]="pageSourceName"
                           [identifier]="menuTile.Name"
                           [title]="menuTile.Name"
                           [iconKey]="menuTile.DisplayContent"
                           [link]="menuTile.Title"
                           [disabled]="!menuTile.Enabled">
      </cybake-factory-tile>
    </div>
    }
  </div>

</div>
