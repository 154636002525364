import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { NgTemplateOutlet } from '@angular/common';
import { StockLocationType } from '../../models/stock-transfers/stocks/stock-location-types.model';
import { FilterPipe } from '../../pipes/filter-by.pipe';

@Component({
  selector: 'cybake-factory-accordion',
  standalone: true,
  imports: [AccordionModule, NgTemplateOutlet,
    FilterPipe],
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss',
})
export class AccordionComponent {
  @Input() accordionTabs: StockLocationType[] = [];
  @Input() pageSourceName: string = '';
  @Input() itemsKey: string = '';
  @Input() expandFirstTab?: boolean = false;

  @Input() searchText: string = '';
  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<unknown>;
  @Input() cardTitleContentTemplate!: TemplateRef<unknown> | null;

  // Returns the tab items for the accordion
  returnTabItems(accordionTab: StockLocationType, key:string) {
    const result = accordionTab[key];
    return Array.isArray(result) ? result : [];
  }
}
