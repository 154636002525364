<div #cardElement
     id="{{pageSourceName}}_{{identifier}}_card"
     class="cybake-card bottom-border-radial cursor-pointer bg-white border-slate-300 {{customClass}} {{cardStyle}}-card {{fullHeight ? 'h-full' : ''}}  {{ selected && 'selected' }}"
     [ngClass]="{'card-with-image': image, 'p-0': loading, 'p-2': !loading, 'cybake-card-fixed': overrideClickStyling, 'opacity-50 pointer-events-none cursor-not-allowed filter grayscale': disabled}"
     (press)="cardLongPress()"
     (click)="naviagteToRoute()">

  @if (loading) {
  <div [style.min-height]="loadingHeight + 'px'">
    <p-skeleton id="{{ pageSourceName }}{{identifier ? '_' + identifier : ''}}_input_skeleton"
                styleClass="w-full"
                width="100%"
                height="{{loadingHeight}}px">
    </p-skeleton>
  </div>
  }

  @if (!hideCardHeader && !loading) {
  <div class="inline-flex w-full"
       [ngClass]="{'vertically-center-left-align': (image || failedImage || loadingImage)}"
       (click)="this.clickEvent.emit(true);toggleCardContent()">


    <div class="{{(!iconKey && !routerLink && (!image && !failedImage && !loadingImage) && !cardTitleContentTemplate) ? 'w-full' : (!cardTitleContentTemplate ? 'w-11_12' : 'w-10_12')}} {{image || failedImage || loadingImage ? 'pr-2' : ''}}">

      <div id="{{pageSourceName}}_{{identifier}}_card_title"
           class="text-base font-semibold mb-2 {{ !toggleableContent ? '' : 'vertically-center-left-align'}}"
           [ngClass]="{'vertically-center-left-align': !subTitle && !subSubTitle, 'inline-flex': tagValue}">

        <div [ngClass]="{'w-full': tagValue}" class="font-semibold" cybake-text-style='body-small'>{{titleFromData ? title : (title | translate: titleParams )}}</div>
        @if (tagValue?.length) {
        @for (value of tagValue; track value; let index = $index) {
        @if (value) {
        <div class="pl-2">
          <cybake-factory-tag [pageSourceName]="pageSourceName"
                              [identifier]="'cybakeCardTag'"
                              [value]="value">
          </cybake-factory-tag>
        </div>
        }
        }
        }
      </div>

      @if (subTitle) {
      <div id="{{pageSourceName}}_{{identifier}}_card_subTitle"
           class="italic ellipsis" cybake-text-style='body-xsmall'>
        {{subTitleFromData ? subTitle : (subTitle | translate)}}
      </div>
      }

      @if (subSubTitle) {
      <div id="{{pageSourceName}}_{{identifier}}_card_subSubTitle"
           class="italic ellipsis" cybake-text-style='body-xsmall'>
        {{subSubTitleFromData ? subSubTitle : (subSubTitle | translate)}}
      </div>
      }

    </div>

    <div [ngClass]="{
         'w-1_12 pl-2': iconKey || routerLink,
         'w-2_12': cardTitleContentTemplate || (image || failedImage || loadingImage),
         'inline-flex w-auto': (image || failedImage || loadingImage) && routerLink,
         'vertically-center-right-align': (image || failedImage || loadingImage) && !routerLink}">

      @if (image || failedImage || loadingImage) {
      <div [ngClass]="{'card-image-landscape': (image || failedImage || loadingImage), 'pr-3': iconKey || routerLink}">
        <cybake-factory-image [pageSourceName]="pageSourceName"
                              [identifier]="identifier"
                              [pImageClass]="pImageClass"
                              [loading]="loadingImage"
                              [failed]="failedImage"
                              [verticalAlign]="'vertically-center-right-align'"
                              [overrideClick]="overrideImageClick"
                              [value]="image"
                              (failedClickEvent)="this.imageFailedClickEvent.emit(true)"
                              (clickEvent)="this.imageClickEvent.emit(true)">
        </cybake-factory-image>
      </div>
      }

      @if (iconKey) {
      <div class="float-end" (click)="this.clickEvent.emit(true)">
        <i id="{{pageSourceName}}_{{identifier}}_card_icon"
           class="fa-icon {{iconKey[0]}} {{iconKey[1]}} text-3xl text-gray-600">
        </i>
      </div>
      }

      @if (routerLink && !iconKey) {
      <div class="float-end pr-1" (click)="this.clickEvent.emit(true)"
           [ngClass]="{'h-full': (!image && !failedImage && !loadingImage) && !iconKey}">
        <i id="{{pageSourceName}}_{{identifier}}_card_icon_navigate"
           class="fa-icon fa-thin fa-angle-right text-gray-600 vertically-center text-4xl"
           [ngClass]="{'pr-1': (image || failedImage || loadingImage)}">
        </i>
      </div>
      }

      @if (cardTitleContentTemplate) {
      <div class="h-full">
        <ng-container class="h-full" *ngTemplateOutlet="cardTitleContentTemplate"></ng-container>
      </div>
      }
      @if (toggleableContent) {
      <div class="float-end pr-1">
        <i id="{{pageSourceName}}_{{identifier}}_card_toggle_icon"
           class="fa-icon fa-solid fa-chevron-down text-gray-600 text-xl"
           [ngClass]="{'fa-chevron-down': !activeAccordionIndex.length, 'fa-chevron-up': activeAccordionIndex.length}">
        </i>
      </div>
      }

    </div>

  </div>
  }

  <!--Normal Card Content-->
  @if (!toggleableContent && cardContentTemplate && !loading) {
  <div class="{{ !hideCardHeader ? 'cybake-card-content' : ''}} w-full border-t-1">

    <div class="{{ !hideCardHeader ? 'pt-2' : ''}} w-full">
      <ng-container class="pt-2" *ngTemplateOutlet="cardContentTemplate"></ng-container>
    </div>

  </div>
  }
  <!--Toggleable Card Content-->
  @if (toggleableContent && cardContentTemplate && !loading) {
  <p-accordion [activeIndex]="activeAccordionIndex" [multiple]="true">
    <p-accordionTab header="">

      @if (cardContentTemplate) {
      <div class="{{ !hideCardHeader ? 'cybake-card-content' : ''}} w-full border-t-1">

        <div class="{{ !hideCardHeader ? 'pt-2' : ''}} w-full">
          <ng-container class="pt-2" *ngTemplateOutlet="cardContentTemplate"></ng-container>
        </div>

      </div>
      }

    </p-accordionTab>
  </p-accordion>
  }

</div>
