import { Component, OnInit, inject } from '@angular/core';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { CybakeTileComponent } from '../_shared/components/tile/tile.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '../../environments/environment';
import { DataService } from '../_shared/services/common/data.service';
import { Site } from '../_shared/models/sites/site.model';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { MenuService } from '../_shared/services/menu.service';
import { MenuServiceMock } from '../_shared/services/mock-services/menu.service.mock';
import { Menu } from '../_shared/models/menus/menu.model';
import { DashboardService } from '../_shared/services/dashboard.service';
import { DashboardServiceMock } from '../_shared/services/mock-services/dashboard.service.mock';
import { SortByPipe } from '../_shared/pipes/sort-by.pipe';
import { LocalStorageService } from '../_shared/services/common/local-storage.service';

@Component({
  selector: 'cybake-factory-dashboard',
  standalone: true,
  imports: [
    UserDetailsComponent,
    CybakeTileComponent,
    RouterOutlet,
    TranslateModule,
    SortByPipe
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {

  // Services
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  dataService: DataService = inject(DataService);
  messageService: MessageService = inject(MessageService);
  localStorageService: LocalStorageService = inject(LocalStorageService);
  dashboardService: DashboardService | DashboardServiceMock = environment.mock ? inject(DashboardServiceMock) : inject(DashboardService);
  menuService: MenuService | MenuServiceMock = environment.mock ? inject(MenuServiceMock) : inject(MenuService);

  // Variables
  pageSourceName: string = 'dashboard';
  menuTiles: Menu[] = [];
  hasSites: boolean = false;
  refreshSites: boolean = false;
  loadingSites: boolean = false;

  ngOnInit() {
    this.dataService.clearNavbarTitle();

    this.getSites();
    this.getDashbaordTiles();
  }

  getDashbaordTiles() {
    this.menuService.getMenus().subscribe({
      next: (getMenusResponse: Menu[]) => {
        this.menuTiles = getMenusResponse;
      },
      error: () => {},
    });
  }

  getSites() {
    this.loadingSites = true;
    console.log('Getting sites for dashboard');
    this.dashboardService.getSitesForDashboard().subscribe({
      next: (getSitesResponse: Site[]) => {

        this.hasSites = getSitesResponse.length ? true : false;

        if (this.hasSites) {
          const deviceSite: Site = this.localStorageService.getItem('deviceSite');
          let site: Site
          if (deviceSite) {
            site = getSitesResponse.find((x: Site) => x.Id == deviceSite.Id) as Site;

            if (!site) {
              this.localStorageService.removeItem('deviceSite');
              this.localStorageService.removeItem('deviceProductionLocation');
            }
          };

        } else {
            this.localStorageService.removeItem('deviceSite');
            this.localStorageService.removeItem('deviceProductionLocation');
        }

        this.loadingSites = false;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: 'error',
            detail: translations[0],
            summary: translations[1],
          });
          this.loadingSites = false;
        });
      },
    });
  }
}
