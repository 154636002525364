@if (!showDeveSettings) {
<div class="grid m-0 w-full">

  <div class="col-12 text-lg text-center mb-3">
    <div>Personalised settings - Coming Soon</div>
  </div>

  <div class="col-12 text-lg pt-0 pl-0 font-semibold">
    {{ 'Settings.ThemeSubHeader' | translate }}
  </div>

  <div class="col-12 pl-3 pt-0">
    <cybake-factory-switch [(value)]="darkMode"
                           [translationKey]="'Settings.DarkModeLabel'"
                           [pageSourceName]="pageSourceName"
                           [disabled]="true"
                           [identifier]="'darkMode'">
    </cybake-factory-switch>
  </div>

  <div class="col-12 pl-3 pt-0">
    <cybake-factory-switch [(value)]="highContrast"
                           [translationKey]="'Settings.HighContrastLabel'"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'highContrast'"
                           [disabled]="true">
    </cybake-factory-switch>
  </div>

  <div class="col-12 pl-3 pt-0">
    <cybake-factory-switch [(value)]="leftHanded"
                           [translationKey]="'Settings.LeftHandedLabel'"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'leftHanded'"
                           [disabled]="true">
    </cybake-factory-switch>
  </div>

  <div class="col-12 pl-3 pt-0" style="opacity: .6;">Avatar colour</div>

  <div class="pl-3 pt-0 inline-flex w-full">
    <div class="col-5 p-0">
      <div class="vertically-center-left-align" style="opacity: .6;">
        {{ 'Settings.FontSizeLabel' | translate }}
      </div>      
    </div>
    <div class="col-7 p-0 inline-flex">

      <div class="col-4 pt-0 pb-0 pl-0 text-center">
        <cybake-factory-toggle [value]="fontSize === 1 ? true : false"
                               [pageSourceName]="pageSourceName"
                               [identifier]="'fontSizeSmall'"
                               [buttonOnLabel]="'Settings.FontSizeSmallLabel'"
                               [class]="'settings-font-size'"
                               [disabled]="true"
                               (valueChange)="setFontSize(1)">
        </cybake-factory-toggle>
      </div>

      <div class="col-4 p-0 text-center">
        <cybake-factory-toggle [value]="fontSize === 2 ? true : false"
                               [pageSourceName]="pageSourceName"
                               [identifier]="'fontSizeNormal'"
                               [buttonOnLabel]="'Settings.FontSizeNormalLabel'"
                               [class]="'settings-font-size'"
                               [disabled]="true"
                               (valueChange)="setFontSize(2)">
        </cybake-factory-toggle>
      </div>

      <div class="col-4 pt-0 pb-0 pr-0 text-center">
        <cybake-factory-toggle [value]="fontSize === 3 ? true : false"
                               [pageSourceName]="pageSourceName"
                               [identifier]="'fontSizeLarge'"
                               [buttonOnLabel]="'Settings.FontSizeLargeLabel'"
                               [class]="'settings-font-size'"
                               [disabled]="true"
                               (valueChange)="setFontSize(3)">
        </cybake-factory-toggle>
      </div>
    </div>
  </div>

  <div class="col-12 text-lg pt-0 pl-0 mt-2 font-semibold">Feedback and sounds</div>

  <div class="col-12 pl-3 pt-0">
    <cybake-factory-switch [(value)]="sounds"
                           [translationKey]="'Settings.SoundsLabel'"
                           [rounded]="true"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'sounds'"
                           [disabled]="true">
    </cybake-factory-switch>
  </div>

  @if (vibrationAvailableOnDevice) {
  <div class="col-12 pl-3 pt-0">
    <cybake-factory-switch [(value)]="hapticFeedback"
                           [translationKey]="'Settings.HapticFeedbackLabel'"
                           [rounded]="true"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'haptic'"
                           [disabled]="true">
    </cybake-factory-switch>
  </div>
  }
</div>
}

<!--Dev Settings-->
@if (showDeveSettings) {
<cybake-factory-settings-dev (closeEvent)="showDeveSettings = $event"></cybake-factory-settings-dev>
}

