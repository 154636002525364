import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserDetails } from '../models/user/user-details.model';
import { AuthData } from '../models/auth/auth-data.model';
import { Router } from '@angular/router';
import { LocalStorageService } from './common/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  // Services
  http: HttpClient = inject(HttpClient);
  router: Router = inject(Router);
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Variables
  _options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Service Calls
  login(username: string, password: string): Observable<AuthData> {

    const url = '/token';

    const data = JSON.stringify({
      grant_type: 'password',
      username: username,
      password: password,
      client_id: environment.clientId
    });

    return this.http.post<AuthData>(environment.authApi + url, data, this._options).pipe(
      map((loginResponse: AuthData) => {

        const mappedResponse: AuthData = {
          // eslint-disable-next-line
          expires: (loginResponse as any)['.expires'],
          // eslint-disable-next-line
          issued: (loginResponse as any)['.issued'],
          access_token: loginResponse.access_token,
          error: loginResponse.error,
          error_description: loginResponse.error_description,
          trial: loginResponse.trial,
          userName: loginResponse.userName
        };

        this.localStorageService.setItem('authData', mappedResponse);
        return loginResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  pinLogin(userDetails: UserDetails, pin: number): Observable<AuthData> {
    const url = '/token';

    const data = JSON.stringify({
      grant_type: 'password',
      userid: userDetails.UserId,
      pinauth: true,
      authcode: userDetails.AuthorisationCode,
      pin: pin,
      client_id: environment.clientId
    });

    return this.http
      .post<AuthData>(environment.api + url, data, this._options)
      .pipe(
        map((loginResponse: AuthData) => {
          this.localStorageService.setItem('authData', loginResponse);
          return loginResponse;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        }),
      );
  }

  logout() {
    this.localStorageService.removeItem('authData');
    this.router.navigateByUrl('/');
  }
}
