import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { faker } from '@faker-js/faker';
import { StockLocationType } from '../../models/stock-transfers/stocks/stock-location-types.model';
import { StockTransferLotContainer } from '../../models/stock-transfers/product/container.model';
import { Product } from '../../models/stock-transfers/product/product.model';
//import { Lot } from '../../models/stock-transfers/product/lot.model';
import { StockLocation } from '../../models/stock-transfers/stocks/stock-location.model';
import { StockLot } from '../../models/stock-transfers/stocks/stock-lot.model';

@Injectable({
  providedIn: 'root',
})

export class StockTransfersServiceMock {

  getStockLocations(): Observable<StockLocationType[]> {

    const stockLocationTypes: StockLocationType[] = [];

    for (let i = 0; i < 5; i++) {

      const stockLocationType: StockLocationType = {
        Id: i + 1,
        Name: faker.commerce.department(),
        StockLocations: []
      };

      for (let k = 0; k < 10; k++) {

        const stockLocation: StockLocation = {
          Id: k + 1,
          Name: faker.commerce.department(),
          AllProducts: false,
          HierarchicalName: '',
          MasterCompanyId: 1,
          Number: k + 1,
          Sequence: k + 1,
          SiteId: k + 1,
          StockLocationProductIds: [
            1000,
            1001,
            1002,
            1003,
            1004,
            1005,
            1006,
            1007,
            1008,
            1009,
            1010
          ],
          Lots: []
        };

        stockLocation.HierarchicalName = stockLocationType.Name + ' > ' + stockLocation.Name;

        stockLocationType.StockLocations!.push(stockLocation);
      }

      stockLocationTypes.push(stockLocationType);
    }

    return of(stockLocationTypes);
  }

  getDestinationStockLocationProducts(): Observable<StockLocationType[]> {

    const stockLocationTypes: StockLocationType[] = [];

    for (let i = 0; i < 5; i++) {

      const stockLocationType: StockLocationType = {
        Id: i + 1,
        Name: faker.commerce.department(),
        StockLocations: []
      };

      for (let k = 0; k < 10; k++) {

        const stockLocation: StockLocation = {
          Id: k + 1,
          Name: faker.commerce.department(),
          AllProducts: false,
          HierarchicalName: '',
          MasterCompanyId: 1,
          Number: k + 1,
          Sequence: k + 1,
          SiteId: k + 1,
          StockLocationProductIds: [
            1000,
            1001,
            1002,
            1003,
            1004,
            1005,
            1006,
            1007,
            1008,
            1009,
            1010
          ],
          ValidProductIds: [
            1000,
            1001,
            1002,
            1003,
            1004,
            1005,
            1006,
            1007,
            1008,
            1009,
            1010
          ],
          Lots: []
        };

        stockLocation.HierarchicalName = stockLocationType.Name + ' > ' + stockLocation.Name;

        stockLocationType.StockLocations!.push(stockLocation);
      }

      stockLocationTypes.push(stockLocationType);
    }

    return of(stockLocationTypes);
  }


  createStockTransferRequest(): Observable<null> {
    return of(null);
  }

  getLots(): Observable<StockLot[]> {

    const lots: StockLot[] = [];

    for (let i = 0; i < 10; i++) {

      const lot: StockLot = {
        Id: i + 1,
        //StockLotId: i + 1,
        SystemLotNumber: faker.number.int().toString(),
        //ContainerId: faker.number.int(),
        //ContainerName: faker.commerce.productMaterial(),
        //Weight: 10,
        ExpiryDate: faker.date.soon().toString(),
        //Quantity: faker.number.int({min: 1, max: 10}),
        //Measure: 'kgs',
        //ProductId: i + 1,
        //LotId: i + 1
      };

      lots.push(lot);

    }

    return of(lots);
  }

  getProductImage(): Observable<Blob> {
    return of();
  }

  getProducts(): Observable<Product[]> {

    const products: Product[] = [];

    for (let i = 0; i < 10; i++) {

      const product: Product = {
        Id: i + 1,
        Name: faker.commerce.productName(),
        ImageUrl: '',
        Code: faker.number.int().toString(),
        Quantity: 0,
        Measure: 'kgs',
        IsTraceable: faker.datatype.boolean()
      };

      products.push(product);

    }

    return of(products);
  }

  getContainers(): Observable<StockTransferLotContainer[]> {

    const containers: StockTransferLotContainer[] = [];

    for (let i = 0; i < 3; i++) {

      const container: StockTransferLotContainer = {
        ProductToContainerId: i + 1,
        ContainerDisplayName: faker.commerce.productMaterial(),
        Sequence: 0,
        Weight: 10,
        Measure: 'kgs',
        IsDefault: (i === 1) ? true : false
      }

      containers.push(container);
    }

    return of(containers);
  }
}


