import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Knob, KnobModule } from 'primeng/knob';
import { CyBakeButtonComponent } from '../button/button.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CyBakeInputNumberComponent } from '../input-number/input-number.component';

@Component({
  selector: 'cybake-factory-dial',
  standalone: true,
  imports: [
    KnobModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    CyBakeButtonComponent,
    CyBakeInputNumberComponent
  ],
  templateUrl: './dial.component.html',
  styleUrl: './dial.component.scss'
})
export class CyBakeDialComponent implements OnInit, AfterViewInit {

  // Children
  @ViewChild('cybakeDial') dialRef!: Knob;
  @ViewChild(Knob) dial!: Knob;

  // Inputs/Outputs
  @Input() pageSourceName: string = '';
  @Input() identifier: string = '';

  @Input() value!: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() quantityUsed!: number;

  @Output() dialTouchChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() dialCenterClick: EventEmitter<null> = new EventEmitter<null>();
  @Output() stepperValueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() min!: number;
  @Input() max!: number;
  @Input() size: number = 100;
  @Input() step: number = 1;
  @Input() loading: boolean = true;
  @Input() readOnly: boolean = true;
  @Input() disabled: boolean = false;
  @Input() hideLabel: boolean = false;
  @Input() showSteppers: boolean = true;
  @Input() valueColor?: string;
  @Input() rangeColor?: string;

  @Input() measure?: string;
  @Input() complete!: boolean;
  @Input() completeLabel?: string;

  @Input() label?: string;
  @Input() subLabel?: string;
  @Input() labelTranslationParams?: object;
  @Input() subLabelTranslationParams?: object;
  @Input() labelClass?: string;

  // Services
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  // Variables
  decreaseTimer: null | ReturnType<typeof setTimeout> = null;
  decreaseInterval: number = 0
  increaseTimer: null | ReturnType<typeof setTimeout> = null;
  increaseInterval: number = 0

  dialCenterHtml?: string;
  loadingDialCenterHtml: string = `<div class="vertically-center">
      <i class="fa-regular fa-spinner-third fa-spin text-5xl"></i>
    </div>`;

  // Screen Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  ngOnInit() {
    // Breakpoint Observer for device/screen sizes
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
    ]).subscribe((state: BreakpointState) => {
      this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
      this.isMobileLandscape = state.breakpoints[Breakpoints.HandsetLandscape];
      this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
      this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
    });
  }

  ngAfterViewInit() {
    if (this.value > this.max) {
      this.value = this.max;
    }
  }
  // Functions

  decreaseNumber() {

    //if ((this.value - this.quantityUsed) === this.min) {
    //  return;
    //}

    if ((Number(this.value) - this.step) < 0) {
      this.value = 0;
      this.stepperValueChange.emit(this.value);
      return;
    }

    this.value = Number(this.value) - this.step;
    this.stepperValueChange.emit(this.value);

    this.decreaseTimer = setTimeout(() => {
      this.decreaseInterval++;
      this.decreaseNumber();
    }, (this.decreaseInterval < 15 ? 100 : (this.decreaseInterval < 30 ? 50 : (this.decreaseInterval < 50) ? 20 : 5)));
  }

  decreaseTimeoutClear() {
    this.decreaseInterval = 0;
    clearTimeout(this.decreaseTimer!);
  }

  increaseNumber() {

    this.value = Number(this.value) + this.step;
    this.stepperValueChange.emit(this.value);

    this.increaseTimer = setTimeout(() => {
      this.increaseInterval++;
      this.increaseNumber();
    }, (this.increaseInterval < 15 ? 100 : (this.increaseInterval < 30 ? 50 : (this.increaseInterval < 50) ? 20 : 5)));
  }

  increaseTimeoutClear() {
    this.increaseInterval = 0;
    clearTimeout(this.increaseTimer!);
  }

  dialUpdateTouch(value: number) {
    if (value < this.quantityUsed) {
      this.value = 0;
      this.dialRef.value = this.quantityUsed;
      this.valueChange.emit(0);
      this.dialTouchChange.emit(0);
    } else {
      this.value = value - this.quantityUsed;
      this.valueChange.emit(this.value);
      this.dialTouchChange.emit(this.value);
    }
  }

  inputNumberChange(value: number) {
    this.value = value;
    this.valueChange.emit(this.value);
  }

}
