import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { CyBakeInputNumberComponent } from '../../_shared/components/input-number/input-number.component';
import { TranslateService } from '@ngx-translate/core';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { CyBakeCalendarComponent } from '../../_shared/components/calendar/calendar.component';
import moment from 'moment';
import { ProductionPlanProduct } from '../../_shared/models/production/plans/production-plan-product.model';
import { TranslateModule } from '@ngx-translate/core';
import { ProductionService } from '../../_shared/services/production.service';
import { ProductionServiceMock } from '../../_shared/services/mock-services/production.service.mock';
import { environment } from '../../../environments/environment';
import { DataService } from '../../_shared/services/common/data.service';

@Component({
  selector: 'cybake-factory-complete-production-item',
  standalone: true,
  imports: [
    CyBakeInputNumberComponent,
    CyBakeButtonComponent,
    CyBakeCalendarComponent,
    TranslateModule
  ],
  templateUrl: './complete-production-item.component.html',
  styleUrl: './complete-production-item.component.scss'
})
export class CompleteProductionItemComponent {

  // Inputs/Outputs
  @Input() traceableItem?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() mixItem?: boolean = false;
  @Input() product!: ProductionPlanProduct;
  @Input() value!: number;
  @Input() weightUnit!: string;

  @Output() comfirmComplete = new EventEmitter<number>();

  // Services
  dataService: DataService = inject(DataService);
  translateService: TranslateService = inject(TranslateService);
  productionService: ProductionService | ProductionServiceMock = environment.mock ? inject(ProductionServiceMock) : inject(ProductionService);

  // Variables
  pageSourceName: string = 'completeProductionItem';
  calendarDate: Date = new Date((moment().add(1, 'day').format('YYYY-MM-DD')));
  todaysDate: Date = new Date((moment().format('YYYY-MM-DD 00:00')));

  // Complete Button
  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'completeItem',
    TranslationKey: 'Production.CompleteItem.CompleteBtn',
    Type: ButtonTypeEnum.default,
    IconKey: ['fa-solid', 'fa-check'],
    Class: ButtonClassEnum.success,
    Loading: false,
  });

  confirmCompletion() {
    this.confirmButton.Loading = true;
    this.confirmButton.Failed = false;

    this.comfirmComplete.emit(this.value !== undefined ? this.value : 0);
    
  }

}
