<div
  class="flex flex-row align-items-center justify-content-between bg-white border-top-1 border-200 px-2"
>
  @if (lot && lot.SystemLotNumber) {
    <p cybake-text-style="body-small" id="trolleyCardlotNumberText" class="w-3/12 truncate">
      {{ lot.SystemLotNumber }}
    </p>
  }
  @if (lot) {
    <p
      cybake-text-style="body-small"
       id="trolleyCardContainerText"
      class="w-3/12 truncate {{ lot && lot.SystemLotNumber && 'text-center' }}"
    >
      {{ lot.ContainerName }}
    </p>
    <div class="flex flex-row gap-3 justify-content-end align-items-center w-2/12">
      <p cybake-text-style="body-small" id="trolleyQuantityText">{{ lot.Quantity }}</p>
      <i class="fa-solid fa-pen text-600" id="trolleyEditIcon" (click)="editItem()"></i>
      <i class="fa-solid fa-trash-can text-red-600" id="trolleyDeleteIcon" (click)="deleteItem()"></i>
    </div>
  }
</div>
