import { Component, OnInit, ViewContainerRef, inject } from '@angular/core';
//import { UserDetails } from '../../models/user/user-details.model';
//import { LoginPinComponent } from '../../../login/login-pin/login-pin.component';
import { LoginComponent } from '../../../login/login.component';
import { LocalStorageService } from '../../services/common/local-storage.service';

@Component({
  selector: 'cybake-factory-conditional-component-loader',
  standalone: true,
  imports: [],
  templateUrl: './conditional-component-loader.component.html',
  styleUrl: './conditional-component-loader.component.scss',
})

export class ConditionalComponentLoaderComponent implements OnInit {

  // Services
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Variables
  viewContainerRef = inject(ViewContainerRef);

  ngOnInit() {
    // RM - 29375 - Commented out 2 lines below to hide pin login from loading
    //const existingUsers: UserDetails[] = this.localStorageService.getItem('users');
    //let componentToLoad = existingUsers?.length ? LoginPinComponent : LoginComponent;

    let componentToLoad = LoginComponent;

    // Force going to login even with existing users
    componentToLoad = LoginComponent;

    // Create and render the component dynamically
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(componentToLoad as never);
  }
}
