<div class="w-full h-full">
  <cybake-factory-card [pageSourceName]="pageSourceName"
                       [hideCardHeader]="true"
                       [customClass]="'production-path'"
                       [cardContentTemplate]="breadcrumbCardContentTemplate"
                       [fullHeight]="true"
                       [identifier]="'productionPlanBreadcrumb'">
  </cybake-factory-card>

  <ng-template #breadcrumbCardContentTemplate>
    <div class="w-full inline-flex">

      <div>
        <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="navigateBackButton.Identifier"
                               [class]="navigateBackButton.Class"
                               [iconClass]="navigateBackButton.IconClass"
                               [raisedButton]="false"
                               (clickEvent)="this.backButtonEvent.emit()">
        </cybake-factory-button>
      </div>

      <div class="w-full">

        @for (breadcrumbItem of breadcrumbItems; track breadcrumbItem;let index = $index) {
        <div class="w-full"
             [ngClass]="{'vertically-center-left-align': breadcrumbItems.length === 1, 'inline-flex': index}"
             [style.padding-left.px]="10 * index">

          @if (index) {
          <i class="fa-solid fa-arrow-turn-down-right mr-2 breadcrumb-icon"></i>
          }

          <div class="w-full">

            @if (breadcrumbItem.TranslationFromData) {
            {{breadcrumbItem.TranslationKey}}
            }

            @if (!breadcrumbItem.TranslationFromData) {
            {{breadcrumbItem.TranslationKey | translate:  breadcrumbItem.TranslationParams}}
            }

          </div>

        </div>
        }

      </div>

    </div>
  </ng-template>
</div>
