import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { CyBakeButtonComponent } from '../../../_shared/components/button/button.component';
import { ImageComponent } from '../../../_shared/components/image/image.component';
import { CyBakeTagComponent } from '../../../_shared/components/tag/tag.component';
import { CyBakeImage } from '../../../_shared/models/cybake/image/image.model';
import { Product } from '../../../_shared/models/stock-transfers/product/product.model';
import { Trolley } from '../../../_shared/models/stock-transfers/product/trolley.model';
import { StockLocationType } from '../../../_shared/models/stock-transfers/stocks/stock-location-types.model';
import { StockTransferOptions } from '../../../_shared/models/stock-transfers/stocks/stock-transfer-options.model';
import { DataService } from '../../../_shared/services/common/data.service';
import { StockTransfersServiceMock } from '../../../_shared/services/mock-services/stock-transfers.service.mock.';
import { StockTransfersService } from '../../../_shared/services/stock-transfers.service';
import { TrolleyCardComponent } from '../trolley-card/trolley-card.component';
import { TrolleyItem } from '../../../_shared/models/stock-transfers/product/trolley-item.model';
import { EditStockTransferLineData } from '../../../_shared/models/stock-transfers/stocks/edit-lot-data.model';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cybake-factory-trolley-list',
  standalone: true,
  imports: [
    AccordionModule,
    TranslateModule,
    ImageComponent,
    CyBakeButtonComponent,
    CyBakeTagComponent,
    TrolleyCardComponent,
    ImageComponent,
    FormsModule,
    CheckboxModule
    
  ],
  templateUrl: './trolley-list.component.html',
  styleUrl: './trolley-list.component.scss',
})
export class TrolleyListComponent implements OnInit {
  translateService: TranslateService = inject(TranslateService);
  dataService: DataService = inject(DataService);
  lotService: StockTransfersService | StockTransfersServiceMock =
    environment.mock
      ? inject(StockTransfersServiceMock)
      : inject(StockTransfersService);
  basketItems: Trolley[] = [];
  @Input() trolleyItems: Trolley[] = [];
  @Input() selectedLocation!: StockLocationType;
  @Input() selectingMultipleStockLines: boolean = false;

  @Output() transferEvent: EventEmitter<null> = new EventEmitter();
  @Output() editEvent: EventEmitter<EditStockTransferLineData> = new EventEmitter<EditStockTransferLineData>();

  // Variables
  pageSourceName: string = 'trolleyList';
  selectedStockLines!: TrolleyItem[];

  // Product Photo
  productPhotoImage: CyBakeImage = new CyBakeImage({
    Value: undefined,
    Identifier: 'productImage',
    PageSourceName: this.pageSourceName,
    Height: 50,
    Width: 50,
    ImageFormatDataURI: true,
  });

  product!: Product;

  ngOnInit() {
    this.dataService.basketItems$.subscribe((basketLot: Trolley[] | null) => {
      this.basketItems = basketLot || [];
    });

    this.dataService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          if (stockTransferOptions?.selectedProduct) {
            this.product = stockTransferOptions?.selectedProduct;
          }
        }
      },
    );
  }

  //Function to check if the trolley item has a property occurrence: Eg: Container, LotNumber
  checkTrolleyHasPropertyOccurrence(lots: TrolleyItem[], property: keyof TrolleyItem): boolean {
    return lots.some((lot) => lot[property] !== undefined && lot[property] !== null &&
      (typeof lot[property] !== 'string' || lot[property] !== ''));
  }

  calculateTotalWeight(trolley: Trolley): number {
    // Check if the trolley is defined and has Locations
    if (!trolley || !trolley.Locations) {
      return 0; // Return 0 if the trolley is not valid
    }

    return trolley.Locations.reduce((total, location) => {
      // Check if location has Lots
      if (location && location.Lots) {
        return total + location.Lots.reduce((sum, lot: TrolleyItem) => {
          // Check if the measure is 'singles'
          if (lot.Measure?.toLowerCase() === 'singles') {
            // Add Quantity for 'singles' measure
            return sum + (lot.Quantity ?? 0);
          } else {
            // Otherwise, add Weight
            return sum + (lot.Weight ?? 0);
          }
        }, 0);
      }
      return total; // Return the current total if no lots
    }, 0);
  }


  deleteItem(i: number) {
    this.basketItems!.splice(i, 1);
    this.dataService.updateBasketItemsSource(this.basketItems!);
  }

  //function to delete a lot from the basket, we need to find the product and the index of the lot to delete
  deleteLot(trolleyItem: TrolleyItem) {
    // get the product from the basket
    const existingProduct =
      this.basketItems &&
      this.basketItems!.find(
        (item) => item.ProductId == trolleyItem.ProductId
      );
      // get the index of the product
    const productIndex = this.basketItems!.findIndex(
      (p) =>
        p.ProductId === trolleyItem.ProductId,
    );
    // if the product exists
    if (existingProduct) {
      // find the index of the lot to delete
      const lotIndex = existingProduct.Lots.findIndex(
        (p) =>
          p.LotId == (trolleyItem?.LotId ?? null) &&
          existingProduct?.ProductId == trolleyItem.ProductId &&
          trolleyItem?.ContainerId == p.ContainerId &&
          p?.SourceLocationId == trolleyItem.SourceLocationId &&
          p.ProductId == trolleyItem.ProductId
      );
      // if the lot exists
      if (lotIndex > -1) {
        // delete the lot
        this.basketItems![productIndex].Lots.splice(lotIndex, 1);
        // if the product has no lots, delete the product
        if (!this.basketItems![productIndex].Lots.length) {
          this.basketItems!.splice(productIndex, 1);
        }
        // update the basket
        this.dataService.updateBasketItemsSource(this.basketItems!);
      }
    } 
  }

  deleteMultipleItems() {
    //console.log(this.selectedStockLines);
    if(this.selectedStockLines && this.selectedStockLines.length) {
      this.selectedStockLines.forEach((lot) => {
        this.deleteLot(lot);
      });
    }
    this.selectedStockLines = [];
  }


  //function to edit a lot from the basket, we need to find the product and the index of the lot to edit the return the line data
  editLot(trolleyIndex: number, locationIndex: number, lineIndex: number, lot: Trolley) {
    const editStockTransferLineData = new EditStockTransferLineData();
    editStockTransferLineData.TrolleyIndex = trolleyIndex;
    editStockTransferLineData.LocationIndex = locationIndex;
    editStockTransferLineData.LineIndex = lineIndex;
    editStockTransferLineData.Lot = lot;
    this.editEvent.emit(editStockTransferLineData);
  }
}
