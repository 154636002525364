import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeCardComponent } from '../card/card.component';
import { CyBakeButtonComponent } from '../button/button.component';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { BreadcrumbItem } from '../../models/cybake/navagation-card/breadcrumb-item.model';

@Component({
  selector: 'cybake-factory-navigation-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeCardComponent,
    CyBakeButtonComponent
  ],
  templateUrl: './navigation-card.component.html',
  styleUrl: './navigation-card.component.scss'
})
export class NavigationCardComponent {

  // Inputs/Outputs
  @Input() identifier!: string;
  @Input() breadcrumbItems!: BreadcrumbItem[];

  @Output() backButtonEvent: EventEmitter<null> = new EventEmitter();

  // Variables
  pageSourceName: string = 'navigationCard';

  // Navigate back button
  navigateBackButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: this.identifier + 'NavigateBack',
    Class: ButtonClassEnum.transparent,
    IconKey: ['fa-regular', 'fa-chevron-left'],
    IconClass: 'text-2xl',
  });

}
