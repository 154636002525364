@if(loadingLocations){
<div class="flex flex-col gap-2">
  @for ( item of [1,2,3,4,5,6]; track item ) {
  <cybake-factory-card [loading]="true"></cybake-factory-card>
  }
</div>
}
<cybake-factory-accordion class="w-full"
                          [accordionTabs]="stockLocationTypes"
                          [expandFirstTab]="stockLocationTypes.length === 1"
                          itemsKey="StockLocations"
                          [pageSourceName]="pageSourceName">
  <ng-template let-item
               let-pageSourceName="pageSourceName"
               let-identifier="identifier">
    @if(item.Id !== selectedtransferSource && (!showAcceptingOnly || showAcceptingOnly && item?.ValidProductIds.length)){
    <cybake-factory-card [pageSourceName]="locationCard.PageSourceName"
                         [title]="item?.Name || locationCard.Title"
                         [customClass]="locationCard.CustomClass"
                         [disabled]="!item?.ValidProductIds.length"
                         [selected]="selectedtransferLocation === item.Id"
                         [subTitle]="(item?.ValidProductIds.length === 1 ? 'StockTransferDestination.SingleAcceptedItem' : 'StockTransferDestination.MultipleAcceptedItems') | translate:{count: item?.ValidProductIds.length, total:basketItems.length}"
                         (clickEvent)="selectLocation(item)"
                         [identifier]="locationCard.Identifier">
    </cybake-factory-card>
    }
  </ng-template>
</cybake-factory-accordion>
<div class="fixed bottom-1 left-0 right-0 py-4 px-32">
  <cybake-factory-button [translationKey]="continueButton.TranslationKey"
                         (clickEvent)="continue()"
                         [fullWidth]="!!continueButton.FullWidth"
                         [disabled]="!selectedtransferLocation">
  </cybake-factory-button>
</div>
