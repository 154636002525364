import { OutputFunction } from "../../common/output-function.model";
import { ToastSeverity } from "./toast-severity.enum";

export class ToastMessage {
  Severity: ToastSeverity = ToastSeverity.success;
  Detail!: string;
  Closable?: boolean = false;
  StyleClass?: string = '';
  Life?: number = 3000;
  Sticky?: boolean = false;
  Key?: string;
  CustomIconKey?: string[];
  RetryOutputMethod?: OutputFunction;

  constructor(values: ToastMessage) {
    Object.assign(this, values);
  }
}
