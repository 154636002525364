import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
  pure: false
})
export class FilterPipe<T extends Record<string, string | number | undefined>>
  implements PipeTransform
{
  transform(
    items: T[],
    searchText: string | number | undefined,
    field: keyof T,
  ): T[] {
    if (!items) return [];
    if (!searchText) return items;

    if (typeof searchText === 'string') {
      searchText = searchText.toLowerCase();
    }

    return items.filter((item: T) => {
      const fieldValue = item[field];
      if (fieldValue) {
        return fieldValue
          .toString()
          .toLowerCase()
          .includes(searchText as string);
      }
      return false;
    });
  }
}
