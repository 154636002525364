@if (selectedLocation && basketItems && !basketItems.length) {
  <div class="px-1">
    <p cybake-text-style="body-small">
      {{ 'StockTransferTrolley.CurrentlySelectedLocation' | translate }}: {{ selectedLocation.Name }}
    </p>
  </div>
}

@if (!basketItems || (basketItems && !basketItems.length)) {
  <div class="flex flex-column gap-3 text-center justify-content-center p-4 pt-8">
    <i class="fa-duotone fa-person-dolly-empty empty-icon"></i>
    <p class="font-bold text-center" cybake-text-style="body">
      {{ 'StockTransferTrolley.NoItems' | translate }}
    </p>
  </div>
 }

<cybake-factory-trolley-list  [selectingMultipleStockLines]="selectingMultipleStockLines" (editEvent)="editLot($event)" [selectedLocation]="selectedLocation" [trolleyItems]="trolleyItems"></cybake-factory-trolley-list>

<div class="fixed bottom-1 left-0 right-0 py-4 px-32 flex flex-column gap-2">
  @if (returnSelectedItemsCount) {
  <cybake-factory-button [translationKey]="deleteAllButton.TranslationKey"
                         [identifier]="deleteAllButton.Identifier"
                         (clickEvent)="deleteAll()"
                         [class]="deleteAllButton.Class"
                         [fullWidth]="!!deleteAllButton.FullWidth">
  </cybake-factory-button>
  }
  <cybake-factory-button [translationKey]="confirmButton.TranslationKey"
                         [identifier]="confirmButton.Identifier"
                         (clickEvent)="continue()"
                         [fullWidth]="!!confirmButton.FullWidth"
                         [disabled]="!basketItems.length">
  </cybake-factory-button>
</div>
