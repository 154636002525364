<div class="w-full slide-right-animation"
     [ngClass]="{ 'pt-3': (index || selectedMethod) && !selected }">

  <cybake-factory-card [title]="recipeLine.ProductName"
                       [subTitle]="recipeLine.ProductCode"
                       [pageSourceName]="pageSourceName"
                       [image]="recipeLine.Image"
                       [loadingImage]="recipeLine.ImageLoading"
                       [identifier]="'productionPlanItemRecipeLine' + recipeLine.Id"
                       [pImageClass]="'vertically-center-left-align'"
                       [tagValue]="[recipeLine.IsTraceable ? 'Production.ProductionPlanProductCard.TraceableLabel': '']"
                       [routerLink]="!selected ? true : false"
                       [cardContentTemplate]="recipeLineCardContentTemplate"
                       [overrideClickStyling]="selected ? true : false"
                       (clickEvent)="!selected ? this.viewProductionPlanProductRecipeEvent.emit() : null">
  </cybake-factory-card>

  <ng-template #recipeLineCardContentTemplate>
    @if (!selected) {
    <div class="inline-flex w-full vertically-center-left-align text-lg">
      <div class="inline-flex w-full">
        <strong class="pr-1">
          {{'Production.ProductionPlanRecipeLineCard.RemainingLabel' | translate}}:
        </strong>
        <span class="pr-1">{{ ( (recipeLine.TotalQuantity - recipeLine.QuantityUsed) >= 0 ) ? recipeLine.TotalQuantity - recipeLine.QuantityUsed : 0 }}</span>{{ recipeLine.Measure }}
      </div>

      <div>
        <div class="vertically-center-right-align pr-3">
          <strong>{{ recipeLine.QuantityUsed }}/{{ recipeLine.TotalQuantity }}</strong>
        </div>
      </div>

      <div class="float-end vertically-center-right-align">
        <cybake-factory-progress-pie [total]="recipeLine.TotalQuantity"
                                     [completed]="recipeLine.QuantityUsed"
                                     [showCheckmark]="recipeLine.Completed">
        </cybake-factory-progress-pie>
      </div>
    </div>
    }

    @if (selected) {

    <div class="w-full pt-2">
      <cybake-factory-dial #recipleLineDial
                           [pageSourceName]="pageSourceName"
                           [identifier]="recipeLine.ProductName + '_mix'"
                           [value]="recipeLine!.QuantityUsed + (usedLine ? usedLine!.Quantity : 0)"
                           [max]="recipeLine.TotalQuantity"
                           [min]="0"
                           [size]="300"
                           [loading]="loadingUsedLines || !recipeLine"
                           [valueColor]="recipeLineDialColour"
                           [rangeColor]="'SlateGray'"
                           [labelClass]="'text-3xl font-semibold'"
                           [readOnly]="false"
                           [label]="(recipeLine.Completed ? 'Production.ProductionPlanRecipeLineCard.TotalUsedLabel' : 'Production.ProductionPlanRecipeLineCard.StillRequiredLabel')"
                           [subLabel]="(recipeLine.Tolerance ? ('+/-' + recipeLine.Tolerance + ' ' + recipeLine.Measure) : '')"
                           [labelTranslationParams]="{requiredTotal: recipeLine.TotalQuantity, used: recipeLine.QuantityUsed, measure: recipeLine.Measure}"
                           [dialCenterTemplate]="dialCenterTemplate"
                           (dialTouchChange)="updateDialTouchEvent($event)"
                           (dialCenterClick)="addRemainingRequiredAmount()">
      </cybake-factory-dial>

      <div class="hidden">
        <div #dialCenterTemplate>

          @if (usedLine) {

          @if (!recipeLine!.Completed) {
          <div class="mb-1 w-full">
            <div class="font-semibold text-4xl"
                 [ngClass]="{'c-warning': (usedLine ? usedLine!.Quantity : 0) > recipeLine!.StillRequired}">

              @if ((usedLine ? usedLine!.Quantity : 0) <= recipeLine!.StillRequired) {
              {{(recipeLine.StillRequired - (usedLine ? usedLine!.Quantity : 0)) | number: '1.0-3'}} {{recipeLine.Measure}}
              }


              @if ((usedLine ? usedLine!.Quantity : 0) > recipeLine!.StillRequired) {
              {{((usedLine ? usedLine!.Quantity : 0) - recipeLine.StillRequired)  | number: '1.0-3'}} {{recipeLine.Measure}}
              }

            </div>
          </div>

          <div class="w-full text-lg">
            {{ (((usedLine ? usedLine!.Quantity : 0) <= recipeLine!.StillRequired) ? 'Production.ProductionPlanRecipeLineCard.RemainingLabel' : 'Production.ProductionPlanRecipeLineCard.OverLabel') | translate}}
          </div>

          @if ((usedLine ? usedLine!.Quantity : 0) < recipeLine!.StillRequired) {
          <div class="w-full c-success" style="font-size: 1.4rem">
            <i class="fa-solid fa-circle-down"></i>
          </div>
          }
          }

          @if (recipeLine!.Completed) {
          <div class="mb-1 w-full">
            <div class="font-semibold text-6xl">
              <i class="fa-duotone fa-solid fa-check c-success"></i>
            </div>
          </div>

          <div class="w-full text-2xl">
            {{'Production.ProductionPlanRecipeLineCard.CompletedLabel' | translate}}
          </div>
          }

          }

        </div>
      </div>

    </div>

    @if (!recipeLine!.Completed && usedLine) {
    <div class="w-full">
      <cybake-factory-input-number [(value)]="usedLine!.Quantity"
                                   [suffix]="isMobilePortrait || isMobileLandscape ? undefined : usedLine!.Measure"
                                   [min]="0"
                                   [step]="recipeLine!.StepAmount ? recipeLine!.StepAmount : 1"
                                   [showSteppers]="true"
                                   [decimal]="true"
                                   [disabled]="loadingUsedLines"
                                   (valueChange)="updateDial()">
      </cybake-factory-input-number>
    </div>

    @if (recipeLine.IsTraceable) {
    <div class="w-full pt-3"
         style="border-bottom: 1px solid var(--gray-300) !important">
      <cybake-factory-button [translationKey]="usedLine.SystemLotNumber ? usedLine.SystemLotNumber.toString() : 'Production.ProductionPlanRecipeLineCard.StockLotBtnPlaceholder'"
                             [iconKey]="stockLotsButton.IconKey"
                             [pageSourceName]="pageSourceName"
                             [identifier]="stockLotsButton.Identifier"
                             [class]="stockLotsButton.Class"
                             [fullWidth]="true"
                             [translationFromData]="stockLot || usedLine.SystemLotNumber ? true : false"
                             [loading]="stockLotsButton.Loading"
                             (clickEvent)="this.openStockLotsEvent.emit()">
      </cybake-factory-button>
    </div>
    }

    <div class="w-full pt-3">
      <cybake-factory-button [translationKey]="(usedLine.Id ? 'Production.ProductionPlanRecipeLineCard.ConfirmUpdateMeasureBtn' : confirmAddingMeasureButton.TranslationKey)"
                             [iconKey]="confirmAddingMeasureButton.IconKey"
                             [pageSourceName]="pageSourceName"
                             [identifier]="confirmAddingMeasureButton.Identifier"
                             [class]="confirmAddingMeasureButton.Class"
                             [type]="usedLine.Id ? defaultButtonType : confirmAddingMeasureButton.Type"
                             [fullWidth]="true"
                             [disabled]="(recipeLine.IsTraceable ? !usedLine!.StockLotId : false) || !usedLine!.Quantity || loadingUsedLines"
                             [loading]="confirmAddingMeasureButton.Loading"
                             (clickEvent)="this.confirmAddEditRecipeLineUsedEvent.emit()">
      </cybake-factory-button>
    </div>

    @if (usedLine.Id) {
    <div class="w-full inline-flex pt-3">
      <div class="w-full mr-1">
        <cybake-factory-button [translationKey]="cancelAddingMeasureButton.TranslationKey"
                               [iconKey]="cancelAddingMeasureButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="cancelAddingMeasureButton.Identifier"
                               [class]="cancelAddingMeasureButton.Class"
                               [fullWidth]="true"
                               [loading]="cancelAddingMeasureButton.Loading"
                               (clickEvent)="this.cancelAddingEditingMeasureEvent.emit()">
        </cybake-factory-button>
      </div>

      <div class="w-full ml-1">
        <cybake-factory-button [translationKey]="removeAddingMeasureButton.TranslationKey"
                               [iconKey]="removeAddingMeasureButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="removeAddingMeasureButton.Identifier"
                               [class]="removeAddingMeasureButton.Class"
                               [fullWidth]="true"
                               [loading]="removeAddingMeasureButton.Loading"
                               (clickEvent)="this.removeMeasureEvent.emit()">
        </cybake-factory-button>
      </div>
    </div>
    }
    }

    }
  </ng-template>
</div>
