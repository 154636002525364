import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { ListboxComponent } from '../../components/listbox/listbox.component';
import { ProductionService } from '../../services/production.service';
import { ProductionServiceMock } from '../../services/mock-services/production.service.mock';
import { environment } from '../../../../environments/environment';
import { CyBakeListbox } from '../../models/cybake/listbox/listbox.model';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { StockLot } from '../../models/production/stocklots/stock-lot.model';
import { UserDetails } from '../../models/user/user-details.model';
import { LocalStorageService } from '../../services/common/local-storage.service';

@Component({
  selector: 'cybake-factory-stock-lot-selection',
  standalone: true,
  imports: [TranslateModule, CyBakeButtonComponent, ListboxComponent],
  templateUrl: './stock-lot-selection.component.html',
  styleUrl: './stock-lot-selection.component.scss',
})
export class StockLotSelectionComponent implements OnInit, OnChanges {
  // Inputs/Outputs
  @Input() stockLotId?: number | null;
  @Input() stockLots: StockLot[] = [];
  @Input() loading: boolean = false;

  @Output() comfirmLocation = new EventEmitter<StockLot>();

  // Services
  productionService: ProductionService | ProductionServiceMock = environment.mock ? inject(ProductionServiceMock) : inject(ProductionService);
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Variables
  pageSourceName: string = 'locations';
  userDetails!: UserDetails;

  // Stock Lots List Box
  lotsListBox: CyBakeListbox = new CyBakeListbox({
    PageSourceName: this.pageSourceName,
    Identifier: 'stockLots',
    Options: [],
    Value: null,
    OptionValue: 'Id',
    OptionLabel: 'SystemLotNumber',
    OptionLabel2: 'FormattedExpiryDate',
    FilterBy: 'Name,FormattedExpiryDate',
    RadioButtons: true,
    Loading: true,
  });

  // Confirm Site Button
  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmSite',
    TranslationKey: 'Production.StockLotSelection.ComfirmLotButton',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.default,
    Loading: false,
  });

  ngOnInit() {
    this.userDetails = this.localStorageService.getItem('user');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['stockLots']) {
      this.lotsListBox.Options = this.stockLots;
    }
  }

  confirmLot() {
    const stockLot: StockLot = (this.lotsListBox.Options as StockLot[]).find((lot: StockLot) => {
        return lot.Id === this.lotsListBox.Value;
      },
    ) as StockLot;

    this.comfirmLocation.emit(stockLot);
  }
}
