import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { ListboxComponent } from '../../components/listbox/listbox.component';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CyBakeConfirmationPrompTypeEnum } from '../../models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';

@Component({
  selector: 'cybake-factory-dialog-select',
  standalone: true,
  imports: [
    DialogComponent,
    TranslateModule,
    CyBakeButtonComponent,
    ListboxComponent
  ],
  templateUrl: './dialog-select.component.html',
  styleUrl: './dialog-select.component.scss',
})
export class DialogSelectComponent {

  // Inputs/Outputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() options!: unknown[];
  @Input() title!: string;
  @Input() dialogType?: CyBakeConfirmationPrompTypeEnum | string = CyBakeConfirmationPrompTypeEnum.question;
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() closable?: boolean = true;

  @Input() value?: unknown;
  @Output() valueChange: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() optionValue?: string;
  @Input() optionLabel?: string;  
  @Input() optionLabel2?: string;  
  @Input() filterListbox?: boolean;  
  @Input() filterListboxBy?: string;  
  @Input() loading?: boolean = false;
  @Input() failed?: boolean = false;
  @Input() listboxHeight?: number;
  @Input() dialogWidth?: number;
  @Input() additionalButton?: CyBakeButton;   

  @Output() additionalButton1ClickEvent = new EventEmitter();
  @Output() dialogClosedEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();

  // Services
  translateService: TranslateService = inject(TranslateService);

  // Confirm select Button
  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmSelectBtn',
    TranslationKey: 'DialogSelect.Confirm',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.default,
    Loading: false,
  });

  confirm(value: unknown) {
    this.valueChange.emit(value);
    this.confirmEvent.emit();
    this.visibleChange.emit(false);    
  }
}
