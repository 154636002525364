<div class="w-full slide-right-animation"
     [ngClass]="{ 'pt-3': index }">

  <cybake-factory-card [title]="productionPlanProductMixCard.Title"
                       [titleParams]="productionPlanProductMixCard.TitleParams"
                       [pageSourceName]="productionPlanProductMixCard.PageSourceName"
                       [identifier]="productionPlanProductMixCard.Identifier"
                       [cardTitleContentTemplate]="productionPlanProductMixCardTitleContentTemplate"
                       (clickEvent)="this.viewProductionPlanProductRecipeMixEvent.emit()">
  </cybake-factory-card>

  <ng-template #productionPlanProductMixCardTitleContentTemplate>
    <div class="w-full vertically-center-right-align">
      <div class="float-end">
        <cybake-factory-progress-pie [total]="mix.TotalNumberOfLines"
                                     [completed]="mix.NumberOfLinesCompleted"
                                     [showCheckmark]="mix.TotalNumberOfLines === mix.NumberOfLinesCompleted">
        </cybake-factory-progress-pie>
      </div>
    </div>
  </ng-template>

</div>
