import { Component, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '../../environments/environment';
import { StockTransfersService } from '../_shared/services/stock-transfers.service';
import { StockTransfersServiceMock } from '../_shared/services/mock-services/stock-transfers.service.mock.';
import { AccordionModule } from 'primeng/accordion';
import { StockLocationType } from '../_shared/models/stock-transfers/stocks/stock-location-types.model';
import { AccordionComponent } from '../_shared/components-review/accordion/accordion.component';
import { Product } from '../_shared/models/stock-transfers/product/product.model';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { Trolley } from '../_shared/models/stock-transfers/product/trolley.model';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DataService } from '../_shared/services/common/data.service';
import { NavbarTitle } from '../_shared/models/navbar/navbar-title.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { ButtonFloatEnum } from '../_shared/models/cybake/button/button-float.enum';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { CyBakeCardComponent } from '../_shared/components/card/card.component';
import { CommonModule } from '@angular/common';
import { DialogComponent } from '../_shared/components/dialog/dialog.component';
import { CybakeInputEmailComponent } from '../_shared/components/input-email/input-email.component';
import { BarcodeScanComponent } from '../_shared/components/barcode-scan/barcode-scan.component';
import { DialogOptionsModel } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { StockLocation } from '../_shared/models/stock-transfers/stocks/stock-location.model';
import { StockTransferLotContainer } from '../_shared/models/stock-transfers/product/container.model';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { StockTransferType } from '../_shared/models/stock-transfers/stocks/stock-transfer.model';
import { Site } from '../_shared/models/sites/site.model';
import { ProductInfoComponent } from '../_shared/dialogs/product-info/product-info.component';
import { CyBakeInputTextComponent } from '../_shared/components/input-text/input-text.component';
import { StockTransferOptions } from '../_shared/models/stock-transfers/stocks/stock-transfer-options.model';
import { StockTransferLine } from '../_shared/models/stock-transfers/stocks/stock-transfer-line.model';
import { SelectTransferSourceComponent } from './select-transfer-source/select-transfer-source.component';
import { SelectTransferTrolleyComponent } from './select-transfer-trolley/select-transfer-trolley.component';
import { SelectTransferLotsComponent } from './select-transfer-lots/select-transfer-lots.component';
import { SelectTransferProductsComponent } from './select-transfer-products/select-transfer-products.component';
import { SelectTransferDestinationComponent } from './select-transfer-destination/select-transfer-destination.component';
import { LocalStorageService } from '../_shared/services/common/local-storage.service';
import { LotCardComponent } from './components/lot-card/lot-card.component';
import { LotsListComponent } from './components/lots-list/lots-list.component';
import { TrolleyListComponent } from './components/trolley-list/trolley-list.component';
import { GetDestinationStockTransferValidProductRequest } from '../_shared/models/stock-transfers/stocks/get-destination-stock-transfer-valid-product-request.model';
import { forkJoin } from 'rxjs';
import { ToastSeverity } from '../_shared/models/cybake/toast/toast-severity.enum';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { DashboardService } from '../_shared/services/dashboard.service';
import { DashboardServiceMock } from '../_shared/services/mock-services/dashboard.service.mock';
import { StockTransferLineData } from '../_shared/models/stock-transfers/stocks/stock-transfer-line-data.model';
import { StockLot } from '../_shared/models/stock-transfers/stocks/stock-lot.model';
import { TrolleyItem } from '../_shared/models/stock-transfers/product/trolley-item.model';
import { CyBakeCard } from '../_shared/models/cybake/card/card.model';
import { CardStyleEnum } from '../_shared/models/cybake/card/card-style.enum';
import { EditStockTransferLineData } from '../_shared/models/stock-transfers/stocks/edit-lot-data.model';
import { CyBakeInputNumber } from '../_shared/models/cybake/input/input-number.model';
import { CyBakeInputNumberComponent } from '../_shared/components/input-number/input-number.component';
import { CyBakeToggleComponent } from '../_shared/components/toggle/toggle.component';

@Component({
  selector: 'cybake-factory-stock-transfers',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    AccordionModule,
    AccordionComponent,
    CybakeInputEmailComponent,
    LotsListComponent,
    CommonModule,
    TrolleyListComponent,
    CyBakeButtonComponent,
    ToastModule,
    LotCardComponent,
    CyBakeCardComponent,
    DialogComponent,
    BarcodeScanComponent,
    InfiniteScrollDirective,
    CyBakeInputTextComponent,
    ProductInfoComponent,
    SelectTransferTrolleyComponent,
    SelectTransferSourceComponent,
    SelectTransferLotsComponent,
    SelectTransferDestinationComponent,
    CyBakeInputNumberComponent,
    CyBakeToggleComponent,
    SelectTransferProductsComponent,
  ],
  providers: [MessageService],
  templateUrl: './stock-transfers.component.html',
  styleUrl: './stock-transfers.component.scss',
})
export class StockTransfersComponent implements OnInit {
  // Services
  dataService: DataService = inject(DataService);
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  stockTransfersService: StockTransfersService | StockTransfersServiceMock = environment.mock ? inject(StockTransfersServiceMock) : inject(StockTransfersService);
  localStorageService: LocalStorageService = inject(LocalStorageService);
  dashboardService: DashboardService | DashboardServiceMock = environment.mock ? inject(DashboardServiceMock) : inject(DashboardService);
  messageService: MessageService = inject(MessageService);

  // Variables
  pageSourceName: string = 'stockTransfers';
  stockLocationTypes: StockLocationType[] = [];
  stockDestinationTypes: StockLocationType[] = [];
  selectedLocation!: StockLocationType;
  showSearch: boolean = false;
  showSourcesSearch: boolean = false;
  searchText: string = '';
  basketItems: Trolley[] = [];
  lots: StockLot[] = [];
  lot!: TrolleyItem;
  filteredProducts: Product[] = [];
  products: Product[] = [];
  currentStage: number = 0;
  containers: StockTransferLotContainer[] = [];
  @ViewChild("scrollElement")
  scrollEl!: ElementRef;
  isPortraitMode: boolean = false;
  userDetails!: UserDetails;
  showAcceptingOnly: boolean = true;
  // Edit Stock Line Data
  editStockTransferLineData!: EditStockTransferLineData;
  //number input count
  count: number = 0;
  isMobilePortrait: boolean = false;
  selectingMultipleStockLines: boolean = false;


  //Loading state
  loadingLocations: boolean = true;
  loadingDestinations: boolean = true;
  loadingProducts: boolean = true;
  loadingContainers: boolean = true;
  processing: boolean = false;

  pageSize: number = 20;  // Number of products to load per scroll
  currentIndex: number = 0;  // Track the current index of displayed products
  isScrolling: boolean = false;
  displayProductInfo: boolean = false;

  //loading errors
  loadingLotsError: boolean = false;
  loadingSitesError: boolean = false;
  loadingContainersError: boolean = false;
  loadingProductsError: boolean = false;

  //Flow config
  stockTransferOptions: StockTransferOptions = {
  };


  navbarTitle: NavbarTitle = new NavbarTitle({
    Title: 'StockTransfer.StockTransferTitle',
    SubTitleFromData: true,
  });


  //Buttons
  // Navigate back button
  navigateBackButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'stuckTransferNavigateBack',
    Class: ButtonClassEnum.transparent,
    IconKey: ['fa-regular', 'fa-chevron-left'],
    IconClass: 'text-2xl',
    Raised: false,
  });

  // Complete button
  completeButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'completeButton',
    Class: ButtonClassEnum.default,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  // cancel button
  cancelButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'cancelBtn',
    TranslationKey:'StockTransfer.Cancel',
    Class: ButtonClassEnum.danger,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  // Barcode Button
  barcodeScannerButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'barcodeScanner',
    Class: ButtonClassEnum.default,
    Float: ButtonFloatEnum.bottomLeft,
    Rounded: true,
    IconKey: ['fa-duotone', 'fa-scanner-gun'],
  });

  // Trolley Button
  trolleyButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'trolleyButton',
    Class: ButtonClassEnum.default,
    Float: ButtonFloatEnum.bottomRight,
    Rounded: true,
    IconKey: ['fa-duotone', 'fa-dolly'],
  });

  // Continue button
  continueButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferContinueButton',
    Class: ButtonClassEnum.success,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-plus'],
    IconClass: 'text-3xl',
  });

  // Modal Continue button
  dialogContinueButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.Continue',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferDialogContinueButton',
    Class: ButtonClassEnum.default
  });

  // Modal Update button
  dialogUpdateButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.Update',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferDialogUpdateButton',
    Class: ButtonClassEnum.default
  });

  // Modal Continue Transfer button
  dialogContinueTransferButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.ContinueTransfer',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferContinueTransferButton',
    Class: ButtonClassEnum.default
  });

  // Dialog Start Over button
  dialogStartOverButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.StartOver',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferStartoverButton',
    Class: ButtonClassEnum.default
  });

  // Dialog New Transfer button
  dialogNewTransferButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.NewTransfer',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferStartoverButton',
    Class: ButtonClassEnum.default
  });

  // Dialog Yes button
  yesButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.Yes',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferYesButton',
    Class: ButtonClassEnum.success
  });

  // Dialog No button
  noButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.No',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferNoButton',
    Class: ButtonClassEnum.danger
  });

  // Dialog Ok button
  okButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.Ok',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferOkButton',
    Class: ButtonClassEnum.default
  });



  // Dialog Close button
  closeButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    TranslationKey: 'StockTransfer.Close',
    PageSourceName: this.pageSourceName,
    Identifier: 'stocktransferCloseButton',
    Class: ButtonClassEnum.default
  });


  // card component config
  breadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'StockTransferBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    ImageClass: 'vertically-center-left-align',
    CardStyle: CardStyleEnum.light ?? undefined,
    HideCardHeaderhideCardHeader: true,
  });


  selectProductBreadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'StockTransferProductBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light ?? undefined,
    HideCardHeaderhideCardHeader: true,
    ImageClass: 'vertically-center-left-align',
  });

  selectLotBreadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'StockTransferSelectLotBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light ?? undefined,
    HideCardHeaderhideCardHeader: true,
    ImageClass: 'vertically-center-left-align',
  });

  selectTrolleyBreadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'StockTransferSelectTrolleyBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light ?? undefined,
    HideCardHeaderhideCardHeader: true,
    ImageClass: 'vertically-center-left-align',
  });

  selectDestinationBreadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'StockTransferSelectDestinationBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light ?? undefined,
    HideCardHeaderhideCardHeader: true,
    ImageClass: 'vertically-center-left-align',
  });


  // Dialogs
  displayStartFlowPopup: boolean = false;
  displayRestartFlowPopup: boolean = true;
  displayBarcodeScanner: boolean = false;
  displayCancelTransferPopup: boolean = false;
  displayTransferConfirmPopup: boolean = false;
  displayNoLotsPopup: boolean = true;
  displayConfirmPopup: boolean = false;
  stockTransferProductInfoDialog!: Product;

  //Start flow dialog configuration
  startflowDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'stockTransferStartFlow',
    Width: 45
  });

  //Retart flow dialog configuration
  restartflowDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'displayRestartFlowPopup',
    Width: 45
  });


  //Confirmation dialog configuration
  transferContinuationDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'displayRestartFlowPopup',
    Width: 45
  });

  //Transfer now dialog configuration
  transferNowDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmationPopup',
    Width: 35,
    Closable: true
  });

  //Display Info dialog configuration
  productInfoDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'stockTransferProductInfo',
    Width: 35
  });

  //Bar Code dialog configuration
  barCodeDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: 'StockTransfer.Header',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'stockTransferBarcodeScanStockTransfer',
    Width: 25,
    Closable: true
  });

  //Change Quantity dialog configuration
  changeQuantityDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: 'StockTransfer.ChangeQuantity',
    Type: CyBakeConfirmationPrompTypeEnum.custom,
    PageSourceName: this.pageSourceName,
    Identifier: 'changeQuantityDialog',
    Width: 25,
    Closable: true
  });

  //No active lots dialog configuration
  noActiveLotsDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.warning,
    PageSourceName: this.pageSourceName,
    Identifier: 'noActiveLotsPopup',
    Width: 45
  });


  //Api call failed dialog configuration
  loadingErrorDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: '',
    Type: CyBakeConfirmationPrompTypeEnum.warning,
    PageSourceName: this.pageSourceName,
    Identifier: 'loadingErrorPopup',
    Width: 45
  });

  //Api call failed dialog configuration
  cancelTransferConfirmationDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: 'StockTransfer.CancelConfirmation',
    Type: CyBakeConfirmationPrompTypeEnum.question,
    PageSourceName: this.pageSourceName,
    Identifier: 'cancelTransferDialog',
    Width: 45,
    Closable: true
  });



  // Text Input Model
  valueNumberInput: CyBakeInputNumber = new CyBakeInputNumber({
    PageSourceName: this.pageSourceName,
    Identifier: 'EditItemCountInput',
    Value: 0,
    Min: 0,
    ShowSteppers: true,
    Placeholder: true,
    FullWidth: true,
  });


  // Pagination
  paginatedProducts: Product[] = [];
  currentPage: number = 1;
  showEditStockLineDialog: boolean = false;

  ngOnInit() {
    this.getStockLocationTypes();
    this.setPageTitle();
    this.checkOrientation();
    this.dataService.basketItems$.subscribe((basketLot: Trolley[] | null) => {
      this.basketItems = JSON.parse(JSON.stringify(basketLot));
    });

    this.dataService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.stockTransferOptions = stockTransferOptions;
        }
      },
    );
    this.getUserDetails()
  }


  //---------------------- Actions ----------------------

  // Filter products based on the search text
  filterProducts() {
    //get search text and convert to lowercase
    const searchText = this.searchText.toLowerCase();

    //if search text is empty, return all products
    if (!searchText.length) {
      this.filteredProducts = this.products;
      //sort products by name
      this.filteredProducts.sort((a, b) => a.Name.localeCompare(b.Name));
      //set paginated products
      this.paginatedProducts = this.filteredProducts;
      //update paginated products
      this.updatePaginatedProducts()

    }
    //filter products by name or code
    this.filteredProducts = this.products.filter(
      (product) =>
        product.Name.toLowerCase().includes(searchText) ||
        product.Code?.toLowerCase().includes(searchText),
    );
    //sort products by name or code, if search text is empty return []
    if (this.filteredProducts.length === 0) {
      this.filteredProducts = [];
    } else {
      this.filteredProducts.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    //set paginated products
    this.paginatedProducts = this.filteredProducts;
    //update paginated products
    this.updatePaginatedProducts()
  }

  // go back to Select Location
  backToSelectLocation() {
    this.currentStage = 1;
  }

  // Select a location
  selectLocation(location: StockLocation) {
    this.stockTransferOptions.selectedLocation = location;
    this.dataService.updateStockTransferOptions(this.stockTransferOptions);

    this.getProducts(location.Id);
  }


  // Add product to the basket
  handleValueChange(lot: TrolleyItem) {
    // Create a new lot object
    const newLot: TrolleyItem = {
      Id: lot.Id,
      SystemLotNumber: lot.SystemLotNumber,
      ExpiryDate: lot.ExpiryDate,
      LotId: lot.LotId,
      Quantity: lot.Quantity,
      Measure: this.stockTransferOptions.selectedProduct?.Measure,
      Weight: this.stockTransferOptions.selectedProduct?.Measure === 'singles' ? ((this.stockTransferOptions?.selectedProduct?.Quantity ?? 0) * (lot?.Quantity ?? 1)) : lot.Weight,
      ContainerId: lot.ContainerId || null,
      ContainerName: lot.ContainerName,
      ProductId: lot.ProductId,
      SourceLocationId: this.stockTransferOptions.selectedLocation?.Id || 0,
      SourceLocationName: this.stockTransferOptions.selectedLocation?.Name || ''
    };

    // Check if the product already exists in the cart
    const existingProduct =
      this.basketItems &&
      this.basketItems!.find(
        (item) => item.ProductId == this.stockTransferOptions.selectedProduct?.Id,
      );
    if (existingProduct) {
      const index = existingProduct.Lots.findIndex(
        (p) =>
          p.Id == (lot?.LotId ?? null) &&
            existingProduct?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
            lot?.ContainerId == p.ContainerId &&
            existingProduct?.LocationId == this.stockTransferOptions?.selectedLocation?.Id
      );


      const productIndex = this.basketItems!.findIndex(
        (p) =>
          p.ProductId === this.stockTransferOptions.selectedProduct?.Id,
      );


      if (index > -1) {
        existingProduct.Lots[index] = newLot;
      } else {

        // If the product exists, add the new lot to the existing lots array
        existingProduct.Lots.push(newLot);
      }

      if (!existingProduct!.Lots.length) {
        this.basketItems!.splice(productIndex, 1)
      } else {
        this.basketItems![productIndex] = existingProduct;
      }

      this.dataService.updateBasketItemsSource(this.basketItems!);


      const options: DialogOptionsModel = new DialogOptionsModel({
        Type: CyBakeConfirmationPrompTypeEnum.success,
        Title: 'StockTransfer.ItemSuccessfullyUpdated',
        PageSourceName: this.pageSourceName,
        Identifier: 'updatedTrolleyDialog',
        AutoClose: true,
      });

      this.dataService.openConfirmationPrompt(options);
    } else {
      // If the product does not exist, create a new basket item
      const basketItem: Trolley = {
        Id: 1,
        Name: this.stockTransferOptions.selectedProduct?.Name || '',
        ImageUrl: this.stockTransferOptions.selectedProduct?.ImageUrl || '',
        Measure:
          this.stockTransferOptions.selectedProduct?.Measure,
        ProductCode: this.stockTransferOptions.selectedProduct?.Code,
        IsTraceable: this.stockTransferOptions.selectedProduct?.IsTraceable,
        Expiry: lot.ExpiryDate,
        Weight: lot.Weight || 0,
        ProductId: this.stockTransferOptions.selectedProduct?.Id,
        LocationId: this.stockTransferOptions.selectedLocation?.Id,
        LocationName: this.stockTransferOptions.selectedLocation?.Name,
        Lots: [newLot], // Initialize with the new lot
      };
      // Add the new basket item to the cart
      this.basketItems!.push(basketItem);
      this.dataService.updateBasketItemsSource(this.basketItems! || []);

      const options: DialogOptionsModel = new DialogOptionsModel({
        Type: CyBakeConfirmationPrompTypeEnum.success,
        Title: 'StockTransfer.ItemSuccessfullyAdded',
        PageSourceName: this.pageSourceName,
        Identifier: 'addToTrolleyDialog',
        AutoClose: true,
      });

      this.dataService.openConfirmationPrompt(options);
    }
  }

  // Start the transfer process
  startFlow() {
    if (this.basketItems?.length) {
      this.displayStartFlowPopup = false;
    } else {
      this.displayRestartFlowPopup = false;
    }
    this.currentStage = 1;
    this.basketItems = [];
    this.dataService.updateBasketItemsSource(this.basketItems!);
    this.stockTransferOptions = {
    };
    this.dataService.updateStockTransferOptions(this.stockTransferOptions);
    this.setPageTitle();
  }


  setContinueTransfer() {
    if (this.stockTransferOptions) {
      this.dataService.updateStockTransferOptions(this.stockTransferOptions);
      this.displayRestartFlowPopup = false;
      this.currentStage = 4;
      this.setPageTitle();
    }
  }

  // Restart the flow
  startOver(clearAll = true, showPopup = true) {
    this.displayStartFlowPopup = false;
    this.displayRestartFlowPopup = showPopup ? true : false;
    this.currentStage = 1;
    if (clearAll) {
      this.basketItems = [];
      this.dataService.updateBasketItemsSource(this.basketItems!);
    }
    this.stockTransferOptions = {
    };
    this.dataService.updateStockTransferOptions(this.stockTransferOptions);
    this.setPageTitle();
  }


  getTransferDialogTitle(): string {
    const selectedDestination = this.stockTransferOptions.selectedDestination;
    const hasFilteredItems = this.returnFilteredItems?.length;
    const allProductsAccepted = selectedDestination?.AllProducts;

    if (!allProductsAccepted && !hasFilteredItems) {
      return this.translateService.instant('StockTransfer.NoProductsAccepted');
    } else if (!allProductsAccepted && hasFilteredItems !== this.returnBasketItemsLength) {
      return this.translateService.instant('StockTransfer.PartialTransfer', {
        count: hasFilteredItems,
        total: this.returnBasketItemsLength
      });
    } else {
      return this.translateService.instant('StockTransfer.TransferConfirmation');
    }
  }

  backToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  // Remove all product from the basket
  deleteAll() {
    this.basketItems = [];
    this.dataService.updateBasketItemsSource(this.basketItems);
    this.stockTransferOptions = {
    };

    this.dataService.updateStockTransferOptions(this.stockTransferOptions);
  }

  selectProduct(product: Product) {
    this.stockTransferOptions.selectedProduct = product;
    this.dataService.updateStockTransferOptions(this.stockTransferOptions);
    this.getLots(product.Id)
    this.getContainers(product.Id)
  }


  // complete transfer and restart flow
  completeTransfer(clearAll = true): void {
    this.currentStage = 0;
    this.displayTransferConfirmPopup = false;
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.success,
      Title: 'StockTransfer.StockTransferCompleted',
      PageSourceName: this.pageSourceName,
      Identifier: 'transferCompletedDialog',
      AutoClose: true,
    });


    this.dataService.openConfirmationPrompt(options);
    //log clear all
    if (!this.basketItems?.length) {
      setTimeout(() => {
        this.startOver(clearAll);
      }, 1000);
    }
  }

  //Return the number of items in the basket
  get returnBasketItemsLength(): number {
    // Filter the stock transfer lines based on the destination location's rules
    const lots: TrolleyItem[] | undefined = this.basketItems?.map(product => {
      return product.Lots.map(lot => {
        return lot;
      })
    }).flat().filter(line => line.ProductId); // Filter out empty lines

    return lots?.length ?? 0;
  }

  // Return the number of items in the basket for current lot
  get returnFilteredItems() {
    // Get selected destination location
    const destinationLocation = this.stockTransferOptions.selectedDestination;
    // Check if destination allows all products or only specific ones
    const allowAllProducts = destinationLocation?.AllProducts;
    const allowedProductIds = destinationLocation?.ValidProductIds || [];

    // Filter the stock transfer lines based on the destination location's rules
    const stockTransferLines: StockTransferLine[]  = this.basketItems?.map(product => {
      // Check if the product is allowed to be transferred
      const isProductAllowed = allowAllProducts || allowedProductIds.includes(product.ProductId || 0);
      if (isProductAllowed) {
        //console.log(product)

        return product.Lots.map(lot => ({
          ProductId: product.ProductId,
          Name: product.Name,
          Measure: lot.Measure,
          Weight: lot.Weight ?? undefined,
          TransferQuantity: lot.Quantity,
          ProductToContainerId: lot.ContainerId ?? undefined,
          SourceLocationId: lot.SourceLocationId ?? 0,
          StockLotId: lot.LotId ?? undefined
        }));
      }

      return []; // Return an empty array if the product is not allowed
    }).flat().filter(line => line.ProductId); // Filter out empty lines

    return stockTransferLines ;

  }

  // ---------------------- Dialogs ----------------------

  openBarcodeScanner() {
    this.displayBarcodeScanner = true;
  }

  //View product info dialog
  viewProductInfo(product: Product | undefined, img: string = "") {
    if (product) {
      product.ImageUrl = img;
      this.stockTransferProductInfoDialog = product;
    }
    this.displayProductInfo = true;
  }

  // ---------------------- Pagination ----------------
  updatePaginatedProducts(): void {
    const nextIndex = this.currentIndex + this.pageSize;
    const newProducts = this.filteredProducts.length ? this.filteredProducts.slice(this.currentIndex, nextIndex) : this.products.slice(this.currentIndex, nextIndex);
    this.paginatedProducts = [...this.paginatedProducts, ...newProducts];
    this.currentIndex = nextIndex;

    this.isScrolling = true;

    // Reset the isScrolling state after a delay (e.g., 1 second)
    setTimeout(() => {
      this.isScrolling = false;
    }, 1000);
  }


  // ---------------------- Services  ----------------------
  getStockLocationTypes() {
    this.loadingSitesError = false;
    this.stockTransfersService.getStockLocations().subscribe({
      next: (getStockLocationResponse: StockLocationType[]) => {
        this.stockLocationTypes = getStockLocationResponse;
        this.loadingLocations = false;
      },
      error: () => {
        this.loadingLocations = false;
        this.loadingSitesError = true;
      },
    });
  }

  doStockTransfer(): void {
    this.processing = true;
    // Get selected destination location
    const destinationLocation = this.stockTransferOptions.selectedDestination;

    // Filter the stock transfer lines based on the destination location's rules
    const stockTransferLines: StockTransferLine[] | undefined = this.returnFilteredItems
    let stockTransferPayloadLines!: StockTransferLineData[]

    if (!stockTransferLines?.length) {
      return;
    }

    if (stockTransferLines.length) {
     stockTransferPayloadLines = stockTransferLines.map((line) => ({
        SourceLocationId: line.SourceLocationId, 
        ProductId: line.ProductId,
        ProductToContainerId: line.ProductToContainerId,
        StockLotId: line.StockLotId,
        TransferQuantity: line.TransferQuantity, // defaulting to 0 if undefined
      }));
    }

    const site: Site = this.localStorageService.getItem('deviceSite');

    const payload: StockTransferType = {
      SiteId: site?.Id || undefined,
      "DestinationLocationId": this.stockTransferOptions.selectedDestination?.Id || 0,
      "StockTransferLines": stockTransferPayloadLines || []
    };

    // Send the stock transfer request
    this.stockTransfersService.createStockTransferRequest(payload).subscribe({
      next: () => {
        // Clear the basket items after preparing the payload
        this.basketItems = this.basketItems?.filter(product => {
          // Remove products from the basket that were successfully added to the transfer
          return !stockTransferLines?.some(line => line.ProductId === product.ProductId);
        }) || [];


        this.dataService.updateBasketItemsSource(this.basketItems!);
        this.processing = false;
        if (!this.basketItems.length) {
          this.completeTransfer(destinationLocation?.AllProducts);
        } else {
          this.displayTransferConfirmPopup = false
          this.currentStage = 4;
          this.stockTransferOptions.selectedDestination = undefined;
          this.dataService.updateStockTransferOptions(this.stockTransferOptions);
        }
      },
      error: () => {
        this.processing = false
        const options: DialogOptionsModel = new DialogOptionsModel({
          Type: CyBakeConfirmationPrompTypeEnum.error,
          Title: 'StockTransfer.StockTransferFailed',
          PageSourceName: this.pageSourceName,
          Identifier: 'stockTranferFailed',
          AutoClose: true,
        });

        this.dataService.openConfirmationPrompt(options);
        // Handle error
      },
    });
  }

  //get product valid destination locations
  getProductsValidDestinations() {
    const site: Site = this.localStorageService.getItem('deviceSite');
    //return product ids from basket items
    const productIds: number[] = this.basketItems.length ? this.basketItems.map(item => (item.ProductId ?? 0)) : [];

    const payload: GetDestinationStockTransferValidProductRequest = {
      MasterCompanyId: this.userDetails.MasterCompanyId,
      SiteId: site?.Id || undefined,
      ProductIds: productIds || []
    }

    this.stockTransfersService.getDestinationStockLocationProducts(payload).subscribe({
      next: (getStockLocationResponse: StockLocationType[]) => {
        this.stockDestinationTypes = getStockLocationResponse;
        this.loadingDestinations = false;
      },
      error: () => {
        this.loadingDestinations = false
        const options: DialogOptionsModel = new DialogOptionsModel({
          Type: CyBakeConfirmationPrompTypeEnum.error,
          Title: 'StockTransfer.GetValidDestinationFailed',
          PageSourceName: this.pageSourceName,
          Identifier: 'getValidDestinationFailed',
          AutoClose: true,
        });

        this.dataService.openConfirmationPrompt(options);
        // Handle error
      },
    });
  }

  getUserDetails() {
    this.dashboardService.getUserDetails().subscribe({
      next: (getUserDetailsResponse: UserDetails) => {
        this.userDetails = getUserDetailsResponse;
      },
      error: () => {
        forkJoin([
          this.translateService.get('StockTransfer.FailedLoginMsg'),
          this.translateService.get('StockTransfer.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: ToastSeverity.error,
            detail: translations[0],
            summary: translations[1],
          });
        });
      },
    });
  }


  getLots(id: number) {
    this.loadingLotsError = false;
    this.stockTransfersService.getLots(id).subscribe({
      next: (getLotsResponse: StockLot[]) => {
        this.lots = getLotsResponse;
      },
      error: () => {
        this.loadingLotsError = true
      },
    });
  }

  // Get products by location id
  getProducts(id: number | undefined) {
    this.stockTransfersService.getProducts(id || 0).subscribe({
      next: (getProductResponse: Product[]) => {
        this.products = [];
        this.currentIndex = 0;
        this.paginatedProducts = [];
        this.products = getProductResponse;
        this.products.sort((a, b) => a.Name.localeCompare(b.Name));

        this.products.forEach((product: Product) => {
          if (product.Id) {
            this.getProductPhoto(product.Id, product);
          }
        });

        this.updatePaginatedProducts();
        this.loadingProducts = false;
      },
      error: () => {
        this.loadingProducts = false;
      },
    });
  }

  // Get containers by product id
  getContainers(productId: number) {
    // check if product has containers using id and return them
    const containerExists = this.containers.some(
      (container) => container.ProductToContainerId === productId
    );

    if (containerExists) {
      return; // Exit early if the container already exists
    }

    this.loadingContainers = true;

    this.stockTransfersService.getContainers(productId).subscribe({
      next: (getContainerResponse: StockTransferLotContainer[]) => {

        //for each container set product identifier
        getContainerResponse.forEach((container) => {
          container.ProductToContainerId = container.ProductToContainerId || 0; // Set to 0 if undefined for the dialog select to have a value when selected

        });

        this.containers = getContainerResponse;

        this.loadingContainers = false;
      },
      error: () => {
        this.loadingContainers = false;
        this.loadingContainersError = true;
      },
    });
  }

  //---------------------- Event Listeners ----------------------

  scrollToTop() {
    this.scrollEl.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  //event listeners to check page orientation; portrait or landscape

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortraitMode = window.innerHeight > window.innerWidth;
  }

  // ---------------------- Helper Functions ---------------------- //

  //Show modal to edit the quantity of a product and set stock transfer line data to edit
  showEditLineModal(lineData: EditStockTransferLineData) {
    this.editStockTransferLineData = lineData;
    this.count = lineData.Lot.Quantity || 0;
    this.showEditStockLineDialog = true;
  }

  //Update the stock transfer line with the new quantity
  updateStockLine() {
    // Get the trolley item to update
    const trolleyItem: TrolleyItem = this.editStockTransferLineData.Lot;
    // Get the existing product from the basket
    const existingProduct =
      this.basketItems &&
      this.basketItems!.find(
        (item) => item.ProductId == trolleyItem.ProductId
      );

      // Get the index of the existing product in the basket
      const productIndex = this.basketItems!.findIndex(
        (p) =>
          p.ProductId === trolleyItem.ProductId,
      );

      // Check if the product exists in the basket
    if (existingProduct) {
      // Get the index of the lot to update
      const lotIndex = existingProduct.Lots.findIndex(
        (p) =>
          p.LotId == (trolleyItem?.LotId ?? null) &&
          existingProduct?.ProductId == trolleyItem.ProductId &&
          trolleyItem?.ContainerId == p.ContainerId &&
          p?.SourceLocationId == trolleyItem.SourceLocationId &&
          p.ProductId == trolleyItem.ProductId
      );
      //if the lot exists, update the quantity
      if (lotIndex > -1) {
        trolleyItem.Quantity = this.count;
        existingProduct.Lots[lotIndex] = trolleyItem;
      }
      // update the product in the basket
      this.basketItems![productIndex] = existingProduct;

    }
    // Update the basket items
    this.dataService.updateBasketItemsSource(this.basketItems! || []);

    //Reset the count and close the dialog
    this.count = 0;
    this.showEditStockLineDialog = false;
  }
 
  // toggle accepting only filter
  toggleAcceptingDestinationsOnlyFilter() {
    this.showAcceptingOnly = !this.showAcceptingOnly;
  }

  getProductPhoto(id: number , product: Product) {
    this.stockTransfersService.getProductImage(id).subscribe((url) => {
      if (url && url.size) {
        const urlCreator = window.URL;
        const imageUrl = urlCreator.createObjectURL(url);
        product.ImageUrl = imageUrl;
      }
    })

  }

  setPageTitle() {
    let title: string = 'StockTransfer.StockTransferTitle';
    let subtitle: string = '';

    switch (this.currentStage) {
      case 1:
        title = 'StockTransfer.SelectSource';
        subtitle = 'StockTransfer.StockTransferTitle';
        break;
      case 2:
        title = 'StockTransfer.SelectProduct';
        subtitle = 'StockTransfer.StockTransferTitle';
        break;
      case 3:
        title = 'StockTransfer.SelectAmountToTransfer';
        subtitle = 'StockTransfer.StockTransferTitle';
        break;
      case 4:
        title = 'StockTransfer.ProductsReadyToTransfer';
        subtitle = 'StockTransfer.StockTransferTitle';
        break;
      case 5:
        title = 'StockTransfer.SelectDestination';
        subtitle = this.translateService.instant('StockTransfer.StockTransferWithItems', {
          count: this.basketItems?.length,
          itemLabel: this.translateService.instant(this.basketItems?.length > 1 ? 'StockTransfer.Products' : 'StockTransfer.Product')
        });
        break;
      default:
        title = 'StockTransfer.StockTransferTitle';
        break;
    }


    this.navbarTitle.Title = title;
    this.navbarTitle.SubTitle = subtitle;
    this.navbarTitle.SubTitleFromData = false;
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  // Handle the count value change
  handleCountValueChange(value: number) {
    this.count = value;
  }

  getCommaSeparatedProductNames(products: StockTransferLine[] | undefined): string {
    if (!products || products.length === 0) {
      return '';
    }

    // Create a Set from the product names to remove duplicates
    const uniqueProductNames = Array.from(new Set(products.map(product => product.Name)));

    // Return the comma-separated list of unique product names
    return uniqueProductNames.join(', ');
  }

  calculateTotalWeight(products: StockTransferLine[] | null): string {
    if (!products || products.length === 0) {
      return '';
    }

    // Calculate total weight for products where Measure is not 'singles'
    const totalWeight = products.reduce((sum, product) => {
      const measure = product.Measure?.toLowerCase();
      // Only include the weight if measure is not 'singles'
      return measure !== 'singles' ? sum + (product.Weight || 0) : sum;
    }, 0);

    if (!totalWeight) {
      return '';
    }
    // Assuming all non-single products have the same measure; otherwise, adjust logic accordingly
    const measure = products.find(p => p.Measure?.toLowerCase() !== 'singles')?.Measure || '';
    return `${totalWeight} ${measure}`;
  }


  //calculate total weight of singles products
  calculateSinglesTotalWeight(products: StockTransferLine[] | null) {
    if (!products || products.length === 0) {
      return '';
    }

    return products?.reduce((totalWeight, trolley) => {
      return trolley?.Measure === 'singles' ? totalWeight + (trolley.TransferQuantity ? trolley.TransferQuantity : 0) : 0; // Use 0 if Weight is undefined
      return (totalWeight);
    }, 0);
  }

  // return lots in a trolley item
  returnLots() {
    if (!this.basketItems || (this.basketItems && !this.basketItems.length)) {
      return undefined;
    }
    const indexedProduct = this.basketItems.find((item) => item.ProductId === this.stockTransferOptions?.selectedProduct?.Id);

    const lot: Trolley | undefined = indexedProduct ? indexedProduct : undefined;
    return lot;
  }

  //get lot container
  returnLotContainer(): TrolleyItem {
    if (!this.basketItems || (this.basketItems && !this.basketItems.length)) {
      return {Id:0};
    }
    const indexedProduct = this.basketItems.find((item) => item.ProductId === this.stockTransferOptions?.selectedProduct?.Id);

    const lot: Trolley | undefined = indexedProduct ? indexedProduct : undefined;
    return lot?.Lots[0] || {Id:0};
  }

  groupByProductLocation(data: Trolley[]): Trolley[] {

    return data.map((product) => {
      // Group lots by their SourceLocationId for each product
      const locationsMap = new Map<number, StockLocation>();

      product.Lots.forEach((lot: TrolleyItem) => {
        const locationKey = lot.SourceLocationId ?? 0;
        if (locationKey && !locationsMap.has(locationKey ?? 0)) {
          locationsMap.set((locationKey ?? 0), {
            Id: lot.SourceLocationId ?? 0,
            Name: lot.SourceLocationName ?? '',
            StockLocationProductIds: [],
            AllProducts: false,
            Number: 0,
            SiteId: 0,
            ValidProductIds: [],
            HierarchicalName: '',
            Sequence: 0,
            MasterCompanyId: 0,
            Lots: [],
          });
        }

        if (locationsMap && lot && locationsMap.get(locationKey)) {
          // Add the lot to the appropriate location
          (locationsMap.get(locationKey)?.Lots as TrolleyItem[]).push(lot);
        }
      });

      return {
        Id: product.Id,
        Name: product.Name,
        ImageUrl: product.ImageUrl,
        Measure: product.Measure,
        ProductCode: product.ProductCode,
        IsTraceable: product.IsTraceable,
        Expiry: product.Expiry,
        Weight: product.Weight,
        ProductId: product.ProductId,
        LocationId: product.LocationId,
        LocationName: product.LocationName,
        Locations: Array.from(locationsMap.values()),
        Lots: product.Lots
      };
    });
  }
}
