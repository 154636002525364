import { CyBakeInput } from './input.model';

export class CyBakeInputNumber extends CyBakeInput {
  Value!: number;
  Min!: number;
  ShowSteppers!: boolean;

  constructor(values: CyBakeInputNumber) {
    super(values);
    Object.assign(this, values);
  }
}
