<div class="cybake-page-background min-h-full page-scroller relative {{isPortraitMode?'page-portrait':'page-landscape'}}">
  @switch (currentStage) {
  <!-- SELECT SOURCE DESTINATION -->
  @case (1) {
  <div class="flex w-full flex-column gap-3 px-2 py-4">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="breadcrumbCard.PageSourceName"
                           [cardStyle]="breadcrumbCard.CardStyle"
                           [hideCardHeader]="breadcrumbCard.HideCardHeaderhideCardHeader"
                           [customClass]="breadcrumbCard.CustomClass"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="breadcrumbCard.Identifier">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="w-full flex flex-row justify-content-between align-items-center">
          <div class="flex flex-row  align-items-center gap-1"
               (click)="currentStage = 0; startOver() ;setPageTitle()">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="navigateBackButton.PageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="!!navigateBackButton.Raised"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
            <div class="w-full"
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              {{ 'StockTransfer.StartNewTransfer' | translate }}
            </div>
          </div>
          <div class="pr-2">
            <span (click)="showSourcesSearch = !showSourcesSearch">
              <i class="fa-duotone fa-{{showSourcesSearch?'xmark':'magnifying-glass'
                }} text-2xl"></i>
            </span>
          </div>
        </div>
      </ng-template>
    </div>
    <cybake-factory-select-transfer-source [showSearch]="showSourcesSearch" [selectedtransferLocation]="stockTransferOptions.selectedLocation?.Id" [stockLocationTypes]="stockLocationTypes" [loadingLocations]="loadingLocations" (selectLocationEvent)="selectLocation($event)" (continueEvent)="currentStage = 2;scrollToTop();setPageTitle()"></cybake-factory-select-transfer-source>
  </div>
  }

  <!-- SELECT PRODUCT SECTION -->
  @case (2) {
  <div class="px-2 py-4 flex flex-column gap-3">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="selectProductBreadcrumbCard.PageSourceName"
                           [hideCardHeader]="selectProductBreadcrumbCard.HideCardHeaderhideCardHeader"
                           [customClass]="selectProductBreadcrumbCard.CustomClass"
                           [cardStyle]="selectProductBreadcrumbCard.CardStyle"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="selectProductBreadcrumbCard.Identifier">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="w-full flex flex-row justify-content-between align-items-center">
          <div class="flex flex-row  align-items-center gap-1" (click)="backToSelectLocation()">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="navigateBackButton.PageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="!!navigateBackButton.Raised"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
            <div class="w-full"
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              {{ stockTransferOptions.selectedLocation?.Name }}
            </div>
          </div>
          <div class="pr-2">
            <span (click)="showSearch = !showSearch">
              <i class="fa-duotone fa-{{showSearch?'xmark':'magnifying-glass'
                }} text-2xl"></i>
            </span>
          </div>
        </div>
      </ng-template>
    </div>
    <cybake-factory-select-transfer-products (continueEvent)="currentStage = 3;scrollToTop();setPageTitle()" [stockTransferOptions]="stockTransferOptions" [paginatedProducts]="paginatedProducts" [loadingProducts]="loadingProducts" (viewProductEvent)="viewProductInfo(stockTransferOptions.selectedProduct,$event)" (selectProductEvent)="selectProduct($event)" (updatePaginationEvent)="updatePaginatedProducts()" [showSearch]="showSearch" (filterProductsEvent)="searchText = $event || ''; filterProducts()"></cybake-factory-select-transfer-products>
  </div>
  }
  <!-- SELECT LOT SECTION -->
  @case (3) {
  <div class="px-2 py-4 flex flex-column gap-3">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="selectLotBreadcrumbCard.PageSourceName"
                           [hideCardHeader]="selectLotBreadcrumbCard.HideCardHeaderhideCardHeader"
                           [cardStyle]="selectLotBreadcrumbCard.CardStyle"
                           [customClass]="selectLotBreadcrumbCard.CustomClass"
                           [cardContentTemplate]="breadcrumbCardContentTemplate2"
                           [identifier]="selectLotBreadcrumbCard.Identifier"
                           (click)="currentStage = 2; setPageTitle()">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate2>
        <div class="w-full flex items-center h-4rem">
          <div>
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="navigateBackButton.PageSourceName"
                                   [raisedButton]="!!navigateBackButton.Raised"
                                   [identifier]="navigateBackButton.Identifier"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
          </div>
          <div class="w-full">
            <div class="w-full" cybake-text-style="body-small">
              {{ stockTransferOptions.selectedLocation?.Name }}
            </div>

            <div class="w-full inline-flex" style="padding-left: 10px">
              <i class="fa-solid fa-arrow-turn-down-right mr-2"
                 style="padding-top: 4px"></i>
              <div class="w-full" cybake-text-style="body-xs">
                {{ stockTransferOptions.selectedProduct?.Name }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <cybake-factory-select-transfer-lots [stockTransferOptions]="stockTransferOptions"
                                         [lots]="lots"
                                         [basketItems]="basketItems"
                                         [basketLot]="returnLots()"
                                         [loadingContainers]="loadingContainers"
                                         [containers]="containers"
                                         (transferEvent)="handleValueChange($event)"
                                         (viewProductEvent)="viewProductInfo(stockTransferOptions.selectedProduct,$event)">
    </cybake-factory-select-transfer-lots>
  </div>
  }
  <!--trolley section-->
  @case (4) {
  <div class="px-2 py-4 flex flex-column gap-3">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="selectTrolleyBreadcrumbCard.PageSourceName"
                           [cardStyle]="selectTrolleyBreadcrumbCard.CardStyle"
                           [hideCardHeader]="selectTrolleyBreadcrumbCard.HideCardHeaderhideCardHeader"
                           [customClass]="selectTrolleyBreadcrumbCard.CustomClass"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="selectTrolleyBreadcrumbCard.Identifier">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="flex flex-row justify-content-between items-center">
          <div class="flex flex-row items-center gap-2"
               (click)="stockTransferOptions.selectedProduct = undefined;getProducts(stockTransferOptions.selectedLocation?.Id); currentStage = 2; setPageTitle()">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="navigateBackButton.PageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="!!navigateBackButton.Raised"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
            <div class=""
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              {{ 'StockTransfer.AddMoreToTransfer' | translate }}
            </div>
          </div>
          <div class="md:pr-2 flex flex-row gap-4">
            @if (basketItems.length) {
            <span (click)="selectingMultipleStockLines = !selectingMultipleStockLines" class="flex align-items-center">
              <i class="fa-duotone fa-circle-check {{selectingMultipleStockLines?'text-orange-800':'text-800'
                }} text-4xl"></i>
            </span>
            <cybake-factory-button [pageSourceName]="cancelButton.PageSourceName"
                                   (clickEvent)="displayCancelTransferPopup = true"
                                   [translationKey]="cancelButton.TranslationKey"
                                   [identifier]="cancelButton.Identifier"
                                   [class]="cancelButton.Class">
            </cybake-factory-button>
            }
          </div>
        </div>
      </ng-template>
    </div>
    <cybake-factory-select-transfer-trolley [selectingMultipleStockLines]="selectingMultipleStockLines" (editEvent)="showEditLineModal($event)" (continueEvent)="getProductsValidDestinations();currentStage = 5;scrollToTop();setPageTitle()" [basketItems]="basketItems" [trolleyItems]="groupByProductLocation(basketItems)"></cybake-factory-select-transfer-trolley>
  </div>
  }
  <!-- Select destination section -->
  @case (5) {
  <div class="flex flex-col w-full px-2 py-4">

    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="selectDestinationBreadcrumbCard.PageSourceName"
                           [cardStyle]="selectDestinationBreadcrumbCard.CardStyle"
                           [hideCardHeader]="selectDestinationBreadcrumbCard.HideCardHeaderhideCardHeader"
                           [customClass]="selectDestinationBreadcrumbCard.CustomClass"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="selectDestinationBreadcrumbCard.Identifier">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>

        <div class="w-full flex flex-row justify-content-between align-items-center">
          <div class="flex flex-row  align-items-center gap-1" (click)="currentStage = 4;setPageTitle()">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="navigateBackButton.PageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="!!navigateBackButton.Raised"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
            <div class="w-full"
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              {{ 'StockTransfer.BackToTrolley' | translate }}
            </div>
          </div>
          <div class="pr-2"
               [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
            <cybake-factory-toggle [(value)]="showAcceptingOnly"
                                   [bigIcon]="true"
                                   [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                                   [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                                   [buttonOnLabel]="!isMobilePortrait ? 'StockTransfer.Available' : ''"
                                   [buttonOffLabel]="!isMobilePortrait ? 'StockTransfer.Available' : ''">
            </cybake-factory-toggle>
          </div>
        </div>
      </ng-template>
    </div>
    <cybake-factory-select-transfer-destination [showAcceptingOnly]="showAcceptingOnly" [basketItems]="basketItems" [selectedtransferLocation]="stockTransferOptions.selectedDestination?.Id" [selectedtransferSource]="stockTransferOptions.selectedLocation?.Id" [stockLocationTypes]="stockDestinationTypes" [loadingLocations]="loadingDestinations" (selectLocationEvent)="stockTransferOptions.selectedDestination = $event" (continueEvent)="displayTransferConfirmPopup = true"></cybake-factory-select-transfer-destination>
  </div>
  }
  }
</div>
<!-- Scan barcode button -->
@if (currentStage && currentStage < 5 && !isScrolling) {
<div class="fixed bottom-0 left-0 z-3 p-4">
  <cybake-factory-button [translationKey]="barcodeScannerButton.TranslationKey"
                         [iconKey]="barcodeScannerButton.IconKey"
                         [pageSourceName]="barcodeScannerButton.PageSourceName"
                         [identifier]="barcodeScannerButton.Identifier"
                         [class]="barcodeScannerButton.Class"
                         [float]="barcodeScannerButton.Float"
                         [rounded]="!!barcodeScannerButton.Rounded"
                         (clickEvent)="openBarcodeScanner()">
  </cybake-factory-button>
</div>
<!-- Basket button -->
@if (basketItems.length && currentStage !== 4) {
<div class="fixed bottom-0 right-0 p-4 z-3 relative">

  <cybake-factory-button [translationKey]="trolleyButton.TranslationKey"
                         [iconKey]="trolleyButton.IconKey"
                         [pageSourceName]="trolleyButton.PageSourceName"
                         [identifier]="trolleyButton.Identifier"
                         [class]="trolleyButton.Class"
                         [countIndicatorValue]="basketItems.length"
                         [float]="trolleyButton.Float"
                         [rounded]="!!trolleyButton.Rounded"
                         (clickEvent)="currentStage = 4;setPageTitle()">
  </cybake-factory-button>
</div>
}
}

<!-- DIALOG SECTION -->
<!-- Init StartFlow Popup -->
@if(displayStartFlowPopup){
<cybake-factory-dialog [(visible)]="displayStartFlowPopup"
                       (hideEvent)="displayStartFlowPopup = false; backToDashboard()"
                       [identifier]="startflowDialog.Identifier"
                       [width]="startflowDialog.Width"
                       [pageSourceName]="startflowDialog.PageSourceName"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <div class="flex flex-column gap-3 items-center">
      <img class="popup-image-icon bg-gray-200 rounded-full p-4" height="120" width="120" src="assets/icons/trolley-icon.png" />
      <p class="text-center" cybake-text-style="body">
        {{ 'StockTransfer.TransferInProgress' | translate }}
      </p>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="dialogContinueButton.PageSourceName"
                               (click)="displayStartFlowPopup = false;currentStage=2; setPageTitle()"
                               [translationKey]="dialogContinueButton.TranslationKey"
                               [identifier]="dialogContinueButton.Identifier"
                               [class]="dialogContinueButton.Class">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="dialogStartOverButton.PageSourceName"
                               (click)="startOver(true,false)"
                               [translationKey]="dialogStartOverButton.TranslationKey"
                               [identifier]="dialogStartOverButton.Identifier"
                               [class]="dialogStartOverButton.Class">
        </cybake-factory-button>
      </div>
      <div class="pt-2 border-t-2 border-slate-200 w-full">
        @if(basketItems && basketItems.length && basketItems.length < 2){
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          {{'StockTransfer.SingleExistingItem' | translate }}
        </p>
        }
        @if(basketItems && basketItems.length && basketItems.length > 1 ){
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          {{ 'StockTransfer.MultipleExistingItems' | translate:{count: basketItems.length} }}
        </p>
        }
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>
}

<!-- Init Restart Two -->
@if(displayRestartFlowPopup){

<cybake-factory-dialog [(visible)]="displayRestartFlowPopup"
                       (hideEvent)="displayRestartFlowPopup = false; backToDashboard()"
                       [identifier]="restartflowDialog.Identifier"
                       [width]="restartflowDialog.Width"
                       [pageSourceName]="restartflowDialog.PageSourceName"
                       [dialogContentTemplate]="dialogContentTemplate6">
  <ng-template #dialogContentTemplate6>
    <div class="flex flex-column gap-3 items-center pb-4">
      <img class="popup-image-icon" src="assets/icons/product-icon.png" />
      <div class="flex flex-column items-center">
        <p class="text-center" cybake-text-style="body">
          {{ 'StockTransfer.WelcomeToTransfer' | translate }}
        </p>
        <p class="text-center" cybake-text-style="body-small">
          {{ 'StockTransfer.StartNewTransferOrContinue' | translate }}
        </p>
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="dialogNewTransferButton.PageSourceName"
                               (clickEvent)="displayRestartFlowPopup = false; startFlow()"
                               [translationKey]="dialogNewTransferButton.TranslationKey"
                               [identifier]="dialogNewTransferButton.Identifier"
                               [class]="dialogNewTransferButton.Class">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="dialogContinueButton.PageSourceName"
                               (clickEvent)="setContinueTransfer()"
                               [disabled]="!basketItems || !(basketItems && basketItems.length)"
                               [translationKey]="dialogContinueButton.TranslationKey"
                               [identifier]="dialogContinueButton.Identifier"
                               [class]="dialogContinueButton.Class">
        </cybake-factory-button>
      </div>
      @if(basketItems && basketItems.length){
      <div class="pt-2 border-t-2 border-slate-200 w-full">

        @if(basketItems && basketItems.length && basketItems.length < 2){
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          {{'StockTransfer.SingleTrolleyCount' | translate }}
        </p>
        }
        @if(basketItems && basketItems.length && basketItems.length > 1 ){
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          {{ 'StockTransfer.MultipleTrolleyCount' | translate:{count: basketItems.length} }}
        </p>
        }
      </div>
      }
    </div>
  </ng-template>
</cybake-factory-dialog>
}


<!-- Change StockLine quantity -->
@if(showEditStockLineDialog){

<cybake-factory-dialog [visible]="true"
                       [identifier]="changeQuantityDialog.Identifier"
                       [type]="changeQuantityDialog.Type"
                       (hideEvent)="showEditStockLineDialog = false;"
                       [width]="changeQuantityDialog.Width"
                       [title]="changeQuantityDialog.Title"
                       [dialogContentTemplate]="dialogContentTemplate7">
  <ng-template #dialogContentTemplate7>
    <div class="flex flex-column gap-3 items-center pb-4">
      <div class="flex flex-column items-center">
        @if(editStockTransferLineData){
        <cybake-factory-input-number [(value)]="count"
                                     (changeEvent)="handleCountValueChange($event)"
                                     [pageSourceName]="valueNumberInput.PageSourceName"
                                     [identifier]="valueNumberInput.Identifier"
                                     [min]="valueNumberInput.Min"
                                     [showSteppers]="valueNumberInput.ShowSteppers">
        </cybake-factory-input-number>
        }
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="dialogUpdateButton.PageSourceName"
                               (clickEvent)="updateStockLine()"
                               [translationKey]="dialogUpdateButton.TranslationKey"
                               [disabled]="!count"
                               [identifier]="dialogUpdateButton.Identifier"
                               [class]="dialogUpdateButton.Class">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>
}

<!-- Error If selecting lots and no lots -->
@if(currentStage === 3 && stockTransferOptions.selectedProduct?.IsTraceable && !lots.length && !loadingContainers){

<cybake-factory-dialog [visible]="true"
                       [identifier]="noActiveLotsDialog.Identifier"
                       [type]="noActiveLotsDialog.Type"
                       (hideEvent)="displayRestartFlowPopup = false; backToDashboard()"
                       [width]="noActiveLotsDialog.Width"
                       [dialogContentTemplate]="dialogContentTemplate6">
  <ng-template #dialogContentTemplate6>
    <div class="flex flex-column gap-3 items-center pb-4">
      <div class="flex flex-column items-center">
        <p class="text-center" cybake-text-style="body">
          {{ 'StockTransfer.NoActiveLots' | translate }}
        </p>
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="dialogContinueButton.PageSourceName"
                               (clickEvent)="currentStage = 2"
                               [translationKey]="dialogContinueButton.TranslationKey"
                               [identifier]="dialogContinueButton.Identifier"
                               [class]="dialogContinueButton.Class">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>
}
<!-- Error when service call failed -->
@if(loadingLotsError || loadingSitesError || loadingProductsError || loadingContainersError){

<cybake-factory-dialog [visible]="true"
                       [identifier]="loadingErrorDialog.Identifier"
                       [type]="loadingErrorDialog.Type"
                       (hideEvent)="displayRestartFlowPopup = false; backToDashboard()"
                       [width]="loadingErrorDialog.Width"
                       [dialogContentTemplate]="dialogContentTemplate8">
  <ng-template #dialogContentTemplate8>
    <div class="flex flex-column gap-3 items-center pb-4">
      <div class="flex flex-column items-center">
        <p class="text-center" cybake-text-style="body">
          {{
          (loadingLotsError?
          'StockTransfer.LotError':
          loadingSitesError? 'StockTransfer.SiteError':
          loadingContainersError? 'StockTransfer.ContainerError':
          'StockTransfer.ProductError' ) | translate
          }}
        </p>
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="closeButton.PageSourceName"
                               (clickEvent)="loadingLotsError = false; loadingSitesError = false; loadingProductsError = false; loadingContainersError = false"
                               [translationKey]="closeButton.TranslationKey"
                               [identifier]="closeButton.Identifier"
                               [class]="closeButton.Class">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>
}

<!-- Cancel Transfer Confirmation -->

<cybake-factory-dialog [title]="cancelTransferConfirmationDialog.Title"
                       [identifier]="cancelTransferConfirmationDialog.Identifier"
                       [type]="cancelTransferConfirmationDialog.Type"
                       [(visible)]="displayCancelTransferPopup"
                       [closable]="cancelTransferConfirmationDialog.Closable"
                       [width]="cancelTransferConfirmationDialog.Width"
                       [dialogContentTemplate]="dialogContentTemplate2">
  <ng-template #dialogContentTemplate2>
    <div class="grid m-0">
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="yesButton.PageSourceName"
                               (click)="deleteAll(); backToDashboard()"
                               [translationKey]="yesButton.TranslationKey"
                               [identifier]="yesButton.Identifier"
                               [class]="yesButton.Class + ' px-4'">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="noButton.PageSourceName"
                               (click)="displayCancelTransferPopup = false;"
                               [translationKey]="noButton.TranslationKey"
                               [identifier]="noButton.Identifier"
                               [class]="noButton.Class + ' px-4'">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>


<!--Transfer Now Confirmation   -->

<cybake-factory-dialog [title]="getTransferDialogTitle()"
                       [type]="!(returnFilteredItems && returnFilteredItems.length)?'error':'question'"
                       [(visible)]="displayTransferConfirmPopup"
                       [identifier]="transferNowDialog.Identifier"
                       [closable]="transferNowDialog.Closable"
                       [width]="transferNowDialog.Width"
                       [dialogContentTemplate]="dialogContentTemplate3">
  <ng-template #dialogContentTemplate3>
    <div class="grid m-0">
      <div class="flex flex-col pb-6">
        @if(returnFilteredItems && returnFilteredItems.length){
        <p cybake-text-style="body-xsmall"><b>{{ 'StockTransfer.Products' | translate }}</b>: {{getCommaSeparatedProductNames(returnFilteredItems)}}</p>

        @if(calculateTotalWeight(returnFilteredItems || [])){
        <p cybake-text-style="body-xsmall"><b>{{ 'StockTransfer.TotalWeight' | translate }}</b>: {{ calculateTotalWeight(returnFilteredItems || []) }}</p>
        }
        @if(calculateSinglesTotalWeight(returnFilteredItems || [])){
        <p cybake-text-style="body-xsmall"><b>{{ 'StockTransfer.TotalSingles' | translate }}</b>: {{ calculateSinglesTotalWeight(returnFilteredItems || []) }} {{  'StockTransfer.Singles' | translate }}</p>
        }
        }

        <p cybake-text-style="body-xsmall"><b>{{ 'StockTransfer.Source' | translate }}</b>: {{ stockTransferOptions.selectedLocation?.Name }}</p>

        <p cybake-text-style="body-xsmall"><b>{{ 'StockTransfer.Destination' | translate }}</b>: {{ stockTransferOptions.selectedDestination?.Name }}</p>
        @if(!(returnFilteredItems && returnFilteredItems.length)){
        <p cybake-text-style="body-xsmall" class="text-red-300">{{ 'StockTransfer.ProductsNotAccepted' | translate }}</p>
        }
      </div>
      <div class="flex flex-row gap-3 justify-center">
        @if((returnFilteredItems && returnFilteredItems.length)){
        <cybake-factory-button [pageSourceName]="okButton.PageSourceName"
                               (clickEvent)="doStockTransfer()"
                               [disabled]="!(returnFilteredItems.length)"
                               [loading]="processing"
                               [translationKey]="okButton.TranslationKey"
                               [identifier]="okButton.Identifier"
                               [class]="okButton.Class + ' px-4'">
        </cybake-factory-button>
        }
        <cybake-factory-button [pageSourceName]="cancelButton.PageSourceName"
                               (click)="displayTransferConfirmPopup = false;"
                               [translationKey]="cancelButton.TranslationKey"
                               [identifier]="cancelButton.Identifier"
                               [class]="cancelButton.Class + ' px-4'">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>

<!--Scan Barcode Dialog-->
<cybake-factory-dialog [title]="barCodeDialog.Title"
                       [(visible)]="displayBarcodeScanner"
                       [identifier]="barCodeDialog.Identifier"
                       [closable]="barCodeDialog.Closable"
                       [width]="barCodeDialog.Width"
                       [dialogContentTemplate]="dialogContentTemplate1">
  <ng-template #dialogContentTemplate1>
    <cybake-factory-barcode-scan></cybake-factory-barcode-scan>
  </ng-template>
</cybake-factory-dialog>

<!-- Transfer continuation popup -->
<cybake-factory-dialog [(visible)]="displayConfirmPopup"
                       (hideEvent)="displayConfirmPopup = false;"
                       [identifier]="transferNowDialog.Identifier"
                       [width]="transferNowDialog.Width"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <div class="flex flex-column gap-3 items-center">
      <img class="popup-image-icon bg-gray-200 rounded-full p-4" height="120" width="120" src="assets/icons/trolley-icon.png" />
      <p class="text-center" cybake-text-style="body">
        {{ 'StockTransfer.TransferInProgress' | translate }}
      </p>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="dialogStartOverButton.PageSourceName"
                               (click)="startOver()"
                               [translationKey]="dialogStartOverButton.TranslationKey"
                               [identifier]="dialogStartOverButton.Identifier"
                               [class]="dialogStartOverButton.Class">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="cancelButton.PageSourceName"
                               (click)="displayConfirmPopup = false;"
                               [translationKey]="cancelButton.TranslationKey"
                               [identifier]="cancelButton.Identifier"
                               [class]="cancelButton.Class">
        </cybake-factory-button>
      </div>
      @if(basketItems && basketItems.length){

      <div class="pt-2 border-t-2 border-slate-200 w-full">
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          {{ basketItems.length === 1 ? 'StockTransfer.SingleExistingItem' : 'StockTransfer.MultipleExistingItems' | translate:{count: basketItems.length} }}
        </p>
      </div>
      }
    </div>
  </ng-template>
</cybake-factory-dialog>

<!--Stock Transfer Product Info-->
<cybake-factory-dialog [(visible)]="displayProductInfo"
                       [identifier]="productInfoDialog.Identifier"
                       [width]="productInfoDialog.Width"
                       [dialogContentTemplate]="stockTransferProductInfo">
  <ng-template #stockTransferProductInfo>

    <cybake-factory-product-info [name]="stockTransferProductInfoDialog.Name"
                                 [code]="stockTransferProductInfoDialog.Code || ''"
                                 [isTraceable]="stockTransferProductInfoDialog.IsTraceable"
                                 [image]="stockTransferProductInfoDialog.ImageUrl"
                                 (closeEvent)="displayProductInfo = false">
    </cybake-factory-product-info>

  </ng-template>

</cybake-factory-dialog>

