<div class="w-full" style=" margin-top: -20px;">

  @if (image) {
  <div>
    <cybake-factory-image [pageSourceName]="pageSourceName"
                          [identifier]="'product'"
                          [verticalAlign]="'vertically-center'"
                          [width]="250"
                          [height]="250"
                          [value]="image">
    </cybake-factory-image>
  </div>
  }

  @if (name) {
  <div class="text-center text-3xl">
    {{name}}
  </div>
  }

  @if (code) {
  <div class="text-center pt-1 text-xl">
    {{code}}
  </div>
  }

  @if (isTraceable) {
  <div class="mt-2" style=" width: fit-content; margin: auto;">

    <div class="">
      <cybake-factory-tag [pageSourceName]="pageSourceName"
                          [identifier]="'traceable'"
                          [value]="'Traceable'">
      </cybake-factory-tag>
    </div>
  </div>
  }

  <div class="col-12 p-0 pt-4">
    <cybake-factory-button [pageSourceName]="pageSourceName"
                           [identifier]="'close'"
                           [translationKey]="'ProductInfo.CloseButton'"
                           [fullWidth]="true"
                           (clickEvent)="this.closeEvent.emit(true)">
    </cybake-factory-button>
  </div>

</div>
