@if (!hideLabel) {
<div id=""
     class="w-full m-auto text-center {{labelClass}}">

  @if (label) {
  <div class="w-full">
    {{label | translate:labelTranslationParams}}
  </div>
  }

  @if (subLabel) {
  <div class="w-full text-base">
    {{subLabel | translate:labelTranslationParams}}
  </div>
  }

  @if (!label) {
  {{value}}
  }

</div>
}

<div class="w-full"
     [ngClass]="{'inline-flex': showSteppers && !isMobilePortrait}">

  <div class="m-auto"
       [ngClass]="{'inline-flex': !isMobilePortrait}">

    @if (showSteppers && !isMobilePortrait) {
    <div>
      <div class="vertically-center pr-6">
        <cybake-factory-button #inputNumberDecreaseButton
                               [iconKey]="['fa-regular', 'fa-chevron-down']"
                               [pageSourceName]="pageSourceName"
                               [identifier]="'decreaseInputNumber'"
                               [class]="'cybake'"
                               [customClass]="'text-6xl dial-decrease-button'"
                               [bigIcon]="true"
                               [rounded]="true"
                               [disabled]="(min !== undefined || min !== null ? (value === min) : false) || loading"
                               (mousedown)="decreaseNumber()"
                               (mouseup)="decreaseTimeoutClear()"
                               (mouseleave)="decreaseTimeoutClear()">
        </cybake-factory-button>
      </div>
    </div>
    }

    <div class="relative"
         [style.height]="size + 'px'">
      <div id="{{pageSourceName}}_{{identifier}}_dial_div"
           class="w-full">
        <p-knob #cybakeDial
                [ngModel]="(value + quantityUsed) > max ? max : (value + quantityUsed)"
                [min]="min"
                [max]="max"
                [size]="size"
                [disabled]="disabled || loading"
                [readonly]="readOnly"
                (onChange)="dialUpdateTouch($event)"
                valueColor="{{valueColor}}"
                rangeColor="{{rangeColor}}"
                valueTemplate="">
        </p-knob>
      </div>

      <div class="absolute text-5xl"
           style="top: 35%; left: 0; right: 0; margin: auto; text-align: center;"
           [style.height]="(loading ? '33%' : '')"
           [style.width]="(size / 2) + 20 + 'px'">

        @if (loading) {
        <i id="{{ pageSourceName }}_{{ identifier }}_dial_loading_icon"
           class="fa-icon fa-regular fa-circle-notch fa-spin">
        </i>
        }

        @if (!loading) {
        @if (!complete) {
        <cybake-factory-input-number [value]="value"
                                     (valueChange)="inputNumberChange($event)"
                                     [pageSourceName]="pageSourceName"
                                     [identifier]="'quantityProduced'"
                                     [decimal]="true"
                                     [maxFractionDigits]="3"
                                     [showSteppers]="false">
        </cybake-factory-input-number>

        <div class="mt-2">
          <div class="w-full text-2xl">
            {{measure}}
          </div>
        </div>
        }

        <div class="mt-2"
             (click)="(!complete && (value + quantityUsed) < max) ? this.dialCenterClick.emit() : ''">

          @if ((value + quantityUsed) < max && !complete) {
          <div class="w-full c-success" style="font-size: 2rem">
            <i class="fa-solid fa-circle-down"></i>
          </div>
          }

          @if (complete) {
          <div class="mb-1 w-full">
            <div class="font-semibold text-6xl">
              <i class="fa-duotone fa-solid fa-check c-success"></i>
            </div>
          </div>

          @if (completeLabel) {
          <div class="w-full text-2xl">
            {{completeLabel | translate}}
          </div>
          }
          }

        </div>
        }

      </div>
    </div>

    @if (showSteppers && isMobilePortrait) {
    <div class="w-full inline-flex">
      <div>
        <div class="vertically-center pr-6">
          <cybake-factory-button #inputNumberDecreaseButton
                                 [iconKey]="['fa-regular', 'fa-chevron-down']"
                                 [pageSourceName]="pageSourceName"
                                 [identifier]="'decreaseInputNumber'"
                                 [class]="'cybake'"
                                 [customClass]="'text-6xl dial-decrease-button'"
                                 [bigIcon]="true"
                                 [rounded]="true"
                                 [disabled]="(min !== undefined || min !== null ? (value === min) : false) || loading"
                                 (mousedown)="decreaseNumber()"
                                 (mouseup)="decreaseTimeoutClear()"
                                 (mouseleave)="decreaseTimeoutClear()">
          </cybake-factory-button>
        </div>
      </div>

      <div>
        <div class="vertically-center pl-6">
          <cybake-factory-button #inputNumberIncreaseButton
                                 [iconKey]="['fa-regular', 'fa-chevron-up']"
                                 [pageSourceName]="pageSourceName"
                                 [identifier]="'increaseInputNumber'"
                                 [class]="'cybake'"
                                 [customClass]="'text-6xl dial-increase-button'"
                                 [bigIcon]="true"
                                 [rounded]="true"
                                 [disabled]="loading"
                                 (mousedown)="increaseNumber()"
                                 (mouseup)="increaseTimeoutClear()"
                                 (mouseleave)="increaseTimeoutClear()">
          </cybake-factory-button>
        </div>
      </div>
    </div>
    }

    @if (showSteppers && !isMobilePortrait) {
    <div>
      <div class="vertically-center pl-6">
        <cybake-factory-button #inputNumberIncreaseButton
                               [iconKey]="['fa-regular', 'fa-chevron-up']"
                               [pageSourceName]="pageSourceName"
                               [identifier]="'increaseInputNumber'"
                               [class]="'cybake'"
                               [customClass]="'text-6xl dial-increase-button'"
                               [bigIcon]="true"
                               [rounded]="true"
                               [disabled]="loading"
                               (mousedown)="increaseNumber()"
                               (mouseup)="increaseTimeoutClear()"
                               (mouseleave)="increaseTimeoutClear()">
        </cybake-factory-button>
      </div>
    </div>
    }

  </div>

</div>
