<div class="w-full slide-right-animation"
     [ngClass]="{ 'pt-3': index }">

  <cybake-factory-card [title]="productionPlanCard.Title"
                       [subSubTitle]="productionPlanCard.SubTitle"
                       [routerLink]="productionPlanCard.RouterLink"
                       [pageSourceName]="productionPlanCard.PageSourceName"
                       [identifier]="productionPlanCard.Identifier"
                       [cardContentTemplate]="productionPlanCardContentTemplate"
                       (longPressEvent)="this.viewPlanNoteEvent.emit()"
                       (clickEvent)="this.viewPlanEvent.emit()">

    <ng-template #productionPlanCardContentTemplate>
      <div class="w-full inline-flex text-lg">
        <div class="w-10_12">
          <div class="vertically-center-left-align font-semibold"
               cybake-text-style="body-xsmall">
            @if (plan.TotalNumberOfLines === plan.NumberOfLinesCompleted) {
            {{'Production.ProductionPlanCard.TotalItemsCompleteLabel' | translate}}
            }

            @if (plan.TotalNumberOfLines !== plan.NumberOfLinesCompleted) {
            {{ plan.TotalNumberOfLines }}

            @if (!isMobilePortrait) {
            {{'Production.ProductionPlanCard.TotalItemsLabel' | translate}}
            }

            @if (isMobilePortrait) {
            {{'Production.ProductionPlanCard.TotalItemsMobileLabel' | translate}}
            }
            }
          </div>
        </div>

        <div class="w-5_12">
          <div class="vertically-center-right-align pr-3 font-semibold"
               cybake-text-style="body-xsmall">

            @if (!isMobilePortrait) {
            {{'Production.ProductionPlanCard.StartTimeLabel'| translate}}:
            }

            {{plan.StartTime}}
          </div>
        </div>

        <div class="">
          <div class="float-end vertically-center-right-align">
            <cybake-factory-progress-pie [total]="plan.TotalNumberOfLines"
                                         [completed]="plan.NumberOfLinesCompleted"
                                         [showCheckmark]="plan.TotalNumberOfLines === plan.NumberOfLinesCompleted">
            </cybake-factory-progress-pie>
          </div>
        </div>
      </div>
    </ng-template>
  </cybake-factory-card>
</div>
