<div class="grid m-0">
  <div class="col-12 p-0 pb-2">
    <cybake-factory-listbox
      [options]="lotsListBox.Options"
      [(value)]="lotsListBox.Value"
      [loading]="loading"
      [optionValue]="lotsListBox.OptionValue"
      [optionLabel]="lotsListBox.OptionLabel"
      [optionLabel2]="lotsListBox.OptionLabel2 ? lotsListBox.OptionLabel2 : ''"
      [emptyTranslationKey]="'Production.StockLotSelection.EmptyStockLots'"
      [emptyIcon]="'fa-kit-duotone fa-duotone-solid-box-circle-xmark'"
      [filter]="true"
      [filterBy]="lotsListBox.FilterBy"
      [radioButtons]="true"
      [height]="414"
    >
    </cybake-factory-listbox>
  </div>

  <div class="col-12 p-0 pt-2">
    <cybake-factory-button
      [pageSourceName]="confirmButton.PageSourceName"
      [identifier]="confirmButton.Identifier"
      [translationKey]="confirmButton.TranslationKey"
      [disabled]="lotsListBox.Value === null || loading"
      [class]="confirmButton.Class"
      [iconKey]="confirmButton.IconKey"
      [iconClass]="confirmButton.IconClass"
      [fullWidth]="true"
      (clickEvent)="confirmLot()"
    >
    </cybake-factory-button>
  </div>
</div>
