<div class="flex flex-col" id="noIconAccordion">
  @if(loading){
  <div class="flex flex-col gap-2">
    @for ( item of [1,2,3,4,5,6,2,3,3,4,2,3]; track item ) {
    <cybake-factory-card [loading]="true" [pageSourceName]="loadingCard.PageSourceName"  [identifier]="loadingCard.Identifier"></cybake-factory-card>
    }
  </div>
  }
  @if(!loading){

  <p-accordion class="w-full flex flex-column gap-3"
               [activeIndex]="lots.length === 1 ? 0:-1">
    @for (lot of lots; track lot; let idx = $index) {
    <p-accordionTab id="{{ pageSourceName + '_' + lot.Id }}_tab" class="cybake-lot-tab bottom-border-radial flex flex-row cursor-pointer bg-white items-center relative border border-slate-300 justify-content-between">
      <ng-template pTemplate="header">
        <span class="flex justify-content-between gap-1 w-full px-2">
          <p id="{{ pageSourceName + '_' + lot.Id }}_lotnumber"
             class="white-space-nowrap text-black"
             cybake-text-style="body-small">
            {{ 'LotsList.Lot' | translate }} {{ lot.SystemLotNumber }}
          </p>
          <p id="{{ pageSourceName + '_' + lot.Id }}_lotexpiry"
             class="text-black"
             cybake-text-style="body-small">
            {{ 'LotsList.Expiry' | translate }} {{ lot.ExpiryDate | date: 'dd/MM/yyyy' }}
          </p>
        </span>
      </ng-template>
      <cybake-factory-lot-card (transferEvent)="handleValueChange($event)"
                               [basketLot]="returnItem(lot)"
                               [lotIndex]="idx"
                               [pageSourceName]="pageSourceName"
                               [containers]="containers"
                               [lot]="lot">
      </cybake-factory-lot-card>
    </p-accordionTab>
    }
  </p-accordion>
  }
</div>
