import { CyBakeTemplate } from '../cybake-template.model';

export class CyBakeInput extends CyBakeTemplate {  
  Class?: string;
  InputClass?: string;

  IconTooltipTranslation?: string;
  IconTooltipTranslationParams?: object;
  IconTooltipPosition?: string = 'top';

  Placeholder: boolean = false;
  ShowClear?: boolean = true;
  Clearable?: boolean = false;
  HideCharcterLimit?: boolean = false;
  Required?: boolean = false;

  MaximumCharacters?: number;

  IconKey?: string[];
  IconClass?: string = '';

  constructor(values: CyBakeInput) {
    super(values);
    Object.assign(this, values);
  }
}
