import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StockLocationType } from '../models/stock-transfers/stocks/stock-location-types.model';
import { Site } from '../models/sites/site.model';
import { GetStockLocationTypeResponse } from '../models/stock-transfers/stocks/stock-location-type-response.model';
import { StockTransferType } from '../models/stock-transfers/stocks/stock-transfer.model';
import { GetProductResponse } from '../models/stock-transfers/product/product-response';
import { Product } from '../models/stock-transfers/product/product.model';
import { Trolley } from '../models/stock-transfers/product/trolley.model';
import { GetContainersResponse } from '../models/stock-transfers/product/container-response.model';
import { StockTransferLotContainer } from '../models/stock-transfers/product/container.model';
import { GetLotsResponse } from '../models/stock-transfers/product/lot-response.model';
import { LocalStorageService } from './common/local-storage.service';
import { GetDestinationStockTransferValidProductRequest } from '../models/stock-transfers/stocks/get-destination-stock-transfer-valid-product-request.model';
import { GetStockTransferDestinationTypeResponse } from '../models/stock-transfers/stocks/stock-transfer-destination-type-response.model';
import { StockLot } from '../models/stock-transfers/stocks/stock-lot.model';

@Injectable({
  providedIn: 'root',
})

export class StockTransfersService {

  // Services
  http: HttpClient = inject(HttpClient);
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Variables
  _options: object = { responseType: 'blob', observe: 'response' };

  // Service Calls
  getStockLocations(): Observable<StockLocationType[]> {
    let url = 'api/stocktransfer/locations';
    const site: Site = this.localStorageService.getItem('deviceSite');
    if (site) {
      url += `?siteId=${site.Id}`;
    }

    return this.http.get<GetStockLocationTypeResponse>(environment.api + url).pipe(
      map((getStockLocationTypeResponse: GetStockLocationTypeResponse) => {
        return getStockLocationTypeResponse.StockLocationTypes;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  createStockTransferRequest(request?: StockTransferType | null): Observable<null> {
    const url = `api/stocktransfer/create`;

    return this.http.post<null>(environment.api + url, request).pipe(
      map((response: null) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProducts(id: number): Observable<Product[]> {

    const url = `api/stocktransfer/locations/${id}/products`;

    return this.http.get<GetProductResponse>(environment.api + url).pipe(
      map((getProductsResponse: GetProductResponse) => {
        return getProductsResponse.ProductListItems;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductImage(productId: number): Observable<Blob> {
    const url = `api/stocktransfer/products/${productId}/image`;

    return this.http.get(environment.api + url, { ...this._options, observe: 'response', responseType: 'blob' }).pipe(
      map((data: HttpResponse<Blob>) => {
        const blob = new Blob([data.body!], { type: 'application/octet-stream' });
        return blob;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  getLots(id: number): Observable<StockLot[]> {
    const url = `api/stocktransfer/products/${id}/lots`;

    return this.http.get<GetLotsResponse>(environment.api + url).pipe(
      map((getLotsResponse: GetLotsResponse) => {
        return getLotsResponse.StockLots;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getContainers(id: number): Observable<StockTransferLotContainer[]> {
    const url = `api/stocktransfer/products/${id}/containers`;

    return this.http.get<GetContainersResponse>(environment.api + url).pipe(
      map((getContainerResponse: GetContainersResponse) => {
        return getContainerResponse.ProductContainerListItems;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getTrolleys(): Observable<Trolley[]> {
    const url = 'api/trolleys';

    return this.http.get<Trolley[]>(environment.api + url).pipe(
      map((getTrolleyResponse: Trolley[]) => {
        return getTrolleyResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getDestinationStockLocationProducts(request?: GetDestinationStockTransferValidProductRequest): Observable<StockLocationType[]> {
    const url = `api/stocktransfer/locations/products`;

    return this.http.post<GetStockTransferDestinationTypeResponse>(environment.api + url, request).pipe(
      map((getStockLocationTypeResponse: GetStockTransferDestinationTypeResponse) => {
        return getStockLocationTypeResponse.StockLocationTypesValidProducts;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }


}
