import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProductionPlanItemRecipeLine } from '../../_shared/models/production/plans/production-plan-item-recipe-line.model';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { CyBakeDialComponent } from '../../_shared/components/dial/dial.component';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { CyBakeInputNumberComponent } from '../../_shared/components/input-number/input-number.component';
import { PersistProductionUsedLineRequest } from '../../_shared/models/production/plans/persist-production-used-line-request.model';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { StockLot } from '../../_shared/models/production/stocklots/stock-lot.model';
import { CyBakeProgressPieComponent } from '../../_shared/components/progress-pie/progress-pie.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { FloorPipe } from '../../_shared/pipes/number-floor.pipe';

@Component({
  selector: 'cybake-factory-production-plan-recipe-line-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeCardComponent,
    CyBakeDialComponent,
    CyBakeButtonComponent,
    CyBakeInputNumberComponent,
    CyBakeProgressPieComponent,
    DecimalPipe,
    FloorPipe
  ],
  templateUrl: './production-plan-recipe-line.component.html',
  styleUrl: './production-plan-recipe-line.component.scss'
})
export class ProductionPlanRecipeLineCardComponent implements OnInit, OnChanges {

  // Children
  @ViewChild('recipleLineDial') recipeLineDial!: CyBakeDialComponent;

  // Inputs/Outputs
  @Input() recipeLine!: ProductionPlanItemRecipeLine;
  @Input() index!: number;
  @Input() selected!: boolean;
  @Input() selectedMethod!: boolean;
  @Input() loading!: boolean;
  @Input() stockLot?: StockLot;
  @Input() usedLine?: PersistProductionUsedLineRequest | null;

  @Output() viewProductionPlanProductRecipeEvent: EventEmitter<null> = new EventEmitter();
  @Output() openStockLotsEvent: EventEmitter<null> = new EventEmitter();
  @Output() confirmAddEditRecipeLineUsedEvent: EventEmitter<null> = new EventEmitter();
  @Output() cancelAddingEditingMeasureEvent: EventEmitter<null> = new EventEmitter();
  @Output() removeMeasureEvent: EventEmitter<null> = new EventEmitter();

  // Services
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  // Variables
  pageSourceName: string = 'productionPlanProductRecipeLineCard';
  recipeLineDialColour!: string;
  defaultButtonType: ButtonTypeEnum = ButtonTypeEnum.default;

  // Stock Lots Button
  stockLotsButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'stockLots',
    Class: ButtonClassEnum.default,
    TranslationKey:
      'Production.ProductionPlanRecipeLineCard.StockLotBtnPlaceholder',
    IconKey: ['fa-sharp', 'fa-barcode-scan'],
    Loading: false,
    TranslationFromData: false,
  });

  // Confirm Adding Measure Button
  confirmAddingMeasureButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.add,
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmAddingEditingMeasure',
    Class: ButtonClassEnum.success,
    TranslationKey:
      'Production.ProductionPlanRecipeLineCard.ConfirmAddingMeasureBtn',
  });

  // Cancel Adding Measure Button
  cancelAddingMeasureButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'cancelAddingEditingMeasure',
    Class: ButtonClassEnum.warning,
    TranslationKey: 'Production.ProductionPlanRecipeLineCard.CancelAddingMeasureBtn',
    IconKey: ['fa-solid', 'fa-xmark'],
  });

  // Remove  Adding Measure Button
  removeAddingMeasureButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.delete,
    PageSourceName: this.pageSourceName,
    Identifier: 'removeMeasure',
    Class: ButtonClassEnum.danger,
    TranslationKey: 'Production.ProductionPlanRecipeLineCard.RemoveMeasureBtn',
    IconKey: ['fa-sharp-duotone fa-solid', 'fa-trash'],
  });

  // Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  // Functions

  ngOnInit() {
    // Breakpoint Observer
    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((state: BreakpointState) => {
        this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
        this.isMobileLandscape =
          state.breakpoints[Breakpoints.HandsetLandscape];
        this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
        this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['usedLine'] || changes['loadingUsedLines']) {
      if (!this.loading) {
        this.updateDial();
      }
    }
  }

  getRecipeLineDialColour(recipeLine: ProductionPlanItemRecipeLine) {
    const minErrorValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance * 2;
    const maxErrorValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance * 2;

    const minSuccessValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance;
    const maxSuccessValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance;

    const usedValue = this.recipeLine!.QuantityUsed + this.usedLine!.Quantity;

    if (usedValue <= minErrorValueThreshold || usedValue >= maxErrorValueThreshold) {
      this.recipeLineDialColour = 'var(--danger)';
    }

    if (
      usedValue >= minSuccessValueThreshold &&
      usedValue <= maxSuccessValueThreshold
    ) {
      this.recipeLineDialColour = 'var(--success)';
    }

    if (
      (usedValue > minErrorValueThreshold &&
        usedValue < minSuccessValueThreshold) ||
      (usedValue > maxSuccessValueThreshold &&
        usedValue < maxErrorValueThreshold)
    ) {
      this.recipeLineDialColour = 'var(--warning)';
    }
  }

  addRemainingRequiredAmount() {
    this.usedLine!.Quantity = this.recipeLine!.StillRequired ?? 0;
    this.updateDial();
  }

  updateDial() {
    setTimeout(() => {
      this.getRecipeLineDialColour(this.recipeLine!);
    }, 1);
  }

  updateDialTouchEvent(value: number) {
    //this.recipeLineDial.value = value
    //this.recipeLineDial.dialRef.value = value;
    this.usedLine!.Quantity = value;

    this.updateDial();
  }

  updateDialSteppersEvent(value: number) {
    this.usedLine!.Quantity = value;
    this.updateDial();
  }
}
