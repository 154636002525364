import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { faker } from '@faker-js/faker';
import { ProductionLocation } from '../../models/production/locations/production-location.model';
import { ProductionPlan } from '../../models/production/plans/production-plan.model';
import { ProductionShift } from '../../models/production/shifts/production-shift.model';
import moment from 'moment';
import { StockLot } from '../../models/production/stocklots/stock-lot.model';
import { ProductionPlanProduct } from '../../models/production/plans/production-plan-product.model';
import { ProductionPlanProductMix } from '../../models/production/plans/production-plan-recipe-line-mix.model';
import { ProductionPlanItemRecipeLine } from '../../models/production/plans/production-plan-item-recipe-line.model';
import { ProductionPlanProductRecipeMethod } from '../../models/production/plans/production-plan-recipe-methods.model';
import { ProductionUsedLine } from '../../models/production/plans/production-used-line.model';
import { CompleteProductionPlanLineRecipeLineResponse } from '../../models/production/plans/complete-recipe-line-response.model';
import { GetProductionPlanProductRecipeMethodLineResponse } from '../../models/production/plans/get-production-plan-product-recipe-method-line-response.model';
import { CompleteProductionPlanLineResponse } from '../../models/production/plans/complete-production-plan-line-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProductionServiceMock {
  // Variables

  delayTimer: number = 1000;
  weightUnits: string[] = ['g', 'kgs', 'litres', 'lbs', 'ml', 'tonnes', 'oz'];
  containers: string[] = ['Units', 'Trays', 'Boxes', 'Pallets'];
  ingrediants: string[] = [
    'Flour',
    'Water',
    'Yeast',
    'Butter',
    'Milk',
    'Sugar',
    'Salt',
  ];
  methodSteps: string[] = [
    '1 - Mixing',
    '2 - Bulk Fermentation',
    '3 - Scaling',
    '4 - Intermediate Proof',
    '5 - Make Up & Final Fermentation',
    '6 - Decoration (before baking)',
    '7 - Baking'
  ];
  methodDescriptions: string[] = [
    '<p>Mixing Spiral</p><p>Add all ingredients to mixing bowl</p><p>4 minutes on slow</p><p>Scrape down</p><p>6 minutes on fast</p><p>Dough temperature: 24C</p>',
    '<p>Bulk Fermentation: 2 x 20 minutes at ambient temperature</p>',
    '<p>Scale: 320g</p><p>Make up half long, not too tight</p>',
    '<p>Intermediate Proof: 15 minutes at ambient temperature</p>',
    '<p>Make up: Long, 50cm.</p><p>Stretch the tips of the baguette</p><p>Final Fermentation: 40 minutes at 28C and 85% R.H.</p>',
    '<p>Must be done before baking</p><p>Dust with flour</p><p>Score 5 times on a slight diagonal, using a lame</p>',
    '<p>Oven temperature: 230C in deck oven</p><p>Baking time: 25 minutes with steam</p>'
  ]

  getCurrentProductionShift(): Observable<ProductionShift> {
    const shift: ProductionShift = new ProductionShift({
      Id: faker.number.int(),
      Name: moment().format('ddd') + ' Shift',
      StartTime: new Date((moment().format('YYYY-MM-DD 14:00'))),
      EndTime: new Date((moment().format('YYYY-MM-DD 04:00')))
    });

    return of(shift).pipe(delay(this.delayTimer));
  }

  getStockLots(): Observable<StockLot[]> {
    const stockLots: StockLot[] = [];

    for (let i = 0; i < 20; i++) {
      const stockLot: StockLot = new StockLot({
        Id: i + 1,
        SystemLotNumber: faker.number.int({ min: 100000000, max: 999999999 }),
        ExpiryDate: faker.date.soon(),
      });

      stockLots.push(stockLot);
    }

    return of(stockLots).pipe(delay(this.delayTimer));
  }

  getProductionPlans(): Observable<ProductionPlan[]> {
    const plans: ProductionPlan[] = [];

    for (let i = 0; i < 4; i++) {
      const plan: ProductionPlan = new ProductionPlan({
        Id: i + 1,
        Name: faker.commerce.productName(),
        Note: faker.lorem.sentences(),
        TotalNumberOfLines: 5,
        NumberOfLinesCompleted: 0,
        StartTime: moment().add(i + 1, 'hours').format('HH:mm'),
        Sequence: i
      });

      plans.push(plan);
    }

    return of(plans).pipe(delay(this.delayTimer));;
  }

  getProductionLocations(): Observable<ProductionLocation[]> {
    const locations: ProductionLocation[] = [];

    for (let i = 0; i < 3; i++) {
      const location: ProductionLocation = {
        Id: i,
        Name: faker.commerce.department(),
        Sequence: i,
      };

      locations.push(location);
    }

    return of(locations).pipe(delay(this.delayTimer));;
  }

  getProductionPlanProducts(): Observable<ProductionPlanProduct[]> {
    const products: ProductionPlanProduct[] = [];

    for (let i = 0; i < 5; i++) {
      const product: ProductionPlanProduct = {
        Id: faker.number.int(),
        ProductId: faker.number.int(),
        ProductCode: faker.number.int().toString(),
        ProductName: faker.commerce.productName(),
        ProductContainerDisplayName: (faker.datatype.boolean() ? this.containers[faker.number.int({ min: 0, max: 2 })] + '(10 singles)' : undefined),
        ImageLoading: false,
        HasSubLines: (i === 2 || faker.datatype.boolean()) ? true : false,
        TotalNumberOfLines: 5,
        NumberOfLinesCompleted: 0,
        IsTraceable: faker.datatype.boolean(),
        Completed: false,
        Sequence: i + 1
      };

      products.push(product);
    }

    return of(products).pipe(delay(this.delayTimer));;
  }

  getProductionPlanProductMixes(): Observable<ProductionPlanProductMix[]> {

    const mixes: ProductionPlanProductMix[] = [];

    for (let i = 0; i < 3; i++) {
      const mix: ProductionPlanProductMix = {
          Id: faker.number.int(),
          Completed: false,
          TotalWeight: faker.number.int({ min: 10, max: 100 }),
          Measure: 'kgs',
          TotalNumberOfLines: 5,
          NumberOfLinesCompleted: 0,
          MixNumber: i + 1,
          Quantity: 1,
          Sequence: 1
      };

      mixes.push(mix);
    }

    return of(mixes).pipe(delay(this.delayTimer));;
  }

  getProductionPlanMethodLines(productionPlanId: number): Observable<ProductionPlanProductRecipeMethod[]> {

    const methods: ProductionPlanProductRecipeMethod[] = [];

    if (productionPlanId === 4 || faker.datatype.boolean()) {

      for (let i = 0; i < 7; i++) {
        const method: ProductionPlanProductRecipeMethod = {
          Id: i + 1,
          Title: this.methodSteps[i],
          Sequence: i,
          Method: this.methodDescriptions[i],
          RecipeLines: [],
          TotalLines: 0,
          CompletedLines: 0
        };

        const randomNum: number = faker.number.int({ min: 0, max: 3 });

        for (let i = 0; i < randomNum; i++) {

          const weightUnitIndex = faker.number.int({ min: 0, max: 6 });

          const recipeLine: ProductionPlanItemRecipeLine = {
            Id: 1000 + (i + 1),
            ProductionLineId: faker.number.int(),
            Sequence: i + 1,
            ProductId: faker.number.int(),
            ProductCode: faker.number.int().toString(),
            ProductName: faker.commerce.productName(),
            Tolerance: faker.number.int({ min: 0, max: 2 }),
            IsTraceable: faker.datatype.boolean(),
            TotalQuantity: faker.number.int({ min: 10, max: 200 }),
            QuantityUsedPercentage: 0,
            QuantityUsed: 0,
            OriginalQuantityUsed: 0,
            StillRequired: 0,
            Measure: this.weightUnits[weightUnitIndex],
            MeasureId: weightUnitIndex + 1,
            Completed: false,
            ImageLoading: false
          };

          method.RecipeLines.push(recipeLine);
        }

        methods.push(method);
      }
    }

    return of(methods).pipe(delay(this.delayTimer));;
  }

  getProductionPlanRecipeLines(productionPlanId: number): Observable<ProductionPlanItemRecipeLine[]> {

    const recipeLines: ProductionPlanItemRecipeLine[] = [];
    const weightUnitIndex = faker.number.int({ min: 0, max: 6 });

    for (let i = 0; i < 3; i++) {
      const recipeLine: ProductionPlanItemRecipeLine = {
        Id: 1000 + (i + 1),
        ProductionLineId: faker.number.int(),
        Sequence: i + 1,
        ProductId: faker.number.int(),
        ProductCode: faker.number.int().toString(),
        ProductName: faker.commerce.productName(),
        Tolerance: faker.number.int({ min: 0, max: 2 }),
        IsTraceable: faker.datatype.boolean(),
        TotalQuantity: faker.number.int({ min: 10, max: 200 }),
        QuantityUsed: 0,
        QuantityUsedPercentage: 0,
        OriginalQuantityUsed: 0,
        StillRequired: 0,
        Measure: this.weightUnits[weightUnitIndex],
        MeasureId: weightUnitIndex + 1,
        Completed: (productionPlanId === 3) ? true : false,
        ImageLoading: false
      };

      if (recipeLine.Completed) {
        recipeLine.QuantityUsed = recipeLine.TotalQuantity;
      }

      recipeLines.push(recipeLine);
    }

    return of(recipeLines).pipe(delay(this.delayTimer));;
  }

  getProductionPlanMethodLine(productionPlanId: number, productionPlanLineId: number, method: ProductionPlanProductRecipeMethod): Observable<GetProductionPlanProductRecipeMethodLineResponse> {

    const methodLine: GetProductionPlanProductRecipeMethodLineResponse = {
      Method: method,
      RecipeLines: []
    };

    const weightUnitIndex = faker.number.int({ min: 0, max: 6 });

    for (let i = 0; i < 3; i++) {
      const recipeLine: ProductionPlanItemRecipeLine = {
        Id: 1000 + (i + 1),
        ProductionLineId: faker.number.int(),
        Sequence: i + 1,
        ProductId: faker.number.int(),
        ProductCode: faker.number.int().toString(),
        ProductName: faker.commerce.productName(),
        Tolerance: faker.number.int({ min: 0, max: 2 }),
        IsTraceable: faker.datatype.boolean(),
        TotalQuantity: faker.number.int({ min: 10, max: 200 }),
        QuantityUsed: 0,
        QuantityUsedPercentage: 0,
        OriginalQuantityUsed: 0,
        StillRequired: 0,
        Measure: this.weightUnits[weightUnitIndex],
        MeasureId: weightUnitIndex + 1,
        Completed: false,
        ImageLoading: false
      };

      methodLine.RecipeLines.push(recipeLine);
    }

    return of(methodLine).pipe(delay(this.delayTimer));;
  }

  getProductionPlanRecipeLine(productionPlanId: number, productionPlanLineId: number, recipeLine: ProductionPlanItemRecipeLine): Observable<ProductionPlanItemRecipeLine> {
    return of(recipeLine).pipe(delay(this.delayTimer));;
  }

  getProductionPlanRecipeLineUsedLines(): Observable<ProductionUsedLine[]> {

    const lines: ProductionUsedLine[] = [];

    const returnNull = faker.datatype.boolean();

    if (returnNull) {
      for (let i = 0; i < 3!; i++) {
        const measure = faker.number.int({ min: 0, max: 6 });

        const line: ProductionUsedLine = {
          Id: faker.number.int(),
          StockLotId: faker.number.int(),
          SystemLotNumber: faker.number.int(),
          ExpiryDate: moment().add(i * 2, 'days').toDate(),
          StockLocationId: faker.number.int(),
          Quantity: faker.number.int({ min: 1, max: 30 }),
          OriginalQuantity: faker.number.int({ min: 1, max: 30 }),
          MeasureId: measure,
          Measure: this.weightUnits[measure],
          Completed: false
        };

        lines.push(line);
      }
    }

    return of(lines).pipe(delay(this.delayTimer));;
  }

  getProductionPlanMethodImage(): Observable<Blob> {
    const image = faker.image.dataUri({ height: 300, width: 300, type: 'svg-base64' });
    const imageBlob = this.base64ToBlob(image);

    return of(imageBlob).pipe(delay(this.delayTimer));
  }

  createProductionPlanRecipeLineUsedLine(): Observable<null> {
    return of(null).pipe(delay(this.delayTimer));
  }

  updateProductionPlanRecipeLineUsedLine(): Observable<null> {
    return of(null).pipe(delay(this.delayTimer));
  }

  deleteProductionPlanRecipeLineUsedLine(): Observable<null> {
    return of(null).pipe(delay(this.delayTimer));
  }

  getProductionPlanProductImage(): Observable<Blob> {

    //const returnImage = faker.datatype.boolean();
    const returnImage = false;

    if (!returnImage) {
      const blankBlob = new Blob([], { type: 'image/png' });
      return of(blankBlob).pipe(delay(this.delayTimer));;
    } else {
      const image = 'iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAVklEQVR42mJkIAIAAwMjI2P4/39DRN1DwMDw398fRmRkYoBLgYWBgkFEyoAAcHBxMPDw/7+/sPCwvLy8T/BhMCMBAIAAAHBC5IAOJSdrjEawAAAABJRU5ErkJggg==';
      const imageBlob = this.base64ToBlob(image);

      return of(imageBlob).pipe(delay(10000));
    }
  }

  // Function to convert Base64 to Blob
  base64ToBlob(base64String: string): Blob {
    // Convert Base64 to a binary string
    const binary = atob(base64String);

    // Create a typed array of 8-bit unsigned integers
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }

    // Create a Blob object from the byte array
    const blob = new Blob([bytes], { type: 'image/png' });
    return blob;
  }

  completeProductionPlanProductRecipeLine(): Observable<CompleteProductionPlanLineRecipeLineResponse> {
    return of(true).pipe(delay(this.delayTimer));
  }

  completeProductionPlanLineAsync(productionPlanId: number, productionPlanLineId: number, quantity: number, expiryDate?: Date): Observable<CompleteProductionPlanLineResponse> {
    return of(new CompleteProductionPlanLineResponse({
      StockLotId: faker.number.int(),
      SystemLotNumber: faker.datatype.boolean() ? faker.number.int() : undefined,
      ExpiryDate: expiryDate ? expiryDate : undefined
    })).pipe(delay(this.delayTimer));
  }

}
