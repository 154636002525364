<div class="w-full slide-right-animation"
     [ngClass]="{ 'pt-3': index }">

  <cybake-factory-card [title]="productionPlanProductCard.Title"
                       [subTitle]="productionPlanProductCard.SubTitle"
                       [pageSourceName]="productionPlanProductCard.PageSourceName"
                       [image]="productionPlanProductCard.Image"
                       [loadingImage]="productionPlanProductCard.LoadingImage"
                       [failedImage]="productionPlanProductCard.FailedImage"
                       [pImageClass]="productionPlanProductCard.ImageClass"
                       [identifier]="productionPlanProductCard.Identifier"
                       [tagValue]="productionPlanProductCard.TagValue"
                       [routerLink]="productionPlanProductCard.RouterLink"
                       [cardContentTemplate]="productionItemCardContentTemplate"
                       [overrideImageClick]="productionPlanProductCard.OverrideImageClick"
                       (longPressEvent)="this.viewProductInfoEvent.emit()"
                       (imageFailedClickEvent)="this.getProductionPlanProductImageEvent.emit()"
                       (imageClickEvent)="this.viewProductInfoEvent.emit()"
                       (clickEvent)="this.viewProductionPlanProductEvent.emit()">
  </cybake-factory-card>

  <ng-template #productionItemCardContentTemplate>
    <div class="w-full inline-flex text-lg">
      <div class="w-full">
        <div class="vertically-center-left-align">
          <div class="inline-flex w-full">

            <span class="pr-1 font-semibold">
              {{'Production.ProductionPlanCard.PlannedLabel' | translate}}:
            </span>

            {{ product.TotalNumberOfLines }}

            <span class="pl-1">
              {{ product.HasSubLines ? (product.TotalNumberOfLines === 1 ? ('Production.ProductionPlanCard.MixLabel' | translate) : ('Production.ProductionPlanCard.MixesLabel' | translate)) : (product.TotalNumberOfLines === 1 ? ('Production.ProductionPlanCard.ItemLabel' | translate) : ('Production.ProductionPlanCard.ItemsLabel' | translate))}}
            </span>

          </div>
        </div>
      </div>

      <div class="w-full pr-3">
        <div class="vertically-center-right-align">
          <strong class="pr-1">
            {{ 'Production.ProductionPlanCard.ProducedLabel' | translate }}:
          </strong>
          <span>{{ product.NumberOfLinesCompleted }}</span>
          <span class="pl-1">
            {{ product.HasSubLines ? (product.NumberOfLinesCompleted === 1 ? ('Production.ProductionPlanCard.MixLabel' | translate) : ('Production.ProductionPlanCard.MixesLabel' | translate)) : (product.NumberOfLinesCompleted === 1 ? ('Production.ProductionPlanCard.ItemLabel' | translate) : ('Production.ProductionPlanCard.ItemsLabel' | translate)) }}
          </span>
        </div>
      </div>

      <div class="">
        <div class="float-end vertically-center-right-align">
          <cybake-factory-progress-pie [total]="product.TotalNumberOfLines"
                                       [completed]="product.NumberOfLinesCompleted"
                                       [showCheckmark]="product.Completed">
          </cybake-factory-progress-pie>
        </div>
      </div>
    </div>
  </ng-template>
</div>
