import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  InputSwitch,
  InputSwitchChangeEvent,
  InputSwitchModule,
} from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'cybake-factory-switch',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputSwitchModule,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss',
})
export class CyBakeSwitchComponent {
  // Children
  @ViewChild(InputSwitch) inputSwitchRef!: InputSwitch;

  // Inputs/Outputs
  @Input() translationKey!: string;
  @Input() translationKeyParams?: object;
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() value!: boolean;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() class?: string;

  @Input() transparent: boolean = true;
  @Input() rounded: boolean = true;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = 'top';

  // Outputs
  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleSwitch(event: InputSwitchChangeEvent) {
    this.changeEvent.emit(event.checked);
  }

  toggleSwitchValue() {
    if (!this.disabled) {
      this.value = !this.value;
      this.valueChange.emit(this.value);
      this.changeEvent.emit(this.value);
    }
    
  }
}
