export class PersistProductionUsedLineRequest {
  Id?: number | null;
  ProductionLineId!: number;
  RecipeLineId!: number;
  ProductId!: number;
  Quantity!: number;
  OriginalQuantity!: number;
  Measure!: string;
  MeasureId!: number;
  ProductionLocationId!: number;
  StockLocationId?: number;
  StockLotId?: number | null;
  SystemLotNumber?: number | null;
  ExpiryDate?: Date | null;

  constructor(values: PersistProductionUsedLineRequest) {
    Object.assign(this, values);
  }
}
