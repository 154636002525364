@if (!loading) {

  @if (filter && options.length && options.length > 10) {
<div class="w-full pb-2">
  <cybake-factory-input-text [(value)]="listboxFilterTextInput.Value"
                             [pageSourceName]="listboxFilterTextInput.PageSourceName"
                             [identifier]="listboxFilterTextInput.Identifier"
                             [translationKey]="listboxFilterTextInput.TranslationKey"
                             [iconKey]="listboxFilterTextInput.IconKey"
                             [placeholder]="listboxFilterTextInput.Placeholder"
                             [disabled]="!options.length"
                             (valueChange)="filterListbox(listboxFilterTextInput.Value!)">
  </cybake-factory-input-text>
</div>
  }

<div class="overflow-y-auto">
  <p-listbox #cybakeListbox
             [ngClass]="{ 'cybake-listbox-empty': !options.length }"
             [options]="options"
             [(ngModel)]="value"
             [checkbox]="checkbox"
             filterBy="{{ filterBy }}"
             [multiple]="multiple"
             [group]="group"
             optionValue="{{ optionValue }}"
             optionGroupLabel="{{ optionGroupLabel }}"
             optionGroupChildren="{{ optionGroupChildren }}"
             optionLabel="{{ optionLabel }}"
             (onChange)="valueChange.emit(value); changeEvent.emit($event)"
             [listStyle]="{ 'max-height': '100%' }"
             [style]="{ 'max-height': (height ? height : 210) + 36 + 'px' }">

    <ng-template let-line pTemplate="item">
      <div class="inline-flex w-full">
        <!--<p-radioButton name="{{line[optionLabel]}}" [(ngModel)]="line.optionValue" inputId="line[optionLabel]" *ngIf="radioButtons"></p-radioButton>-->

        <div class="w-full">
          {{ line[optionLabel!] }}
        </div>

        @if (optionLabel2) {
        <div class="w-full">
          {{ line[optionLabel2] }}
        </div>
        }
      </div>
    </ng-template>

    <ng-template pTemplate="empty">
      <div class="w-full">

        @if (emptyIcon) {
        <div class="w-full text-center p-5">
          <i class="fa-icon {{emptyIcon}} text-8xl"></i>
        </div>
        }

        <div class="w-full text-center text-3xl p-3">
          {{ emptyTranslationKey ? (emptyTranslationKey | translate: emptyTranslationParams) : ('CyBakeTemplates.Listbox.EmptyMessage' | translate) }}
        </div>

      </div>
    </ng-template>

  </p-listbox>
</div>
}

@if (loading) {
<div [style.height]="(height ? height : 210) + 36 + 'px'">
  <div class="h-100 component-loading-background vertically-center">
    <div class="h-100">
      <i class="fa-regular fa-spinner-third fa-spin text-5xl"></i>
    </div>
  </div>

  <!--<div class="h-100 component-loading-background vertically-center br-default">
    <div class="">
      <span class="p-datatable-loading-icon pi pi-spin pi-spinner fs-2"></span>
    </div>
  </div>-->
</div>
}
