import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotesComponent } from '../../_shared/dialogs/notes/notes.component';

@Component({
  selector: 'cybake-factory-production-plan-note',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NotesComponent
  ],
  templateUrl: './production-plan-note.component.html',
  styleUrl: './production-plan-note.component.scss'
})
export class ProductionPlanNoteComponent {

  // Inputs/Outputs
  @Input() note!: string;
  @Input() planName!: string;
  @Input() shiftName!: string;
  @Input() startTime!: string;

  @Output() closeNoteEvent: EventEmitter<null> = new EventEmitter();

  // Variables
  pageSourceName: string = 'productionPlanNote';

}
