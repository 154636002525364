import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CybakeInputEmailComponent } from '../_shared/components/input-email/input-email.component';
import { CybakeInputPasswordComponent } from '../_shared/components/input-password/input-password.component';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../_shared/services/authentication.service';
import { AuthenticationServiceMock } from '../_shared/services/mock-services/authentication.service.mock';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Message } from '../_shared/models/cybake/message/message.model';
import { MessageSeverityEnum } from '../_shared/models/cybake/message/message-severity.enum';
import { CyBakeMessageComponent } from '../_shared/components/message/message.component';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { CommonModule } from '@angular/common';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha-2';
import { VibrationService } from '../_shared/services/common/vibration.service';
import { DashboardService } from '../_shared/services/dashboard.service';
import { DashboardServiceMock } from '../_shared/services/mock-services/dashboard.service.mock';
import { LocalStorageService } from '../_shared/services/common/local-storage.service';
import { CyBakeInputText } from '../_shared/models/cybake/input/input-text.model';

@Component({
  selector: 'cybake-factory-login',
  standalone: true,
  imports: [
    CybakeInputEmailComponent,
    CyBakeButtonComponent,
    CybakeInputPasswordComponent,
    CyBakeMessageComponent,
    TranslateModule,
    CommonModule,
    RecaptchaV3Module
  ],
  providers: [MessageService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {

  // Services
  translate: TranslateService = inject(TranslateService);
  messageService: MessageService = inject(MessageService);
  router: Router = inject(Router);
  recaptchaV3Service: ReCaptchaV3Service = inject(ReCaptchaV3Service);
  vibrationService: VibrationService = inject(VibrationService);
  localStorageService: LocalStorageService = inject(LocalStorageService);
  authenticationService: AuthenticationService | AuthenticationServiceMock = environment.mock ? inject(AuthenticationServiceMock) : inject(AuthenticationService);
  dashboardService: DashboardService | DashboardServiceMock = environment.mock ? inject(DashboardServiceMock) : inject(DashboardService);

  // Variables
  pageSourceName = 'login';
  failedGetUser: boolean = false;
  loggingIn: boolean = false;
  versionNumber!: string;
  existingUsers: UserDetails[] = [];
  failedLoggingIn: boolean = false;

  // Existing Users Button
  existingUserButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'goToPinLogin',
    Class: ButtonClassEnum.warning,
    IconKey: ['fa-duotone', 'fa-users'],
  });

  // Failed Login Message
  failedLoginMessage: Message = new Message({
    PageSourceName: this.pageSourceName,
    Identifier: 'failedLoginMessage',
    Severity: MessageSeverityEnum.error,
    TranslationKey: 'Login.FailedLoginAttempt',
  });

  emailInput: CyBakeInputText = new CyBakeInputText({
    Value: null,
    PageSourceName: this.pageSourceName,
    Identifier: 'loginEmail',
    TranslationKey: 'Login.UsernamePlaceholder',
    Placeholder: true
  });

  passwordInput: CyBakeInputText = new CyBakeInputText({
    Value: null,
    PageSourceName: this.pageSourceName,
    Identifier: 'loginPassword',
    TranslationKey: 'Login.PasswordPlaceholder',    
    Placeholder: true
  });

  ngOnInit() {
    // Remove stored data on [page load (to handle when logout function is not called)]
    this.localStorageService.removeItem('user');
    this.localStorageService.removeItem('sites');

    // Show the re-capatcha logo in the DOM (login pages only)
    document.documentElement.style.setProperty('--recaptcha', 'initial');

    this.versionNumber = environment.version;

    // RM - 29375 - Removed so pin login doesn't show until it's implemented
    //if (this.localStorageService.getItem('users')) {
    //  this.existingUsers = this.localStorageService.getItem('users')
    //};
  }

  login() {
    if (!this.emailInput.Value && !this.passwordInput.Value) {
      return;
    };

    this.loggingIn = true;
    this.failedGetUser = false;
    this.failedLoggingIn = false;

    this.recaptchaV3Service.execute('login').subscribe(() => {
      this.authenticationService.login(this.emailInput.Value!, this.passwordInput.Value!).subscribe({
          next: () => {
            this.dashboardService.getUserDetails().subscribe({
              next: () => {
                this.router.navigateByUrl('/dashboard');
                this.loggingIn = false;
              },
              error: () => {
                this.failedLoginMessage.TranslationKey = 'Login.FailedGetUser';
                this.failedGetUser = true;
                this.loggingIn = false;
                this.vibrationService.vibrate();
              },
            });
          },
          error: () => {
            this.failedLoggingIn = true;
            this.failedLoginMessage.TranslationKey = 'Login.FailedLoginAttempt';
            this.loggingIn = false;
            this.vibrationService.vibrate();

          },
        });
      });
  }

  goToPinLogin() {
    this.router.navigateByUrl('/login-pin');
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--recaptcha', 'hidden');
  }
}
