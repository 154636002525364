import { Injectable, inject } from '@angular/core';
import { Observable, catchError, retry } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthData } from '../../models/auth/auth-data.model';
import { AuthenticationService } from '../authentication.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})

export class HTTPInterceptor {

  // Services
  authService: AuthenticationService = inject(AuthenticationService);
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Functions
  // eslint-disable-next-line
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log('!INTERCEPT!', request);

    const auth: AuthData = this.localStorageService.getItem('authData');

    if (request.url.indexOf('verification') === -1) {

      if (auth != null) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${auth.access_token}`,
            TrustedCallerGuid: 'fedbddf9-0894-4b11-8859-09e620b2b4dc'
          }
        });

      } else {
        this.logout();
      }

    }

    return next.handle(request).pipe(
      retry(0),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            this.logout();
          }
        }
        throw err;
      })
    );
  }

  private logout() {
    this.authService.logout();
  }

}
