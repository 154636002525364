import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
  standalone: true
})
export class SentenceCasePipe implements PipeTransform {
  transform(passedInString: string): string {
    if (!passedInString) {
      return passedInString;
    }

    return (
      passedInString[0].toUpperCase() + passedInString.substring(1).toLowerCase()
    );
  }
}
