import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { ImageComponent } from '../../components/image/image.component';
import { CyBakeTagComponent } from '../../components/tag/tag.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'cybake-factory-product-info',
  standalone: true,
  imports: [
    CyBakeButtonComponent,
    ImageComponent,
    CyBakeTagComponent,
    TranslateModule
  ],
  templateUrl: './product-info.component.html',
  styleUrl: './product-info.component.scss'
})
export class ProductInfoComponent {

  // Inputs/Outputs
  @Input() name!: string;
  @Input() image?: string | SafeResourceUrl;
  @Input() code!: string;
  @Input() isTraceable?: boolean = false;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  // Variables
  pageSourceName: string = 'productInfo'
}
