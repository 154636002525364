import { CyBakeTemplate } from '../cybake-template.model';
import { ButtonClassEnum } from './button-class.enum';
import { ButtonFloatEnum } from './button-float.enum';
import { ButtonTypeEnum } from './button-type.enum';

export class CyBakeButton extends CyBakeTemplate {
  IconKey?: string[];
  IconClass?: string;
  Prefix?: string;
  Type: ButtonTypeEnum = ButtonTypeEnum.default;
  Class: ButtonClassEnum = ButtonClassEnum.default;
  Float?: ButtonFloatEnum;
  Rounded?: boolean = false;
  Pulse?: boolean = false;
  OverrideCenterAlign?: boolean = false;
  Raised?: boolean = false;

  constructor(values: CyBakeButton) {
    super(values);
    Object.assign(this, values);
  }
}
