import { SafeResourceUrl } from '@angular/platform-browser';
import { CyBakeTemplate } from '../cybake-template.model';
import { CardStyleEnum } from './card-style.enum';

export class CyBakeCard extends CyBakeTemplate {
  Title!: string;
  TitleParams?: object;
  TitleFromData?: boolean = false;

  SubTitle?: string;
  SubTitleFromData?: boolean = false;

  SubSubTitle?: string;
  SubSubTitleFromData?: boolean = false;

  ToggleableContent?: boolean = false
  OpenToggleableContent?: boolean = false
  HideCardHeaderhideCardHeader?: boolean = false
  FullHeight?: boolean = false

  LoadingHeight?: number = 50;
  TagValue?: string[];
  TagSeverity?: string[];

  RouterLink?: string | boolean;

  Image?: string | SafeResourceUrl;
  LoadingImage?: boolean;
  FailedImage?: boolean;
  ImageClass?: string;
  Selected?: boolean;
  OverrideImageClick?: boolean;
  OverrideClickStyling?: boolean;

  IconKey?: string[];
  CustomClass?: string;
  CardStyle?: CardStyleEnum = CardStyleEnum.default;

  constructor(values: CyBakeCard) {
    super(values);
    Object.assign(this, values);
  }
}
