<div class="vertically-center inline-flex">
  @if (showSteppers) {
  <div [ngClass]="{ 'mt-4': label }">
    <cybake-factory-button #inputNumberDecreaseButton
                           [iconKey]="['fa-regular', 'fa-chevron-down']"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'decreaseInputNumber'"
                           [class]="'cybake'"
                           [bigIcon]="true"
                           [rounded]="true"
                           [disabled]="(min !== undefined || min !== null ? (value === min) : false)"
                           (mousedown)="decreaseNumber()"
                           (mouseup)="decreaseTimeoutClear()"
                           (mouseleave)="decreaseTimeoutClear()">
    </cybake-factory-button>
  </div>
  }

  <div class="" [ngClass]="{ 'p-float-label mt-4': label }">
    <p-inputNumber inputId="{{ pageSourceName }}_{{ identifier }}_inputNumber_input"
                   class="w-full"
                   styleClass="w-full cybake-input-number"
                   placeholder="{{ placeholder ? (placeholder | translate) : '' }}"
                   buttonLayout="horizontal"
                   suffix="{{decimal ? undefined : suffix}}"
                   [(ngModel)]="value"
                   (onInput)="this.valueChange.emit(value)"
                   (onFocus)="onInputFocus($event)"
                   mode="decimal"
                   [showButtons]="false"
                   [readonly]="readonly"
                   [disabled]="disabled !== undefined ? disabled : false"
                   [step]="step ? step : 1"
                   [min]="min"
                   [max]="max"
                   [minFractionDigits]="(decimal && !minFractionDigits) ? 0 : minFractionDigits"
                   [maxFractionDigits]="(decimal && !maxFractionDigits) ? 2 : maxFractionDigits" />

    @if (label) {
    <label id="{{ pageSourceName }}_{{ identifier }}_inputNumber_label"
           for="{{ pageSourceName }}_{{ identifier }}_inputNumber_input"
           [translate]="label"
           style="width: 100%; left: 0; text-align: center;">
    </label>
    }
  </div>

  @if (showSteppers) {
  <div [ngClass]="{ 'mt-4': label }">
    <cybake-factory-button #inputNumberIncreaseButton
                           [iconKey]="['fa-regular', 'fa-chevron-up']"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'increaseInputNumber'"
                           [class]="'cybake'"
                           [bigIcon]="true"
                           [rounded]="true"
                           [disabled]="(max !== undefined || max !== null ? (value === max) : false)"
                           (mousedown)="increaseNumber()"
                           (mouseup)="increaseTimeoutClear()"
                           (mouseleave)="increaseTimeoutClear()">
    </cybake-factory-button>
  </div>
  }
</div>
