import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CreateTraceabilityLabelZplResponse } from '../../models/zpl/create-zpl-response.model';
//import * as BrowserPrint from "BrowserPrint-3.1.250"
//import * as BrowserPrintZebra from "BrowserPrint-Zebra-1.1.250"

declare let BrowserPrint: any;

@Injectable({
  providedIn: 'root',
})

export class PrintingService {

  // Services
  http: HttpClient = inject(HttpClient);

  // Variables
  selected_device: any;
  devices: any[] = [];

  setup() {
    //Get the default device from the application as a first step. Discovery takes longer to complete.
    BrowserPrint.getDefaultDevice("printer", (device: any) => {

      console.log(device);
      //Add device to list of devices and to html select element
      this.selected_device = device;
      this.devices.push(device);
      //var html_select = document.getElementById("selected_device") as any;
      //var option = document.createElement("option");
      //option.text = device.name;
      //html_select!.add(option);

      //Discover any other devices available to the application
      BrowserPrint.getLocalDevices((device_list: any) => {
        console.log(device_list);
        for (let i = 0; i < device_list.length; i++) {
          //Add device to list of devices and to html select element
          const device = device_list[i];
          if (!this.selected_device || device.uid != this.selected_device.uid) {
            this.devices.push(device);
            //var option = document.createElement("option");
            //option.text = device.name;
            //option.value = device.uid;
            //html_select!.add(option);
          }
        }

      }, function () { console.log("Error getting local devices") }, "printer");

    }, function (error: any) {
      console.log(error);
    })
  }

  getConfig() {
    BrowserPrint.getApplicationConfiguration((config: any) => {
      console.log(JSON.stringify(config))
    },
      () => {
        console.log(JSON.stringify(new BrowserPrint.ApplicationConfiguration()));
      })
  }

  getZplData(stockLotId: number): Observable<string> {
    const url = `api/zpl/traceabilitylabel?stockLotId=${stockLotId}`;

    return this.http.get<CreateTraceabilityLabelZplResponse>(environment.api + url).pipe(
      map((getStockLotsResponse: CreateTraceabilityLabelZplResponse) => {
        return getStockLotsResponse.ZplData;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  writeToSelectedPrinter(dataToWrite: any) {
    this.selected_device.send(dataToWrite, undefined, this.errorCallback(""));
  }

  readCallback(readData: any) {
    if (readData === undefined || readData === null || readData === "") {
      console.log("No Response from Device");
    }
    else {
      console.log(readData);
    }
  }

  errorCallback(errorMessage: any) {
    console.log("Error: " + errorMessage);
  }

  readFromSelectedPrinter() {
    this.selected_device.read(this.readCallback(""), this.errorCallback(""));
  }

  getDeviceCallback(deviceList: any) {
    console.log("Devices: \n" + JSON.stringify(deviceList, null, 4))
  }

  sendImage(imageUrl: any) {
    let url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
    url = url + "/" + imageUrl;
    this.selected_device.convertAndSendFile(url, undefined, this.errorCallback(""))
  }
  sendFile(fileUrl: any) {
    let url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
    url = url + "/" + fileUrl;
    this.selected_device.sendFile(url, undefined, this.errorCallback(""))
  }

  onDeviceSelected(selected: any) {
    for (let i = 0; i < this.devices.length; ++i) {
      if (selected.value == this.devices[i].uid) {
        this.selected_device = this.devices[i];
        return;
      }
    }
  }

  printDeliveryReceipt() {
    const newLine = '\r\n';
    const dashLine = '------------------------------------------------' + newLine;
    const footerSpace = newLine + newLine + newLine + newLine;
    const customerHeader = 'CUSTOMER:' + newLine;
    const deliveryHeader = 'DELIVERY:' + newLine;
    const itemHeader = 'ITEMS:' + newLine;

    let outputText = dashLine;
    outputText = outputText + 'DemoWholesaleBakery - Delivery Receipt          ' + newLine;
    outputText = outputText + dashLine;
    outputText = outputText + deliveryHeader;
    outputText = outputText + 'Delivery Date:                        11/01/2024' + newLine
    outputText = outputText + 'Order Wave:                                    1' + newLine
    outputText = outputText + 'Round:                                York Outer' + newLine
    outputText = outputText + 'Drop Number:                                   1' + newLine
    outputText = outputText + dashLine;
    outputText = outputText + customerHeader;
    outputText = outputText + 'My cafe' + newLine;
    outputText = outputText + '12 Kings Street' + newLine;
    outputText = outputText + 'York' + newLine;
    outputText = outputText + 'YO17PX' + newLine;
    outputText = outputText + dashLine;
    outputText = outputText + itemHeader;
    outputText = outputText + 'Baguette (x4) ..........................    4.14' + newLine;
    outputText = outputText + 'Buns (x42) .............................   22.14' + newLine;
    outputText = outputText + 'Wholemeal Buns (x42) ...................   22.14' + newLine;
    outputText = outputText + '                                  TOTAL    48.42' + newLine;
    outputText = outputText + dashLine;
    outputText = outputText + footerSpace;


    this.writeToSelectedPrinter(outputText);
  }
}
