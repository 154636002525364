@if (!loading) {
<div class="w-full h-full {{ class }} p-input-icon-left"
     [ngClass]="{
      'p-input-icon-right': showClear || maximumCharacters,
      'p-float-label': translationKey
    }"
     pTooltip="{{ tooltip | translate }}"
     tooltipPosition="{{ tooltipPosition }}"
     [tooltipDisabled]="!tooltip">

  <!--Custom start Icon-->
  @if (iconKey) {
  <button (click)="this.iconClickEvent.emit()"
          class="vertically-center pl-2 pr-2"
          style="position: absolute"
          tabindex="-1">

    <i id="{{ pageSourceName }}{{identifier ? '_' + identifier : ''}}_emailInput_icon"
       class="fa-icon input-left-icon-with-right cursor-pointer {{iconClass}} fa-light fa-user"
       [ngClass]="{ 'model-required': required && !value }"
       pTooltip="{{iconTooltipTranslation | translate: iconTooltipTranslationParams}}"
       tooltipPosition="{{iconTooltipPosition ? iconTooltipPosition : 'right'}}"
       [escape]="false"
       [tooltipDisabled]="!iconTooltipTranslation"
       style="max-width: fit-content">
    </i>

  </button>
  }

  <!--Input-->
  <input #cybakeInput
         id="{{ pageSourceName + '_' + identifier }}_emailInput_input"
         pInputText
         class="h-full w-full cybake-input {{ inputClass }}"
         type="text"
         [(ngModel)]="value"
         (input)="this.valueChange.emit(value)"
         (keydown.enter)="this.enterEvent.emit()"
         autocomplete=""
         maxlength="{{maximumCharacters}}"
         [ngClass]="{ 'model-required': required && !value }"
         placeholder="{{placeholder ? (translationKey ? (translationKey | translate: translationParams) : null) : null}}"
         [disabled]="disabled"
         tabindex="{{tabIndex}}"
         onmousewheel="return false;"
         onwheel="return false;" />

  <!--Clear Icon-->
  @if (value && showClear && !maximumCharacters) {
  <button tabindex="-1"
          (click)="value = null; this.clearClickEvent.emit()"
          class="pl-2 pr-2 h-full"
          style="position: absolute; right: 0">
    <i id="{{ pageSourceName }}_{{identifier}}_search_input_clear"
       class="cursor-pointer input-right-icon input-clear-icon input-right-icon-with-left fa-icon fa-light fa-xmark">
    </i>
  </button>
  }

  @if (translationKey && !placeholder) {
  <label id="{{pageSourceName}}_{{identifier}}_input_label"
         for="{{pageSourceName}}_{{identifier}}_input"
         [ngClass]="{ 'model-required': required && !value }"
         [translate]="translationKey">
  </label>
  }
</div>
}

@if (loading) {
<p-skeleton id="{{pageSourceName}}_{{identifier}}_input_skeleton"
            styleClass="h-full w-full"
            width="100%"
            height="100%">
</p-skeleton>
}
