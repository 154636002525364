import { Component, OnInit, inject } from '@angular/core';
import { LoginProfileComponent } from '../../_shared/components/login-profile/login-profile.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_shared/services/authentication.service';
import { AuthenticationServiceMock } from '../../_shared/services/mock-services/authentication.service.mock';
import { environment } from '../../../environments/environment';
import { UserDetails } from '../../_shared/models/user/user-details.model';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import moment from 'moment';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { SoundService } from '../../_shared/services/common/sound.service';
import { VibrationService } from '../../_shared/services/common/vibration.service';
import { LocalStorageService } from '../../_shared/services/common/local-storage.service';

@Component({
  selector: 'cybake-factory-login-pin',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    LoginProfileComponent,
    CyBakeButtonComponent,
    TranslateModule,
  ],
  templateUrl: './login-pin.component.html',
  styleUrl: './login-pin.component.scss',
})
export class LoginPinComponent implements OnInit {

  // Services
  translate: TranslateService = inject(TranslateService);
  messageService: MessageService = inject(MessageService);
  router: Router = inject(Router);
  soundService: SoundService = inject(SoundService);
  vibrationService: VibrationService = inject(VibrationService);
  localStorageService: LocalStorageService = inject(LocalStorageService);
  authenticationService: AuthenticationService | AuthenticationServiceMock = environment.mock ? inject(AuthenticationServiceMock) : inject(AuthenticationService);

  // Variables
  pageSourceName = 'loginPin';
  failedLoginCount: number = 0;
  loggingIn: boolean = false;
  users?: UserDetails[] = [];
  user?: UserDetails;
  pin: string = '';

  // Back to users button
  backToExistingUsersButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'location',
    IconKey: ['fa-duotone', 'fa-circle-arrow-left'],
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.transparent,
    IconClass: 'text-4xl default-colour',
    Loading: false,
  });

  ngOnInit() {
    this.users = this.localStorageService.getItem('users');
  }

  selectUser(user: UserDetails) {
    this.user = user;
  }

  navigateToLogin() {
    this.router.navigateByUrl('login');
  }

  pinButton(selectedNumber: number) {
    this.pin += selectedNumber.toString();

    if (this.pin.length === 4) {
      if (this.user) {
        this.login(this.user);
      }
    }
  }

  login(user: UserDetails) {
    this.loggingIn = true;

    this.authenticationService.pinLogin(user, Number(this.pin)).subscribe({
      next: () => {
        user.LastLogin = moment().toDate();

        this.router.navigateByUrl('/dashboard');
        this.loggingIn = false;
        this.failedLoginCount = 0;
      },
      error: () => {
        this.failedLoginCount++;
        this.loggingIn = false;
        this.pin = '';
        this.vibrationService.vibrate();
        this.soundService.playAlert();
      },
    });
  }
}
