<div class="w-full slide-right-animation"
     [ngClass]="{ 'pt-3': index, 'pb-3': loadingRecipeLines }">

  <cybake-factory-card [title]="method.Title"
                       [pageSourceName]="pageSourceName"
                       [identifier]="'methodStep' + method.Id"
                       [routerLink]="selected ? undefined : true"
                       [cardTitleContentTemplate]="selected ? null : methodTitleContentTemplate"
                       [cardContentTemplate]="selected ? methodContentTemplate : null"
                       (clickEvent)="(selected ? null : this.viewRecipeMethodEvent.emit())">
  </cybake-factory-card>

  <ng-template #methodTitleContentTemplate>
    <div class="w-full vertically-center-right-align pr-2">
      <div class="float-end">
        <cybake-factory-progress-pie [total]="method.TotalLines"
                                     [completed]="method.CompletedLines"
                                     [showCheckmark]="method.TotalLines === method.CompletedLines">
        </cybake-factory-progress-pie>
      </div>

    </div>
  </ng-template>

  <ng-template #methodContentTemplate>
    <div class="inline-flex w-full">
      <div class="w-full text-lg">
        <div [innerHtml]="method.Method"></div>
      </div>

      <div class="method-image-div">
        <cybake-factory-image [pageSourceName]="pageSourceName"
                              [identifier]="'methodImage' + method.Id"
                              [verticalAlign]="'vertically-center-right-align'"
                              [width]="150"
                              [height]="150"
                              [loading]="method.LoadingImage"
                              [overrideClick]="true"
                              [value]="method.Image">
        </cybake-factory-image>
      </div>
    </div>
  </ng-template>
</div>
