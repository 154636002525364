import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { ProductionUsedLine } from '../../_shared/models/production/plans/production-used-line.model';
import { ProductionPlanItemRecipeLine } from '../../_shared/models/production/plans/production-plan-item-recipe-line.model';

@Component({
  selector: 'cybake-factory-production-plan-product-measures-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeCardComponent
  ],
  templateUrl: './production-plan-product-measures.component.html',
  styleUrl: './production-plan-product-measures.component.scss'
})
export class ProductionPlanProductMeasuresCardComponent {

  // Inputs/Outputs
  @Input() measures!: ProductionUsedLine[];
  @Input() parentRecipeLine!: ProductionPlanItemRecipeLine;
  @Input() selectedUsedLineId?: number | null;

  @Output() editRecipeLineUsedEvent: EventEmitter<ProductionUsedLine> = new EventEmitter();

  // Variables
  pageSourceName: string = 'productionPlanProductMixCard';

}
