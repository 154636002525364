import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Toast, ToastModule } from 'primeng/toast';
import { Subscription, forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DataService } from '../../services/common/data.service';
import { ToastMessage } from '../../models/cybake/toast/toast-message.model';
import { CyBakeButtonComponent } from '../button/button.component';

@Component({
  selector: 'cybake-factory-toast',
  standalone: true,
  imports: [
    ToastModule,
    CommonModule,
    CyBakeButtonComponent
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class CyBakeToastComponent implements OnInit {

  // Children
  @ViewChild(Toast) toastRef!: Toast;

  // Services
  dataService: DataService = inject(DataService);
  messageService: MessageService = inject(MessageService);
  translateService: TranslateService = inject(TranslateService);

  // Variables
  pageSourceName: string = 'toast';
  toastMessage!: ToastMessage;

  // Subscriptions
  subscriptionOpenToast!: Subscription;

  ngOnInit() {
    this.subscriptionOpenToast = this.dataService.openToastState.subscribe((toast: ToastMessage) => {
      this.toastMessage = toast;

      forkJoin([
        this.translateService.get(toast.Detail!),
      ]).subscribe((translations: string[]) => {
        this.messageService.add({
          key: 'cybakeToast',
          severity: toast.Severity.toString(),
          detail: translations[0],
          sticky: (toast.Sticky || toast.RetryOutputMethod) ? true : false,
          closable: false,
          life: toast.Life ? toast.Life : 3000
        });

      });

    });
  }

  retryMethod() {
    this.dataService.toastOutputFunction(this.toastMessage.RetryOutputMethod!);
  }

  closeToast(key: string) {
    this.messageService.clear(key);
  }

}
