import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { BarcodeScanComponent } from '../_shared/components/barcode-scan/barcode-scan.component';
import { DialogComponent } from '../_shared/components/dialog/dialog.component';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { ButtonFloatEnum } from '../_shared/models/cybake/button/button-float.enum';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { NavbarTitle } from '../_shared/models/navbar/navbar-title.model';
import { environment } from '../../environments/environment';
import { ProductionService } from '../_shared/services/production.service';
import { ProductionServiceMock } from '../_shared/services/mock-services/production.service.mock';
import { ProductionLocation } from '../_shared/models/production/locations/production-location.model';
import { ProductionPlan } from '../_shared/models/production/plans/production-plan.model';
import { ProductionShift } from '../_shared/models/production/shifts/production-shift.model';
import moment from 'moment';
import { CyBakeCardComponent } from '../_shared/components/card/card.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from '../_shared/pipes/filter-by.pipe';
import { ProductionPlanProduct } from '../_shared/models/production/plans/production-plan-product.model';
import { ProductionPlanItemRecipeLine } from '../_shared/models/production/plans/production-plan-item-recipe-line.model';
import { ProductionPlanProductMix } from '../_shared/models/production/plans/production-plan-recipe-line-mix.model';
import { StockLotSelectionComponent } from '../_shared/dialogs/stock-lot-selection/stock-lot-selection.component';
import { StockLot } from '../_shared/models/production/stocklots/stock-lot.model';
import {
  LayoutModule,
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { CompleteProductionItemComponent } from './complete-production-item/complete-production-item.component';
import { FilterByBooleanPipe } from '../_shared/pipes/filter-by-boolean.pipe';
import { DialogOptionsModel } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { Subscription } from 'rxjs';
import { OutputFunction } from '../_shared/models/common/output-function.model';
import { ProductionPlanProductRecipeMethod } from '../_shared/models/production/plans/production-plan-recipe-methods.model';
import { CyBakeToggleComponent } from '../_shared/components/toggle/toggle.component';
import { ProductInfoComponent } from '../_shared/dialogs/product-info/product-info.component';
import { ProductionUsedLine } from '../_shared/models/production/plans/production-used-line.model';
import { PersistProductionUsedLineRequest } from '../_shared/models/production/plans/persist-production-used-line-request.model';
import { ToastSeverity } from '../_shared/models/cybake/toast/toast-severity.enum';
import { GetProductionPlanProductRecipeMethodLineResponse } from '../_shared/models/production/plans/get-production-plan-product-recipe-method-line-response.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CompleteProductionPlanLineResponse } from '../_shared/models/production/plans/complete-production-plan-line-response.model';
import { DataService } from '../_shared/services/common/data.service';
import { VibrationService } from '../_shared/services/common/vibration.service';
import { PrintingService } from '../_shared/services/common/printing.service';
import { ToastMessage } from '../_shared/models/cybake/toast/toast-message.model';
import { SortByPipe } from '../_shared/pipes/sort-by.pipe';
import { ProductionPlanCardComponent } from './production-plan/production-plan.component';
import { ProductionPlanProductCardComponent } from './production-plan-product/production-plan-product.component';
import { ProductionPlanProductMixCardComponent } from './production-plan-product-mix/production-plan-product-mix.component';
import { ProductionPlanProductMethodCardComponent } from './production-plan-product-method/production-plan-product-method.component';
import { ProductionPlanProductMeasuresCardComponent } from './production-plan-product-measures/production-plan-product-measures.component';
import { ProductionPlanRecipeLineCardComponent } from './production-plan-recipe-line/production-plan-recipe-line.component';
import { ProductionPlanNoteComponent } from './production-plan-note/production-plan-note.component';
import { NavigationCardComponent } from '../_shared/components/navigation-card/navigation-card.component';
import { BreadcrumbItem } from '../_shared/models/cybake/navagation-card/breadcrumb-item.model';
import { LocalStorageService } from '../_shared/services/common/local-storage.service';
import { DialogSelectModel } from '../_shared/models/cybake/dialog-select/dialog-select.model';
import { DialogSelectComponent } from '../_shared/components/dialog-select/dialog-select.component';
import { BarcodeData } from '../_shared/models/barcode/barcode-data.model';
import { BarcodeService } from '../_shared/services/barcode.service';
import { BarcodeStockLotResponse } from '../_shared/models/barcode/barcode-stock-lot-response.model';

@Component({
  selector: 'cybake-factory-production',
  standalone: true,
  imports: [
    DialogComponent,
    BarcodeScanComponent,
    CyBakeButtonComponent,
    CyBakeCardComponent,
    TranslateModule,
    CommonModule,
    FilterPipe,
    FilterByBooleanPipe,
    StockLotSelectionComponent,
    LayoutModule,
    CompleteProductionItemComponent,
    CyBakeToggleComponent,
    ProductInfoComponent,
    SortByPipe,
    ProductionPlanCardComponent,
    ProductionPlanProductCardComponent,
    ProductionPlanProductMixCardComponent,
    ProductionPlanProductMethodCardComponent,
    ProductionPlanProductMeasuresCardComponent,
    ProductionPlanRecipeLineCardComponent,
    ProductionPlanNoteComponent,
    NavigationCardComponent,
    DialogSelectComponent
  ],
  providers: [
    SortByPipe
  ],
  templateUrl: './production.component.html',
  styleUrl: './production.component.scss',
})
export class ProductionComponent implements OnInit, OnDestroy, AfterViewInit {
  // Children
  @ViewChild('productionHeaderDiv') productionHeader!: HTMLDivElement;

  // Services
  dataService: DataService = inject(DataService);
  vibrationService: VibrationService = inject(VibrationService);
  router: Router = inject(Router);
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  productionService: ProductionService | ProductionServiceMock = environment.mock ? inject(ProductionServiceMock) : inject(ProductionService);
  domSanitizer: DomSanitizer = inject(DomSanitizer);
  sortPipe: SortByPipe = inject(SortByPipe);
  printingService: PrintingService = inject(PrintingService);
  barcodeService: BarcodeService = inject(BarcodeService);
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Variables
  pageSourceName: string = 'production';
  userDetails!: UserDetails;
  loadingCardHeight: number = 111;

  // Variables - Production Locations
  productionLocations: ProductionLocation[] = [];
  selectedProductionLocation?: ProductionLocation;
  selectedProductionLocationId?: number;
  closableProductionLocationSelection: boolean = false;
  productionLocationsDialogSelect: DialogSelectModel = new DialogSelectModel({
    PageSourceName: this.pageSourceName,
    Identifier: 'productionLocations',
    Options: [],
    Loading: true,
    Title: 'Production.ProductionLocationSelection.Header',
    Visible: false,
    OptionLabel: 'Name',
    OptionValue: 'Id'
  });

  // Variables - Production Shifts
  productionShift!: ProductionShift;

  // Variables - Production Plans
  loadingProductionPlans: boolean = false;
  productionPlans: ProductionPlan[] = [];
  selectedProductionPlan?: ProductionPlan | null;
  showCompleteProductionPlansSwitchValue: boolean = false;

  // Variables - Production Items
  loadingProductionProducts: boolean = false;
  productionPlanProducts?: ProductionPlanProduct[];
  selectedProductionPlanProduct?: ProductionPlanProduct | null;
  showCompleteProductionPlanProductsSwitchValue: boolean = false;
  completingProdutcionPlanProduct: boolean = false;

  // Variables - Mixes
  loadingMixes: boolean = false;
  productionPlanProductMixes?: ProductionPlanProductMix[] = [];
  selectedProductionPlanProductMix?: ProductionPlanProductMix | null;
  showCompleteMixSwitchValue: boolean = false;

  // Variables - Recipe Lines
  loadingRecipeLines: boolean = false;
  productionPlanProductRecipeLines?: ProductionPlanItemRecipeLine[] = [];
  selectedProductionPlanProductRecipeLine?: ProductionPlanItemRecipeLine | null;
  showCompleteRecipeLineSwitchValue: boolean = false;
  completeRecipeLineConfirmationOptions?: DialogOptionsModel;

  // Variables - Methods
  loadingMethods: boolean = false;
  productionPlanProductMethods?: ProductionPlanProductRecipeMethod[];
  selectedProductionPlanProductMethod?: ProductionPlanProductRecipeMethod | null;

  // Variables - Recipe Measures
  loadingUsedLines: boolean = false;
  selectedProductionPlanProductRecipeLineUsedLines: ProductionUsedLine[] = [];
  usedLine?: PersistProductionUsedLineRequest | null;
  productWithinTolerance?: boolean = false;

  // Variables - Plan Notes
  productionPlanForNoteDialog?: ProductionPlan;

  // Variables - Product Info
  productionPlanProductInfoDialog?: ProductionPlanProduct;

  // Variables - Stock Lots
  selectedStockLot?: StockLot;
  selectedStockLotId?: number;
  stockLotsDialogSelect: DialogSelectModel = new DialogSelectModel({
    PageSourceName: this.pageSourceName,
    Identifier: 'stockLots',
    Options: [],
    Loading: true,
    Closable: true,
    DialogType: CyBakeConfirmationPrompTypeEnum.custom,
    Title: 'Production.StockLotSelection.Header',
    Visible: false,
    ListboxHeight: 400,
    OptionLabel: 'SystemLotNumber',
    OptionLabel2: 'FormattedExpiryDate',
    OptionValue: 'Id',
    FilterListbox: true,
    FilterListboxBy: 'Name,FormattedExpiryDate',
    AdditionalButton: new CyBakeButton({
      Type: ButtonTypeEnum.default,
      PageSourceName: this.pageSourceName,
      Identifier: 'stockLotScan',
      Class: ButtonClassEnum.action,
      TranslationKey: 'Production.StockLotSelection.ScanForStockLotBtn',
      IconKey: ['fa-duotone', 'fa-scanner-gun'],
    })
  });

  // Variables - Navigation Card
  breadcrumbItems: BreadcrumbItem[] = [];

  // Dialogs
  displayBarcodeScanner: boolean = false;
  displayStockLots: boolean = false;
  displayCompleteItem: boolean = false;
  displayPlanNote: boolean = false;
  displayProductInfo: boolean = false;

  // Complete Item Dialog
  completeItemDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: 'Production.CompleteItem.HeaderPrefix',
    Type: CyBakeConfirmationPrompTypeEnum.question,
    PageSourceName: this.pageSourceName,
    Identifier: 'completeItem',
  });

  // Barcode Button
  barcodeScannerButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'barcodeScanner',
    Class: ButtonClassEnum.default,
    Float: ButtonFloatEnum.bottomLeft,
    IconKey: ['fa-duotone', 'fa-scanner-gun'],
  });

  // Complete button
  completeButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'complete',
    Class: ButtonClassEnum.success,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  // Print Labels button
  printLabelsButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.output,
    PageSourceName: this.pageSourceName,
    Identifier: 'printLabels',
    Class: ButtonClassEnum.default,
    Rounded: true,
    IconClass: 'text-3xl',
  });

  // Production Locations Button
  productionLocationButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'productionLocation',
    Class: ButtonClassEnum.default,
    TranslationKey: 'Production.ProductionLocationsBtn',
    Prefix: 'Production.ProductionLocationsBtnPrefex',
    IconKey: ['fa-duotone', 'fa-location-dot'],
    Loading: true,
    TranslationFromData: false,
  });

  navbarTitle: NavbarTitle = new NavbarTitle({
    Title: 'Production.PlanHeader',
    PrefixSubTitle: 'Production.SubPlanHeaderPrefix',
    SubTitleFromData: true,
    Loading: true
  });

  // productionPlanDetailsPrompOptions

  productionPlanDetailsPrompOptions: DialogOptionsModel = new DialogOptionsModel({
    Type: CyBakeConfirmationPrompTypeEnum.info,
    PageSourceName: this.pageSourceName,
    Identifier: 'planDetails',
    Title: 'Production.ProductionPlanNote.Header',
    ConfirmButton: new CyBakeButton({
      PageSourceName: this.pageSourceName,
      TranslationKey: 'Production.ProductionPlanNote.CloseButton',
      Identifier: 'closePlanDetails',
      Type: ButtonTypeEnum.default,
      Class: ButtonClassEnum.default,
    }),
    ConfirmButtonMethod: new OutputFunction({
      MethodName: 'closePlanNote'
    }),
  });

  // Screen Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  // Subscriptions
  subscriptionConfirmationPromptOutputMethod!: Subscription;
  subscriptionToastOutputMethod!: Subscription;
  subscriptionBarcodeScanOutput!: Subscription;

  ngOnInit() {
    this.userDetails = this.localStorageService.getItem('user');
    this.dataService.updateNavbarTitle(this.navbarTitle);

    this.getCurrentProductionShift();

    // Breakpoint Observer
    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((state: BreakpointState) => {
        this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
        this.isMobileLandscape =
          state.breakpoints[Breakpoints.HandsetLandscape];
        this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
        this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
      });

    // Confirmation Prompt Output Method
    this.subscriptionConfirmationPromptOutputMethod =
      this.dataService.confirmationPromptOutputFunctionState.subscribe(
        (outputFunction: OutputFunction) => {
          // eslint-disable-next-line
          (this as any)[outputFunction.MethodName](outputFunction.Param);
        },
      );

    this.subscriptionToastOutputMethod =
      this.dataService.toastOutputFunctionState.subscribe(
        (outputFunction: OutputFunction) => {
          // eslint-disable-next-line
          (this as any)[outputFunction.MethodName](outputFunction.Param);
        },
      );

    // Barcode Scan Output
    this.subscriptionBarcodeScanOutput =
      this.dataService.barcodeOutputState.subscribe(
        (barcodeOutput: BarcodeData) => {
          if (this.stockLotsDialogSelect.Options.length) {
            const matchingStockLot: StockLot | undefined = (this.stockLotsDialogSelect.Options as StockLot[]).find((lot: StockLot) => lot.Id == barcodeOutput.Identifier);

            if (matchingStockLot) {
              if (this.usedLine) {
                this.usedLine.StockLotId = matchingStockLot!.Id;
                this.usedLine.SystemLotNumber = matchingStockLot!.SystemLotNumber;
                this.usedLine.ExpiryDate = matchingStockLot!.ExpiryDate;
                this.stockLotsDialogSelect.Visible = false;
                this.displayBarcodeScanner = false;
              }
            } else {
              this.getStockLotFromBarcode(barcodeOutput.Identifier!);
            }
          } else {
            this.getStockLotFromBarcode(barcodeOutput.Identifier!);
          }
        },
      );
  }

  ngAfterViewInit() {
    if (!environment.mock) {
      this.printingService.setup();
    }
  }

  // ---------------------- Current Production Shift ----------------------

  getCurrentProductionShift() {

    this.navbarTitle.Loading = true;
    this.dataService.updateNavbarTitle(this.navbarTitle);

    this.productionService.getCurrentProductionShift().subscribe({
      next: (getCurrentProductionShiftResponse: ProductionShift) => {

        if (!getCurrentProductionShiftResponse) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionShiftMsg',
            PageSourceName: this.pageSourceName,
            Identifier: 'noProductShift',
            Closable: false,
            Width: 25,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            })
          });

          this.dataService.openConfirmationPrompt(options);
          this.productionLocationButton.Loading = false;
          return;
        }

        this.productionShift = getCurrentProductionShiftResponse;
        this.getProductionLocations();

        const formattedStartDateTime = moment(this.productionShift.StartTime).format(`${this.userDetails.Settings.TimeFormat.Short}`,);
        const formattedEndDateTime = moment(this.productionShift.EndTime).format(`${this.userDetails.Settings.TimeFormat.Short}`,);

        this.navbarTitle.SubTitle = `${this.productionShift.Name} (${formattedStartDateTime} - ${formattedEndDateTime})`;
        this.navbarTitle.Loading = false;
        this.dataService.updateNavbarTitle(this.navbarTitle);
      },
      error: () => {
        this.navbarTitle.Loading = false;
        this.dataService.updateNavbarTitle(this.navbarTitle);

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionShift',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getCurrentProductionShift'
          })
        }))
      },
    });
  }

  // ---------------------- Production Locations ----------------------

  getProductionLocations() {
    this.productionLocationButton.Loading = true;
    this.productionLocationButton.Failed = false;

    this.productionService.getProductionLocations().subscribe({
      next: (getProductionLocationsResponse: ProductionLocation[]) => {

        this.productionLocations = getProductionLocationsResponse;
        this.productionLocationsDialogSelect.Options = getProductionLocationsResponse;

        // Check if any location are returned and if not prompt user to go back to dashboard. No other option.
        if (!this.productionLocations.length) {
          this.productionLocationButton.Loading = false;
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionLocationsMsg',
            Message: `Production.MissingDataMessage.NoProductionLocationsSubMsg`,
            MessageHtml: false,
            PageSourceName: this.pageSourceName,
            Identifier: 'noProductionLocations',
            Closable: false,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          return;
        };

        this.productionLocationsDialogSelect.Loading = false;

        // Find if production location has been set previously for user and set it
        this.selectedProductionLocation = (this.localStorageService.getItem('deviceProductionLocation')) as ProductionLocation;

        // LS - Added as error with local storage production location not in list
        if (this.selectedProductionLocation) {
          this.selectedProductionLocationId = this.selectedProductionLocation.Id;
          this.productionLocationButton.TranslationKey = `${this.selectedProductionLocation.Name}`;
          this.productionLocationButton.TranslationFromData = true;

          this.getCurrentProductionPlans();
        } else {
          // If no stored production location, force a selection
          this.openProductionLocations(true);
        }

        this.productionLocationButton.Loading = false;
      },
      error: () => {
        this.productionLocationButton.Loading = false;
        this.productionLocationsDialogSelect.Loading = false;
        this.productionLocationButton.Failed = true;
        this.productionLocationsDialogSelect.Failed = true;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionLocations',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getProductionLocations'
          })
        }))
      },
    });
  }

  // ---------------------- Get Production Plans ----------------------

  getCurrentProductionPlans() {

    this.loadingProductionPlans = true;

    this.productionService.getProductionPlans(this.selectedProductionLocation!.Id).subscribe({
      next: (getProductionPlansResponse: ProductionPlan[]) => {

        if (!getProductionPlansResponse.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionPlansMsg',
            PageSourceName: this.pageSourceName,
            Identifier: 'NoProductionPlans',
            Closable: false,
            Width: 40,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
            DeclineButtonMethod: new OutputFunction({
              MethodName: 'backToProductionLocations',
            }),
            DeclineButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToProductionPlansBtn',
              Identifier: 'backToProductionLocations',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          this.loadingProductionPlans = false;
          return;
        }

        const nonCompletePlans: ProductionPlan[] = getProductionPlansResponse.filter((plan: ProductionPlan) => {
          return plan.NumberOfLinesCompleted !== plan.TotalNumberOfLines;
        });

        if (!nonCompletePlans.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.success,
            Title: 'Production.AllProductionPlansCompleteMsg',
            PageSourceName: this.pageSourceName,
            Identifier: 'completeProductionPlans',
            Closable: false,
            Width: 40,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
            DeclineButtonMethod: new OutputFunction({
              MethodName: 'backToProductionLocations',
            }),
            DeclineButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToProductionPlansBtn',
              Identifier: 'backToProductionLocations',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          this.loadingProductionPlans = false;
          return;
        }

        getProductionPlansResponse.forEach((plan: ProductionPlan) => {
          plan.Completed = plan.TotalNumberOfLines === plan.NumberOfLinesCompleted ? true : false;
          plan.StartTime = moment('1970-01-01 ' + plan.StartTime).format(this.userDetails.Settings.TimeFormat.Short);
        });

        getProductionPlansResponse = this.sortPipe.transform(getProductionPlansResponse, 'StartTime');
        this.productionPlans = getProductionPlansResponse;
        this.loadingProductionPlans = false;
      },
      error: () => {
        this.loadingProductionPlans = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionPlans',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getCurrentProductionPlans'
          })
        }))
      },
    });
  }

  // ---------------------- Get Production Plan Products ----------------------

  getProductionPlanProducts() {
    this.loadingCardHeight = 130;
    this.loadingProductionProducts = true;

    this.productionService.getProductionPlanProducts(this.selectedProductionPlan!.Id).subscribe({
      next: (getProductionPlanProductsResponse: ProductionPlanProduct[]) => {

        this.productionPlanProducts = [];

        if (!getProductionPlanProductsResponse.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionPlanProductsMsg',
            TitleTranslationParams: { planName: this.selectedProductionPlan!.Name },
            Message: 'Production.MissingDataMessage.NoProductionPlanProductsSubMsg',
            MessageHtml: false,
            PageSourceName: this.pageSourceName,
            Identifier: 'NoProductionPlans',
            Closable: false,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToProductionPlans',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey: 'Production.MissingDataMessage.BackToProductionPlansBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.loadingProductionProducts = false;
          this.dataService.openConfirmationPrompt(options);
          return;
        }

        this.loadingProductionProducts = false;
        this.productionPlanProducts = getProductionPlanProductsResponse;

        this.productionPlanProducts.forEach((product: ProductionPlanProduct) => {
          this.getProductionPlanProductImage(product.ProductId, product);
        });

      },
      error: () => {
        this.loadingProductionProducts = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionPlanProducts',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getProductionPlanProducts'
          })
        }))
      },
    });
  }

  getProductionPlanProductImage(productId: number, product: ProductionPlanProduct) {
    product.ImageFailed = false;
    product.ImageLoading = true;

    this.productionService.getProductionPlanProductImage(productId).subscribe({
      next: (getProductionPlanProductImageResponse: Blob) => {
        if (getProductionPlanProductImageResponse.size) {
          const urlCreator = window.URL;
          const imageUrl = urlCreator.createObjectURL(getProductionPlanProductImageResponse);
          product.Image = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        }
        product.ImageLoading = false;
        product.ImageFailed = false;
      },
      error: () => {
        product.Image = undefined;
        product.ImageLoading = false;
        product.ImageFailed = true;
      },
    });
  }

  // ---------------------- Get Production Product Mixes ----------------------

  getProductionPlanProductMixes() {
    this.loadingCardHeight = 51;
    this.loadingMixes = true;

    this.productionPlanProductMixes = [];

    this.productionService.getProductionPlanProductMixes(this.selectedProductionPlan!.Id, this.selectedProductionPlanProduct!.ProductId).subscribe({
      next: (getProductionPlanProductMixesResponse: ProductionPlanProductMix[]) => {

        getProductionPlanProductMixesResponse.forEach((mix: ProductionPlanProductMix) => {
          mix.Completed = mix.TotalNumberOfLines === mix.NumberOfLinesCompleted ? true : false;
        });

        const incompleteMixes: ProductionPlanProductMix[] = getProductionPlanProductMixesResponse.filter((mix: ProductionPlanProductMix) => {
          return !mix.Completed
        })

        if (!incompleteMixes.length) {
          this.showCompleteMixSwitchValue = true;
        }

        this.productionPlanProductMixes = getProductionPlanProductMixesResponse;
        this.loadingMixes = false;
      },
      error: () => {
        this.loadingMixes = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionPlanProductMixes',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getProductionPlanProductMixes'
          })
        }))
      },
    });
  }

  // ---------------------- Get Production Product Recipe Lines ----------------------


  getProductionPlanMethodLines() {
    this.loadingCardHeight = 51;
    this.loadingMethods = true;

    this.productionPlanProductMethods = [];

    this.productionService.getProductionPlanMethodLines(this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id)).subscribe({
      next: (methods: ProductionPlanProductRecipeMethod[]) => {

        if (!methods.length) {
          this.loadingMethods = false;
          this.getProductionPlanProductRecipeLines();
        } else {
          this.productionPlanProductMethods = methods;

          this.productionPlanProductMethods.forEach((method: ProductionPlanProductRecipeMethod) => {

            if (method.FileIdentifier) {
              method.LoadingImage = true;
              this.getProductionPlanMethodImage(method.FileIdentifier, method);
            }
          });

          this.loadingMethods = false;
        }

      },
      error: () => {
        this.loadingMethods = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionPlanProductMethods',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getProductionPlanMethodLines'
          })
        }))
      },
    });
  }

  getProductionPlanMethodImage(image: string, method: ProductionPlanProductRecipeMethod) {
    method.ImageFailed = false;

    this.productionService.getProductionPlanMethodImage(image).subscribe({
      next: (getProductionPlanProductImageResponse: Blob) => {
        const urlCreator = window.URL;
        const imageUrl = urlCreator.createObjectURL(getProductionPlanProductImageResponse);
        method.Image = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        method.LoadingImage = false;
        method.ImageFailed = false;
      },
      error: () => {
        method.LoadingImage = false;
        method.ImageFailed = true;
      },
    });
  }

  getProductionPlanProductRecipeLines() {
    this.loadingCardHeight = 130;
    this.loadingRecipeLines = true;

    this.productionPlanProductRecipeLines = [];

    this.productionService.getProductionPlanRecipeLines(this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id)).subscribe({
      next: (recipeLines: ProductionPlanItemRecipeLine[]) => {

        if (!recipeLines.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionPlanProductRecipeLinesMsg',
            TitleTranslationParams: { productName: this.selectedProductionPlanProduct!.ProductName },
            Message: 'Production.MissingDataMessage.NoProductionPlanProductRecipeLinesSubMsg',
            MessageHtml: false,
            PageSourceName: this.pageSourceName,
            Identifier: 'NoProductionPlanProductRecipeLines',
            Closable: false,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToProductionPlan',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey: 'Production.MissingDataMessage.BackToProductionPlanProductsBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.loadingRecipeLines = false;
          this.dataService.openConfirmationPrompt(options);
          return;
        }

        const incompleteRecipeLines: ProductionPlanItemRecipeLine[] = recipeLines.filter((recipeLine: ProductionPlanItemRecipeLine) => {
          return !recipeLine.Completed
        })

        if (!incompleteRecipeLines.length) {
          this.showCompleteRecipeLineSwitchValue = true;
        }

        this.productionPlanProductRecipeLines = recipeLines;
        this.loadingRecipeLines = false;

        this.productionPlanProductRecipeLines.forEach((recipeLine: ProductionPlanItemRecipeLine) => {

          if (recipeLine.TotalQuantity) {
            //let stepAmount = Number(this.dataService.countDecimals(recipeLine.TotalQuantity));

            // RM - Trying to make step amount based on number of decimal places in TotalQuantiy but covid killed my brain and i can't figure it
            //recipeLine.StepAmount = stepAmount ? (1 / stepAmount) : 1;
            recipeLine.StepAmount = 1;
          }

          recipeLine.ImageLoading = true;
          this.getProductionPlanRecipeLineProductImage(recipeLine.ProductId, recipeLine);

        },
        );
      },
      error: () => {
        this.loadingRecipeLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetProductionPlanProductRecipeLines',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getProductionPlanProductRecipeLines'
          })
        }))
      },
    });
  }

  getProductionPlanRecipeLineProductImage(productId: number, recipeLine: ProductionPlanItemRecipeLine) {
    recipeLine.ImageFailed = false;

    this.productionService.getProductionPlanProductImage(productId).subscribe({
      next: (getProductionPlanProductImageResponse: Blob) => {
        if (getProductionPlanProductImageResponse.size) {
          const urlCreator = window.URL;
          const imageUrl = urlCreator.createObjectURL(getProductionPlanProductImageResponse);
          recipeLine.Image = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        }
        recipeLine.ImageLoading = false;
        recipeLine.ImageFailed = false;

      },
      error: () => {
        recipeLine.ImageLoading = false;
        recipeLine.ImageFailed = true;
      },
    });
  }

  // ---------------------- Local Functions ----------------------

  viewPlan(productionPlan: ProductionPlan) {
    this.selectedProductionPlan = productionPlan;
    this.breadcrumbItems.push({ TranslationKey: this.selectedProductionPlan.Name });
    this.getProductionPlanProducts();

    this.navbarTitle.Title = 'Production.PlanProductHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  backToDashboard() {
    this.dataService.closeConfirmationPrompt();
    this.router.navigateByUrl('/dashboard');
  }

  backToProductionLocations() {
    this.dataService.closeConfirmationPrompt();
    this.selectedProductionPlan = null;
    this.navbarTitle.Title = 'Production.PlanHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
    this.openProductionLocations(true);
  }

  backToProductionPlans() {
    this.dataService.closeConfirmationPrompt();
    this.selectedProductionPlan = null;
    this.navbarTitle.Title = 'Production.PlanHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
    return;
  }

  viewPlanNote(productionPlan: ProductionPlan) {
    this.productionPlanForNoteDialog = productionPlan;
    this.displayPlanNote = true;
  }

  viewProductInfo(product: ProductionPlanProduct) {
    this.productionPlanProductInfoDialog = product;
    this.displayProductInfo = true;
  }

  closePlanNote() {
    this.displayPlanNote = false;
  }

  confirmProductionLocation() {
    this.selectedProductionLocation = (this.productionLocationsDialogSelect.Options as ProductionLocation[]).find((location: ProductionLocation) => {
      return location.Id === this.selectedProductionLocationId;
    }) as ProductionLocation;

    this.localStorageService.setItem('deviceProductionLocation', this.selectedProductionLocation);

    this.productionLocationButton.TranslationKey = `${this.selectedProductionLocation!.Name}`;
    this.productionLocationButton.TranslationFromData = true;
    this.selectedProductionPlan = null;
    this.getCurrentProductionPlans();
  }

  viewRecipeMethod(method: ProductionPlanProductRecipeMethod) {

    this.loadingCardHeight = 130;
    this.loadingRecipeLines = true;

    this.productionService.getProductionPlanMethodLine(
      this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id), method).subscribe({
        next: (methodResponse: GetProductionPlanProductRecipeMethodLineResponse) => {

          this.selectedProductionPlanProductMethod = methodResponse.Method;
          this.productionPlanProductRecipeLines = methodResponse.RecipeLines;

          this.breadcrumbItems.push({
            TranslationKey: this.selectedProductionPlanProductMethod.Title,
            TranslationFromData: true
          });

          this.productionPlanProductRecipeLines.forEach((recipeLine: ProductionPlanItemRecipeLine) => {
            recipeLine.ImageLoading = true;
            this.getProductionPlanRecipeLineProductImage(recipeLine.ProductId, recipeLine);
          });

          const incompleteRecipeLines: ProductionPlanItemRecipeLine[] = this.productionPlanProductRecipeLines!.filter((recipeLine: ProductionPlanItemRecipeLine) => {
            return !recipeLine.Completed
          });

          if (!incompleteRecipeLines.length) {
            this.showCompleteRecipeLineSwitchValue = true;
          }

          this.loadingRecipeLines = false;

          if (this.productionPlanProductRecipeLines.length) {
            this.productionPlanProductRecipeLines.forEach((methodRecipeLine: ProductionPlanItemRecipeLine) => {
              if (methodRecipeLine.Image) {
                this.getProductionPlanRecipeLineProductImage(methodRecipeLine.ProductId, methodRecipeLine);
              }
            });
          }
        },
        error: () => {
          this.loadingRecipeLines = false;

          this.dataService.openToast(new ToastMessage({
            Severity: ToastSeverity.error,
            Detail: 'Production.ToastMessages.FailedToGetProductionPlanProductMethodRecipeLines',
            RetryOutputMethod: new OutputFunction({
              MethodName: 'viewRecipeMethod',
              Param: method
            })
          }))
        },
      });

    this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  backToProductionPlan() {

    // Close any open confirmation prompts on any step
    this.dataService.closeConfirmationPrompt();

    // Selected Recipe Line Step
    if (this.selectedProductionPlanProductRecipeLine) {
      this.usedLine = undefined;
      this.selectedProductionPlanProductRecipeLine = null;
      this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);

      const incompleteRecipeLines: ProductionPlanItemRecipeLine[] = (this.productionPlanProductRecipeLines!).filter((recipeLine: ProductionPlanItemRecipeLine) => {
        return !recipeLine.Completed
      })

      if (!incompleteRecipeLines.length) {
        this.showCompleteRecipeLineSwitchValue = true;
      }

      return;
    }

    // Selected Product Method Step
    if (this.selectedProductionPlanProductMethod) {
      this.selectedProductionPlanProductMethod = null;
      this.breadcrumbItems.pop();
      this.productionPlanProductRecipeLines = [];
      this.navbarTitle.Title = 'Production.SelectRecipeMethodHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
      return;
    }

    if (this.productionPlanProductMethods?.length) {
      this.productionPlanProductMethods = [];
      this.breadcrumbItems.pop();
      this.showCompleteMixSwitchValue = false;
      this.showCompleteRecipeLineSwitchValue = false;

      if (this.selectedProductionPlanProduct?.HasSubLines) {
        this.selectedProductionPlanProductMix = null;
        this.navbarTitle.Title = 'Production.PlanProductMixHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      } else {

        const incompleteProducts: ProductionPlanProduct[] = this.productionPlanProducts!.filter((product: ProductionPlanProduct) => {
          return !product.Completed && product !== this.selectedProductionPlanProduct
        });

        if (!incompleteProducts.length) {
          this.showCompleteProductionPlanProductsSwitchValue = true;
        }

        this.selectedProductionPlanProduct = null;
        this.navbarTitle.Title = 'Production.PlanProductHeader';

        this.dataService.updateNavbarTitle(this.navbarTitle);
      }

      return;
    }

    if (this.productionPlanProductRecipeLines?.length) {
      this.productionPlanProductRecipeLines = [];
      this.breadcrumbItems.pop();
      this.showCompleteMixSwitchValue = false;
      this.showCompleteRecipeLineSwitchValue = false;

      if (this.selectedProductionPlanProduct?.HasSubLines) {
        this.selectedProductionPlanProductMix = null;
        this.breadcrumbItems.pop();
        this.navbarTitle.Title = 'Production.PlanProductMixHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      } else {
        this.selectedProductionPlanProduct = null;
        this.navbarTitle.Title = 'Production.PlanProductHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      }

      return;
    }

    if (this.selectedProductionPlanProduct) {
      this.selectedProductionPlanProduct = null;
      this.showCompleteMixSwitchValue = false;
      this.showCompleteRecipeLineSwitchValue = false;
      this.breadcrumbItems.pop();
      this.navbarTitle.Title = 'Production.PlanProductHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
      return;
    }

    if (this.selectedProductionPlan) {
      this.selectedProductionPlan = null;
      this.breadcrumbItems = [];
      this.navbarTitle.Title = 'Production.PlanHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
      return;
    }
  }

  // ---------------------- Dialogs ----------------------

  openBarcodeScanner() {
    this.displayBarcodeScanner = true;
  }

  openProductionLocations(forceSelection?: boolean) {
    this.closableProductionLocationSelection = !forceSelection;
    this.productionLocationsDialogSelect.Visible = true;
  }

  // ---------------------- Recipe Line Functions ----------------------

  viewProductionPlanProduct(product: ProductionPlanProduct) {
    this.selectedProductionPlanProduct = product;
    this.loadingCardHeight = 51;

    if (product.HasSubLines) {
      this.loadingMixes = true;
      this.getProductionPlanProductMixes();

      this.breadcrumbItems.push({
        TranslationKey: (product.TotalNumberOfLines === 1 ? 'Production.BreadcrumbItems.SelectedProductionPlanProductWithMixBreadcrumbItem' : 'Production.BreadcrumbItems.SelectedProductionPlanProductWithMixesBreadcrumbItem'),
        TranslationParams: { productName: product.ProductName, productContainerDisplayName: (product.ProductContainerDisplayName ? ' - ' + product.ProductContainerDisplayName : ''), totalNumberOfLines: product.TotalNumberOfLines }
      });

      this.navbarTitle.Title = 'Production.PlanProductMixHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
    } else {
      this.loadingMethods = true;
      this.getProductionPlanMethodLines();

      this.breadcrumbItems.push({
        TranslationKey: (product.TotalNumberOfLines === 1 ? 'Production.BreadcrumbItems.SelectedProductionPlanProductBreadcrumbItem' : 'Production.BreadcrumbItems.SelectedProductionPlanProductWithItemsBreadcrumbItem'),
        TranslationParams: { productName: product.ProductName, productContainerDisplayName: (product.ProductContainerDisplayName ? ' - ' + product.ProductContainerDisplayName : ''), totalNumberOfLines: product.TotalNumberOfLines }
      });

      this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
    }
  }

  viewProductionPlanProductRecipeMix(mix: ProductionPlanProductMix) {
    this.selectedProductionPlanProductMix = mix;

    this.breadcrumbItems.push({
      TranslationKey: 'Production.BreadcrumbItems.SelectedMixBreadcrumbItem',
      TranslationParams: { mixNumber: mix.MixNumber }
    });

    this.getProductionPlanMethodLines();
    this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  viewProductionPlanProductRecipe(recipeLine: ProductionPlanItemRecipeLine) {

    this.loadingUsedLines = true;

    this.usedLine = new PersistProductionUsedLineRequest({
      Id: null,
      ProductionLineId: 0,
      RecipeLineId: 0,
      ProductId: 0,
      Quantity: 0,
      OriginalQuantity: 0,
      Measure: '',
      MeasureId: 0,
      StockLocationId: 1,
      StockLotId: null
    });

    this.selectedProductionPlanProductRecipeLine = recipeLine;

    this.productionService.getProductionPlanRecipeLine(this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id), recipeLine).subscribe({
      next: (getProductionPlanRecipeLineResponse: ProductionPlanItemRecipeLine) => {
        this.selectedProductionPlanProductRecipeLine = getProductionPlanRecipeLineResponse;
        this.selectedProductionPlanProductRecipeLine.StepAmount = recipeLine.StepAmount;

        this.getRecipeLineUsed();
        let stillRequiredAmount = this.selectedProductionPlanProductRecipeLine.TotalQuantity - this.selectedProductionPlanProductRecipeLine.QuantityUsed;

        if (stillRequiredAmount < 0) {
          stillRequiredAmount = 0;
        }

        this.usedLine = new PersistProductionUsedLineRequest({
          Id: null,
          ProductionLineId: this.selectedProductionPlanProductRecipeLine!.ProductionLineId,
          RecipeLineId: this.selectedProductionPlanProductRecipeLine!.Id,
          ProductId: this.selectedProductionPlanProductRecipeLine!.ProductId,
          Quantity: stillRequiredAmount,
          OriginalQuantity: stillRequiredAmount,
          Measure: this.selectedProductionPlanProductRecipeLine ? this.selectedProductionPlanProductRecipeLine.Measure : 'kg',
          MeasureId: this.selectedProductionPlanProductRecipeLine!.MeasureId,
          StockLocationId: 1,
          StockLotId: null
        });

        this.selectedProductionPlanProductRecipeLine.StillRequired = stillRequiredAmount;
        this.selectedProductionPlanProductRecipeLine.OriginalQuantityUsed = this.dataService.deepClone(this.selectedProductionPlanProductRecipeLine.QuantityUsed);

        this.navbarTitle.Title = 'Production.AddToRecipeLineHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
        //this.updateDial();

        this.navbarTitle.Title = 'Production.AddToRecipeLineHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);

        this.loadingUsedLines = false;
      },
      error: () => {
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetRecipeLine',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'viewProductionPlanProductRecipe',
            Param: recipeLine
          })
        }))
      },
    });
  }

  recipeLineWithinTolerance(recipeLine: ProductionPlanItemRecipeLine): boolean {
    const minErrorValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance * 2;
    const maxErrorValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance * 2;

    const minSuccessValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance;
    const maxSuccessValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance;

    const usedValue = recipeLine!.QuantityUsed;
    let productWithinTolerance: boolean = true;

    if (usedValue <= minErrorValueThreshold || usedValue >= maxErrorValueThreshold) {
      productWithinTolerance = false;
    }

    if (usedValue >= minSuccessValueThreshold && usedValue <= maxSuccessValueThreshold) {
      productWithinTolerance = true;
    }

    if ((usedValue > minErrorValueThreshold && usedValue < minSuccessValueThreshold) || (usedValue > maxSuccessValueThreshold && usedValue < maxErrorValueThreshold)) {
      productWithinTolerance = false;
    }

    return productWithinTolerance;
  }

  getRecipeLineUsed() {
    this.loadingUsedLines = true;

    this.productionService.getProductionPlanRecipeLineUsedLines(this.selectedProductionPlanProduct!.Id, this.selectedProductionPlanProductRecipeLine!.Id).subscribe({
      next: (usedLines: ProductionUsedLine[]) => {
        this.selectedProductionPlanProductRecipeLineUsedLines = usedLines;
        this.loadingUsedLines = false;
      },
      error: () => {
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetUsedInLinesForRecipeLine',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'getRecipeLineUsed'
          })
        }))
      },
    });
  }

  addRecipeLineUsed() {

    let stillRequiredAmount: number = 0;
    if (this.selectedProductionPlanProductRecipeLine) {
      stillRequiredAmount = this.selectedProductionPlanProductRecipeLine?.TotalQuantity - this.selectedProductionPlanProductRecipeLine.QuantityUsed;
    }

    if (stillRequiredAmount < 0) {
      stillRequiredAmount = 0;
    }

    this.usedLine = new PersistProductionUsedLineRequest({
      Id: null,
      ProductionLineId: this.selectedProductionPlanProductRecipeLine!.ProductionLineId,
      RecipeLineId: this.selectedProductionPlanProductRecipeLine!.Id,
      ProductId: this.selectedProductionPlanProductRecipeLine!.ProductId,
      Quantity: stillRequiredAmount,
      OriginalQuantity: stillRequiredAmount,
      Measure: this.selectedProductionPlanProductRecipeLine ? this.selectedProductionPlanProductRecipeLine.Measure : 'kg',
      MeasureId: this.selectedProductionPlanProductRecipeLine!.MeasureId,
      StockLocationId: 1,
      StockLotId: null
    });
  }

  editRecipeLineUsed(usedLine: ProductionUsedLine) {

    if (this.selectedProductionPlanProductRecipeLine!.Completed || usedLine.Completed) {
      return;
    }

    this.usedLine = new PersistProductionUsedLineRequest({
      Id: usedLine.Id,
      ProductionLineId: this.selectedProductionPlanProductRecipeLine!.ProductionLineId,
      RecipeLineId: this.selectedProductionPlanProductRecipeLine!.Id,
      ProductId: this.selectedProductionPlanProductRecipeLine!.ProductId,
      Quantity: usedLine.Quantity,
      OriginalQuantity: usedLine.Quantity,
      Measure: this.selectedProductionPlanProductRecipeLine ? this.selectedProductionPlanProductRecipeLine.Measure : 'kg',
      MeasureId: this.selectedProductionPlanProductRecipeLine!.MeasureId,
      StockLocationId: 1,
      StockLotId: usedLine.StockLotId,
      SystemLotNumber: usedLine.SystemLotNumber ? usedLine.SystemLotNumber : undefined
    });

    const stillRequiredAmount = this.selectedProductionPlanProductRecipeLine!.TotalQuantity - (this.selectedProductionPlanProductRecipeLine!.OriginalQuantityUsed - this.usedLine.Quantity);
    this.selectedProductionPlanProductRecipeLine!.StillRequired = stillRequiredAmount;
    this.selectedProductionPlanProductRecipeLine!.QuantityUsed = this.selectedProductionPlanProductRecipeLine!.OriginalQuantityUsed - this.usedLine.Quantity;

    //this.updateDial();
  }

  confirmAddEditRecipeLineUsed() {
    this.loadingUsedLines = true;

    if (this.usedLine?.Id) {
      // Update existing measure record  
      this.updateRecipeLineUsed();
    } else {
      // Create a new measure entry
      this.createRecipeLineUsed();
    }

  }

  createRecipeLineUsed() {
    this.loadingUsedLines = true;

    this.productionService.createProductionPlanRecipeLineUsedLine(this.usedLine).subscribe({
      next: () => {
        this.selectedStockLot = undefined;
        this.selectedProductionPlanProductRecipeLine!.QuantityUsed += this.usedLine!.Quantity;
        this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.success,
          Detail: 'Production.ProductionPlanRecipeLineCard.AddedMeasureLabel'
        }));
      },
      error: () => {
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToCreateUsedLine',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'createRecipeLineUsed'
          })
        }))
      },
    });
  }

  updateRecipeLineUsed() {
    this.loadingUsedLines = true;

    this.productionService.updateProductionPlanRecipeLineUsedLine(this.usedLine).subscribe({
      next: () => {

        this.selectedStockLot = undefined;
        this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);
        this.usedLine = null;
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.success,
          Detail: 'Production.ProductionPlanRecipeLineCard.UpdatedMeasureLabel'
        }));

      },
      error: () => {
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToUpdateUsedLine',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'updateRecipeLineUsed'
          })
        }))
      },
    });
  }

  removeMeasure() {

    this.loadingUsedLines = true;

    this.productionService.deleteProductionPlanRecipeLineUsedLine(this.selectedProductionPlanProductRecipeLine!.ProductionLineId, this.usedLine!.Id!).subscribe({
      next: () => {

        this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.success,
          Detail: 'Production.ProductionPlanRecipeLineCard.DeletedMeasureLabel'
        }));

      },
      error: () => {
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToDeleteUsedLine',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'removeMeasure'
          })
        }))
      },
    });
  }

  cancelAddingEditingMeasure() {
    this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);
  }

  // Show Complete Switch Functions

  checkCompleteProductionPlans(): boolean {
    if (this.productionPlans.length) {
      const completePlans: ProductionPlan[] = this.productionPlans.filter((plan: ProductionPlan) => {
        return plan.NumberOfLinesCompleted === plan.TotalNumberOfLines;
      },
      );

      return completePlans.length ? true : false;
    } else {
      return false;
    }
  }

  checkCompleteProductionPlanProducts(): boolean {
    if (this.productionPlanProducts?.length) {
      const completeProducts: ProductionPlanProduct[] = this.productionPlanProducts.filter(() => {
        return (
          this.selectedProductionPlanProduct!.TotalNumberOfLines -
          this.selectedProductionPlanProduct!.NumberOfLinesCompleted ===
          0
        );
      },
      );

      return completeProducts.length ? true : false;
    } else {
      return false;
    }
  }

  checkCompleteProductionPlanProductMixes(): boolean {
    if (this.productionPlanProductMixes?.length) {
      const completedMixes: ProductionPlanProductMix[] = this.productionPlanProductMixes.filter((mix: ProductionPlanProductMix) => {
        return mix.NumberOfLinesCompleted === mix.TotalNumberOfLines;
      },
      );

      return completedMixes.length ? true : false;
    } else {
      return false;
    }
  }

  checkCompleteProductionPlanRecipeLines(): boolean {
    if (this.productionPlanProductRecipeLines?.length) {
      const completeRecipeLines: ProductionPlanItemRecipeLine[] = this.productionPlanProductRecipeLines.filter((recipeLine: ProductionPlanItemRecipeLine) => {
        return recipeLine.Completed;
      },
      );

      return completeRecipeLines.length ? true : false;
    } else {
      return false;
    }
  }

  // Print Labels
  printLabels() {

    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.question,
      Title: 'Production.PrintLabels.Title',
      Message: `Production.PrintLabels.Message`,
      MessageTranslationParams: {
        productName: this.selectedProductionPlanProduct!.ProductName,
        productCode: this.selectedProductionPlanProduct!.ProductCode,
        totalNumberOfLines: this.selectedProductionPlanProduct!.TotalNumberOfLines,
        cybakeLot: '8472938462384', // RM - Hard-coded for example/demo for now
        expiryDate: moment().add(2, 'days').format(this.userDetails.Settings.DateFormat.MomentShort) // RM - Hard-coded for example/demo for now
      },
      PageSourceName: this.pageSourceName,
      Identifier: 'printLabels',
      ConfirmButtonMethod: new OutputFunction({
        MethodName: 'confirmPrintLabels',
      }),
      ConfirmButton: new CyBakeButton({
        PageSourceName: this.pageSourceName,
        TranslationKey: 'Production.PrintLabels.PrintLabelsButton',
        Identifier: 'location',
        Type: ButtonTypeEnum.output,
        Class: ButtonClassEnum.default,
      }),
    });

    this.dataService.openConfirmationPrompt(options);
  }

  confirmPrintLabels() {
    this.printingService.getZplData(this.selectedProductionPlanProduct!.StockLotId!).subscribe({
      next: (zplData: string) => {
        this.dataService.closeConfirmationPrompt();
        this.printingService.writeToSelectedPrinter(zplData);

      },
      error: () => {
        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetZplData',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'confirmPrintLabels'
          })
        }))
      },
    });
  }

  // Barcode

  getStockLotFromBarcode(stockLotId: number) {
    this.barcodeService.getStockLot(stockLotId).subscribe({
      next: (getStockLotResponse: BarcodeStockLotResponse) => {

        if (!getStockLotResponse.WithResult) {
          this.stockLotsDialogSelect.Visible = false;
          this.displayBarcodeScanner = false;

          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.ToastMessages.StockLotNotFound',
            PageSourceName: this.pageSourceName,
            Identifier: 'stockLotNotFound',
            AutoClose: true,
          });

          this.dataService.openConfirmationPrompt(options);
          return;
        }

        if (getStockLotResponse.ProductId !== this.selectedProductionPlanProductRecipeLine?.ProductId) {
          this.stockLotsDialogSelect.Visible = false;
          this.displayBarcodeScanner = false;

          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.ToastMessages.StockLotDoesntMatchProductId',
            PageSourceName: this.pageSourceName,
            Identifier: 'stockLotNotFoundForProduct',
            AutoClose: true,
          });

          this.dataService.openConfirmationPrompt(options);
          return;
        }

        if (this.usedLine) {
          this.usedLine!.StockLotId = getStockLotResponse!.Id;
          this.usedLine!.SystemLotNumber = getStockLotResponse!.SystemLotNumber;
          this.usedLine!.ExpiryDate = getStockLotResponse!.ExpiryDate;
        }

        this.stockLotsDialogSelect.Visible = false;
        this.displayBarcodeScanner = false;

      },
      error: () => {
        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetStockLotFromBarcode'
        }))
      },
    });
  }

  // Complete Actions

  completeAction() {
    this.displayCompleteItem = true;
  }

  confirmCompleteAction(quantityProduced?: number) {

    this.completingProdutcionPlanProduct = true;

    this.productionService.completeProductionPlanLineAsync(
      this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id), quantityProduced ?? 0).subscribe({
        next: (completeProductionPlanProductRecipeLineResponse: CompleteProductionPlanLineResponse) => {

          if (completeProductionPlanProductRecipeLineResponse.SystemLotNumber) {
            this.selectedProductionPlanProduct!.StockLotId = completeProductionPlanProductRecipeLineResponse.StockLotId
          }

          if (this.selectedProductionPlanProduct!.IsTraceable) {
            const options: DialogOptionsModel = new DialogOptionsModel({
              Type: CyBakeConfirmationPrompTypeEnum.success,
              Title: 'Production.CompletedProductionItem.Title',
              Message: `Production.PrintLabels.Message`,
              MessageTranslationParams: {
                productName: this.selectedProductionPlanProduct!.ProductName,
                productCode: this.selectedProductionPlanProduct!.ProductCode,
                totalNumberOfLines: this.selectedProductionPlanProduct!.TotalNumberOfLines,
                cybakeLot: completeProductionPlanProductRecipeLineResponse.SystemLotNumber,
                expiryDate: moment(completeProductionPlanProductRecipeLineResponse.ExpiryDate).format(this.userDetails.Settings.DateFormat.MomentShort)
              },
              PageSourceName: this.pageSourceName,
              Identifier: 'completedPlanProduct',
              ConfirmButtonMethod: new OutputFunction({
                MethodName: 'confirmPrintLabels',
              }),
              ConfirmButton: new CyBakeButton({
                PageSourceName: this.pageSourceName,
                TranslationKey: 'Production.CompletedProductionItem.PrintLabelsButton',
                Identifier: 'location',
                Type: ButtonTypeEnum.output,
                Class: ButtonClassEnum.default,
              }),
            });

            this.displayCompleteItem = false;
            this.dataService.openConfirmationPrompt(options);

            this.showCompleteRecipeLineSwitchValue = true;

            if (this.selectedProductionPlanProductMix) {
              this.selectedProductionPlanProductMix!.Completed = true;
            } else {
              this.selectedProductionPlanProduct!.Completed = true;
            }

            if (this.productionPlanProductMethods?.length) {
              if (this.selectedProductionPlanProductMethod) {
                this.selectedProductionPlanProductMethod.CompletedLines++;
              }
            }

          } else {
            const options: DialogOptionsModel = new DialogOptionsModel({
              Type: CyBakeConfirmationPrompTypeEnum.success,
              Title: 'Production.CompleteItem.SuccessfullyCompletedMessage',
              PageSourceName: this.pageSourceName,
              Identifier: 'confrimCompletedPlanProduct',
              AutoClose: true,
            });

            this.displayCompleteItem = false;
            this.dataService.openConfirmationPrompt(options);
            this.backToProductionPlan();
          }

          // Update complete number on production plan screen
          if (this.selectedProductionPlan) {
            const currentPlan: ProductionPlan = this.productionPlans!.find((plan: ProductionPlan) => {
              return plan.Id === this.selectedProductionPlan!.Id;
            }) as ProductionPlan;

            if (currentPlan) {
              currentPlan.NumberOfLinesCompleted++;
            }
          }

          this.completingProdutcionPlanProduct = false;
          this.getProductionPlanProducts();

        },
        error: () => {
          this.completingProdutcionPlanProduct = false;

          this.dataService.openToast(new ToastMessage({
            Severity: ToastSeverity.error,
            Detail: 'Production.ToastMessages.FailedToCompleteProductionPlanProduct'
          }))
        },
      });

  }

  productCanBeCompleted(): boolean {

    if (this.productionPlanProductMethods?.length) {

      const uncompletedItems: ProductionPlanProductRecipeMethod[] = this.productionPlanProductMethods!.filter((method: ProductionPlanProductRecipeMethod) => {
        return method.TotalLines !== method.CompletedLines;
      });

      return uncompletedItems.length ? false : true;

    } else {

      const uncompletedItems: ProductionPlanItemRecipeLine[] = this.productionPlanProductRecipeLines!.filter((recipeLine: ProductionPlanItemRecipeLine) => {
        return !recipeLine.Completed;
      },
      );

      return uncompletedItems.length ? false : true;
    }
  }

  incompleteProduct() {
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.warning,
      Title: 'Production.CompleteItem.IncompleteMessage',
      PageSourceName: this.pageSourceName,
      Identifier: 'incompleteRecipeLine',
      AutoClose: true,
    });

    this.dataService.openConfirmationPrompt(options);
  }

  incompleteRecipeLine() {
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.warning,
      Title: 'Production.CompleteRecipeLine.IncompleteMessage',
      PageSourceName: this.pageSourceName,
      Identifier: 'incompleteRecipeLine',
      AutoClose: true,
    });

    this.dataService.openConfirmationPrompt(options);
  }

  completeRecipeLine() {
    this.completeRecipeLineConfirmationOptions = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.question,
      Title: 'Production.CompleteRecipeLine.Message',
      Message: 'Production.CompleteRecipeLine.Message2',
      MessageTranslationParams: {
        itemName: this.selectedProductionPlanProductRecipeLine!.ProductName,
      },
      MessageHtml: false,
      PageSourceName: this.pageSourceName,
      Identifier: 'completeIngrediant',
      ConfirmButton: new CyBakeButton({
        PageSourceName: this.pageSourceName,
        TranslationKey: 'Production.CompleteItem.CompleteBtn',
        Identifier: 'completeConfirmation',
        Type: ButtonTypeEnum.default,
        Class: ButtonClassEnum.success,
      }),
      ConfirmButtonMethod: new OutputFunction({
        MethodName: 'confirmCompleteRecipeLine',
      }),
    });

    this.dataService.openConfirmationPrompt(this.completeRecipeLineConfirmationOptions);
  }

  confirmCompleteRecipeLine() {
    this.completeRecipeLineConfirmationOptions!.Loading = true;
    this.dataService.openConfirmationPrompt(this.completeRecipeLineConfirmationOptions!);

    this.productionService.completeProductionPlanProductRecipeLine(this.selectedProductionPlanProduct!.Id, this.selectedProductionPlanProductRecipeLine!.Id).subscribe({
      next: () => {

        this.getProductionPlanProductRecipeLines();

        if (this.selectedProductionPlanProductMix) {
          this.selectedProductionPlanProductMix!.NumberOfLinesCompleted++;
        }

        if (this.selectedProductionPlanProductMethod) {

          const currentRecipeMeethod: ProductionPlanProductRecipeMethod = this.productionPlanProductMethods!.find((method: ProductionPlanProductRecipeMethod) => {
            return method.Id === this.selectedProductionPlanProductMethod!.Id;
          },) as ProductionPlanProductRecipeMethod;

          if (currentRecipeMeethod) {
            currentRecipeMeethod.CompletedLines++;
          }

          this.selectedProductionPlanProductMethod!.CompletedLines++;
        }

        this.dataService.closeConfirmationPrompt();

        const options: DialogOptionsModel = new DialogOptionsModel({
          Type: CyBakeConfirmationPrompTypeEnum.success,
          Title: 'Production.CompleteItem.SuccessfullyCompletedMessage',
          PageSourceName: this.pageSourceName,
          Identifier: 'confrimCompletedPlanProduct',
          AutoClose: true,
        });

        this.dataService.openConfirmationPrompt(options);
        this.selectedProductionPlanProductRecipeLine = null;

      },
      error: () => {
        this.completeRecipeLineConfirmationOptions!.Loading = false;
        this.dataService.openConfirmationPrompt(this.completeRecipeLineConfirmationOptions!);

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToCompleteRecipeLine'
        }))
      },
    });
  }

  //Stock Lot Selection

  openStockLots() {
    this.stockLotsDialogSelect.Loading = true;
    this.stockLotsDialogSelect.Visible = true;

    this.productionService.getStockLots(this.selectedProductionPlan!.Id, this.selectedProductionPlanProductRecipeLine!.ProductId).subscribe({
      next: (stockLots: StockLot[]) => {

        stockLots.forEach((stockLot: StockLot) => {
          stockLot.FormattedExpiryDate = moment(stockLot.ExpiryDate).format(
            this.userDetails.Settings.DateFormat.MomentShort,
          );
        });

        this.stockLotsDialogSelect.Options = stockLots;
        this.stockLotsDialogSelect.Loading = false;
      },
      error: () => {
        this.stockLotsDialogSelect.Loading = false;
        this.stockLotsDialogSelect.Failed = true;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.error,
          Detail: 'Production.ToastMessages.FailedToGetStockLots',
          RetryOutputMethod: new OutputFunction({
            MethodName: 'openStockLots'
          })
        }))
      },
    });
  }

  confirmStockLot() {
    this.selectedStockLot = (this.stockLotsDialogSelect.Options as StockLot[]).find((lot: StockLot) => {
      return lot.Id === this.selectedStockLotId;
    }) as StockLot;

    if (this.usedLine) {
      this.usedLine.StockLotId = this.selectedStockLot!.Id;
      this.usedLine.SystemLotNumber = this.selectedStockLot!.SystemLotNumber;
      this.usedLine.ExpiryDate = this.selectedStockLot!.ExpiryDate;
    }
  }

  ngOnDestroy() {
    if (this.subscriptionConfirmationPromptOutputMethod) {
      this.subscriptionConfirmationPromptOutputMethod.unsubscribe();
    }

    if (this.subscriptionToastOutputMethod) {
      this.subscriptionToastOutputMethod.unsubscribe();
    }

    if (this.subscriptionBarcodeScanOutput) {
      this.subscriptionBarcodeScanOutput.unsubscribe();
    }
  }
}
