import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeSwitchComponent } from '../_shared/components/switch/switch.component';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { CyBakeToggleComponent } from '../_shared/components/toggle/toggle.component';
import { ApplicationInfo } from '../_shared/models/common/app-info.model';
import { HammerModule } from '@angular/platform-browser';
import { SettingsDevComponent } from './settings-dev/settings-dev.component';
import { VibrationService } from '../_shared/services/common/vibration.service';

@Component({
  selector: 'cybake-factory-settings',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    CyBakeSwitchComponent,
    CyBakeButtonComponent,
    CyBakeToggleComponent,
    HammerModule,
    SettingsDevComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  // Services
  vibrationService = inject(VibrationService);

  // Variables
  pageSourceName: string = 'settings';
  applicationInformation!: ApplicationInfo;

  // Settings - Theme
  darkMode: boolean = false;
  avatarColour: string = 'blue';
  highContrast: boolean = false;
  fontSize: number = 2;
  leftHanded: boolean = false;

  // Settings - Feedback and Sounds
  sounds: boolean = true;
  hapticFeedback: boolean = true;
  vibrationAvailableOnDevice: boolean = false;

  // Dev Settings
  showDeveSettings: boolean = false;

  ngOnInit() {
    this.vibrationAvailableOnDevice =
      this.vibrationService.hasVibrationSupport();
  }

  setFontSize(fontSize: number) {
    this.fontSize = fontSize;

    switch (this.fontSize) {
      case 3:
        // Large
        document.documentElement.style.setProperty('--font-size', '18px');
        break;
      case 2:
        // Medium (normal)
        document.documentElement.style.setProperty('--font-size', '16px');
        break;
      case 1:
        // Small
        document.documentElement.style.setProperty('--font-size', '14px');
        break;
    }
  }

  clearSettings() {
    this.setFontSize(2);
  }

  updateUserSettings() {}

  openSettingsDev() {
    this.showDeveSettings = true;
  }
}
