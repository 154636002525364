import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Trolley } from '../../_shared/models/stock-transfers/product/trolley.model';
import { StockLocationType } from '../../_shared/models/stock-transfers/stocks/stock-location-types.model';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { TrolleyListComponent } from '../components/trolley-list/trolley-list.component';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { EditStockTransferLineData } from '../../_shared/models/stock-transfers/stocks/edit-lot-data.model';

@Component({
  selector: 'cybake-factory-select-transfer-trolley',
  standalone: true,
  imports: [
    TrolleyListComponent,
    TranslateModule,
    CyBakeButtonComponent
  ],
  templateUrl: './select-transfer-trolley.component.html',
  styleUrl: './select-transfer-trolley.component.scss'
})
export class SelectTransferTrolleyComponent {
  pageSourceName: string = 'selectTransferTrolley';

  @ViewChild(TrolleyListComponent) trolleyListComponent!: TrolleyListComponent;

  confirmButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'trolleyTransferNow',
    Class: ButtonClassEnum.default,
    TranslationKey: 'StockTransferTrolley.TransferNow',
    Disabled: true,
    FullWidth: true,
  });

  deleteAllButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'trolleyTransferNow',
    Class: ButtonClassEnum.danger,
    TranslationKey: 'StockTransferTrolley.DeleteMultipleItems',
    Disabled: false,
    FullWidth: true,
  });

  @Input() basketItems: Trolley[] = [];
  @Input() trolleyItems: Trolley[] = [];
  @Input() selectingMultipleStockLines : boolean = false;
  @Input() selectedLocation!: StockLocationType;
  @Output() continueEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editEvent: EventEmitter<EditStockTransferLineData> = new EventEmitter<EditStockTransferLineData>();



  continue() {
    this.continueEvent.emit(true);
  }

  editLot(lotData: EditStockTransferLineData) {
    this.editEvent.emit(lotData);
  }
 get returnSelectedItemsCount() : number {
   if (this.trolleyListComponent && this.trolleyListComponent?.selectedStockLines && this.trolleyListComponent?.selectedStockLines?.length) {
     return this.trolleyListComponent.selectedStockLines.length
   }

   return 0
  }

  deleteAll() {
    this.trolleyListComponent.deleteMultipleItems()
  }
}
