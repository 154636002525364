<cybake-factory-notes [note]="note"
                      [contentHeader]="productionPlanNoteHeader"
                      (closeEvent)="this.closeNoteEvent.emit()">
</cybake-factory-notes>

<ng-template #productionPlanNoteHeader>
  <div class="w-full inline-flex">
    <div class="pr-1">
      <strong>
        {{'Production.ProductionPlanNote.PlanNameLabel' | translate}}:
      </strong>
    </div>
    <div>{{ planName }}</div>
  </div>

  <div class="w-full inline-flex">
    <div class="pr-1">
      <strong>
        {{'Production.ProductionPlanNote.ShiftLabel' | translate}}:
      </strong>
    </div>
    <div>
      {{shiftName}}
    </div>
  </div>

  <div class="w-full inline-flex">
    <div class="pr-1">
      <strong>
        {{'Production.ProductionPlanNote.PlannedStartLabel' | translate}}:
      </strong>
    </div>
    <div>
      {{startTime}}
    </div>
  </div>

</ng-template>
