import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings } from "ng-recaptcha-2";
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HammerModule } from '@angular/platform-browser';
import 'hammerjs';
import { environment } from '../environments/environment';
import { PrimeNGConfig } from 'primeng/api';
import { HTTPInterceptor } from './_shared/services/common/_http-interceptor.service';

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
    return new MultiTranslateHttpLoader(_httpBackend, [
      { prefix: './app/_shared/components/barcode-scan/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/button/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/calendar/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/card/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/dial/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/dialog/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/dropdown/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/image/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/input-email/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/input-number/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/input-password/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/input-text/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/listbox/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/login-profile/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/message/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/progress-pie/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/slide-to-confirm/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/switch/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/table/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/tag/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/tile/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/toast/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/toggle/_translations/', suffix: '.json' },
      { prefix: './app/_shared/dialogs/add-app-to-home-screen/_translations/', suffix: '.json' },
      { prefix: './app/_shared/dialogs/app-info/_translations/', suffix: '.json' },
      { prefix: './app/_shared/dialogs/notes/_translations/', suffix: '.json' },
      { prefix: './app/_shared/dialogs/product-info/_translations/', suffix: '.json' },
      { prefix: './app/_shared/dialogs/stock-lot-selection/_translations/', suffix: '.json' },
      { prefix: './app/dashboard/_translations/', suffix: '.json' },
      { prefix: './app/despatch/_translations/', suffix: '.json' },
      { prefix: './app/goods-in/_translations/', suffix: '.json' },
      { prefix: './app/login/_translations/', suffix: '.json' },
      { prefix: './app/login/login-pin/_translations/', suffix: '.json' },
      { prefix: './app/login/login-profile-options/_translations/', suffix: '.json' },
      { prefix: './app/menu/_translations/', suffix: '.json' },
      { prefix: './app/navbar/_translations/', suffix: '.json' },
      { prefix: './app/picking/_translations/', suffix: '.json' },
      { prefix: './app/production/_translations/', suffix: '.json' },
      { prefix: './app/settings/_translations/', suffix: '.json' },
      { prefix: './app/stock-take/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/select-transfer-products/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/select-transfer-trolley/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/select-transfer-lots/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/components/lot-card/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/components/lots-list/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/components/trolley-list/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/select-transfer-destination/_translations/', suffix: '.json' },
      { prefix: './app/_shared/components/dialog-select/_translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/select-transfer-source/_translations/', suffix: '.json' },
      { prefix: './app/user-details/_translations/', suffix: '.json' },
      { prefix: './assets/_translations/', suffix: '.json' },
    ]);
}

const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
  primeConfig.ripple = true;
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [PrimeNGConfig],
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaSiteKey },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.captchaSiteKey,
      } as RecaptchaSettings,
    },
    importProvidersFrom(
      FormsModule,
      HammerModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
    ),
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Ld-uUwqAAAAAMbR8d73SNkMmnuILoKkIgD8oBjq" }
  ],
};
