<div class="cybake-page-background w-full h-full p-2">

  <!--Navigation Card-->
  <div #productionHeaderDiv
       class="pb-2"
       [ngClass]="{ 'inline-flex w-full': selectedProductionPlan }">

    @if (selectedProductionPlan) {

    <div class="w-full inline-flex">

      <div class="w-full">
        <cybake-factory-navigation-card [identifier]="'production'"
                                        [breadcrumbItems]="breadcrumbItems"
                                        (backButtonEvent)="backToProductionPlan()">
        </cybake-factory-navigation-card>
      </div>

      <!--Show Complete Items Switch - Production Plan Products-->
      @if (selectedProductionPlan && !selectedProductionPlanProduct) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteProductionPlanProductsSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }

      <!--Show Complete Items Switch - Production Plan Item Mix-->
      @if (selectedProductionPlan && selectedProductionPlanProduct && selectedProductionPlanProduct.HasSubLines && !selectedProductionPlanProductMix) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteMixSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }

      <!--Show Complete Items Switch - Production Plan Recipe Line-->
      @if (selectedProductionPlan && selectedProductionPlanProduct && (selectedProductionPlanProduct.HasSubLines ? selectedProductionPlanProductMix : true) && !selectedProductionPlanProductRecipeLine) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteRecipeLineSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }

      <!--Complete button - Non Mix Item Or Method Product-->
      @if (selectedProductionPlanProduct && !selectedProductionPlanProductMix && !selectedProductionPlanProductRecipeLine && !selectedProductionPlanProduct.HasSubLines && !selectedProductionPlanProduct.Completed && !selectedProductionPlanProductMethod) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="completeButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="!isMobileLandscape && !isMobilePortrait ? 'Production.CompleteBtn' : undefined"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class"
                               [iconClass]="completeButton.IconClass"
                               [bigIcon]="true"
                               [disabled]="!productCanBeCompleted() || (loadingMixes || loadingMethods || loadingRecipeLines)"
                               (clickEvent)="completeAction()"
                               (disabledClickEvent)="incompleteProduct()">
        </cybake-factory-button>
      </div>
      }

      <!--Complete Button - Mix-->
      @if (selectedProductionPlanProduct && !selectedProductionPlanProductRecipeLine && selectedProductionPlanProductMix && !selectedProductionPlanProductMix.Completed) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="completeButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="!isMobileLandscape && !isMobilePortrait ? 'Production.CompleteBtn' : undefined"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class"
                               [iconClass]="completeButton.IconClass"
                               [bigIcon]="true"
                               [disabled]="!productCanBeCompleted() || (loadingMixes || loadingMethods || loadingRecipeLines)"
                               (clickEvent)="completeAction()"
                               (disabledClickEvent)="incompleteProduct()">
        </cybake-factory-button>
      </div>
      }

      <!--Complete button - Recipe Line-->
      @if (selectedProductionPlanProductRecipeLine && !selectedProductionPlanProductRecipeLine.Completed) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="completeButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="(!isMobileLandscape && !isMobilePortrait) ? 'Production.CompleteBtn' : undefined"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class"
                               [iconClass]="completeButton.IconClass"
                               [bigIcon]="true"
                               [disabled]="!recipeLineWithinTolerance(selectedProductionPlanProductRecipeLine) || (loadingMixes || loadingMethods || loadingRecipeLines)"
                               (clickEvent)="completeRecipeLine()"
                               (disabledClickEvent)="incompleteRecipeLine()">
        </cybake-factory-button>
      </div>
      }

      <!--Print Button-->
      @if (selectedProductionPlanProduct) {
      @if ((selectedProductionPlanProduct!.Completed || selectedProductionPlanProductMix?.Completed) && selectedProductionPlanProduct!.IsTraceable && !selectedProductionPlanProductRecipeLine) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="printLabelsButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="!isMobileLandscape && !isMobilePortrait ? 'Production.PrintLabelsBtn' : undefined"
                               [identifier]="printLabelsButton.Identifier"
                               [class]="printLabelsButton.Class"
                               [iconClass]="printLabelsButton.IconClass"
                               [type]="printLabelsButton.Type"
                               [loading]="printLabelsButton.Loading"
                               [bigIcon]="true"
                               (clickEvent)="printLabels()">
        </cybake-factory-button>
      </div>
      }

      }
    </div>
    }

    <!--Production Location Button-->
    @if (!selectedProductionPlan) {
    <div class="w-full"
         [ngClass]="{ 'inline-flex': checkCompleteProductionPlans() }">
      <div class="w-full">
        <cybake-factory-button [translationKey]="selectedProductionLocation ? productionLocationButton.TranslationKey : ''"
                               [prefix]="isMobileLandscape || isTabletLandscape ? productionLocationButton.Prefix : undefined"
                               [translationFromData]="true"
                               [iconKey]="productionLocationButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="productionLocationButton.Identifier"
                               [class]="productionLocationButton.Class"
                               [fullWidth]="true"
                               [overrideCenterAlign]="true"
                               [loading]="productionLocationButton.Loading"
                               (clickEvent)="openProductionLocations()">
        </cybake-factory-button>
      </div>

      <!--Show Complete Items Switch - Production Plans-->
      @if (checkCompleteProductionPlans() && !selectedProductionPlan) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteProductionPlansSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }
    </div>
    }
  </div>

  <div class="overflow-y-auto p-2"
       style="height: calc(100% - 100px); margin-left: -.5rem; margin-right: -.5rem; overflow-x: hidden;">

    <!--List Of Production Plans-->
    @if (!selectedProductionPlan && !loadingProductionPlans) {
    @for ((plan of productionPlans | filterByBoolean:(!showCompleteProductionPlansSwitchValue ? showCompleteProductionPlansSwitchValue : null):'Completed');track plan;let index = $index) {
    <cybake-factory-production-plan-card [plan]="plan"
                                         [index]="index"
                                         (viewPlanNoteEvent)="viewPlanNote(plan)"
                                         (viewPlanEvent)="viewPlan(plan)">
    </cybake-factory-production-plan-card>
    }
    }

    <!--List of Products in selected Production Plan-->
    @if (productionPlanProducts?.length && selectedProductionPlan && !selectedProductionPlanProduct && !loadingProductionProducts) {
    @for ((product of productionPlanProducts | filterByBoolean:(!showCompleteProductionPlanProductsSwitchValue ? showCompleteProductionPlanProductsSwitchValue : null):'Completed');track product;let index = $index) {
    <cybake-factory-production-plan-card-product [product]="product"
                                                 [index]="index"
                                                 [image]="product.Image"
                                                 [imageLoading]="product.ImageLoading"
                                                 [imageFailed]="product.ImageFailed"
                                                 (viewProductInfoEvent)="viewProductInfo(product)"
                                                 (getProductionPlanProductImageEvent)="getProductionPlanProductImage(product.ProductId, product)"
                                                 (viewProductionPlanProductEvent)="viewProductionPlanProduct(product)">
    </cybake-factory-production-plan-card-product>
    }
    }

    <!--Mixes-->
    @if (selectedProductionPlanProduct && !selectedProductionPlanProductMix && selectedProductionPlanProduct.HasSubLines && !loadingMixes) {
    @for ((mix of productionPlanProductMixes | filterByBoolean:(!showCompleteMixSwitchValue ? showCompleteMixSwitchValue : null):'Completed');track mix;let index = $index) {
    <cybake-factory-production-plan-product-mix-card [mix]="mix"
                                                     [index]="index"
                                                     (viewProductionPlanProductRecipeMixEvent)="viewProductionPlanProductRecipeMix(mix)">
    </cybake-factory-production-plan-product-mix-card>
    }
    }

    <!--Methods-->
    @if (productionPlanProductMethods?.length && !selectedProductionPlanProductRecipeLine && !loadingRecipeLines) {
    @for ((method of productionPlanProductMethods | filter:selectedProductionPlanProductMethod?.Id:'Id');track method;let index = $index) {
    <cybake-factory-production-plan-product-method-card [method]="method"
                                                        [index]="index"
                                                        [selected]="selectedProductionPlanProductMethod?.Id === method.Id"
                                                        [loadingRecipeLines]="loadingRecipeLines"
                                                        (viewRecipeMethodEvent)="viewRecipeMethod(method)">
    </cybake-factory-production-plan-product-method-card>
    }
    }

    <!--Recipe Lines-->
    @if (productionPlanProductRecipeLines?.length || selectedProductionPlanProductMethod && !loadingRecipeLines) {
    @for ((recipeLine of productionPlanProductRecipeLines | filterByBoolean:(!showCompleteRecipeLineSwitchValue ? (!selectedProductionPlanProductRecipeLine ? showCompleteRecipeLineSwitchValue : null) : null):'Completed' | filter:selectedProductionPlanProductRecipeLine?.Id:'Id');track recipeLine;let index = $index) {
    <cybake-factory-production-plan-recipe-line-card [recipeLine]="selectedProductionPlanProductRecipeLine ? selectedProductionPlanProductRecipeLine : recipeLine"
                                                     [index]="index"
                                                     [usedLine]="selectedProductionPlanProductRecipeLine?.Id && !loadingUsedLines ? usedLine : undefined"
                                                     [stockLot]="selectedProductionPlanProductRecipeLine?.Id && !loadingUsedLines ? selectedStockLot : undefined"
                                                     [loadingUsedLines]="loadingUsedLines"
                                                     [selected]="selectedProductionPlanProductRecipeLine?.Id === recipeLine.Id"
                                                     [selectedMethod]="selectedProductionPlanProductMethod ? true : false"
                                                     (viewProductionPlanProductRecipeEvent)="viewProductionPlanProductRecipe(recipeLine)"
                                                     (openStockLotsEvent)="openStockLots()"
                                                     (confirmAddEditRecipeLineUsedEvent)="confirmAddEditRecipeLineUsed()"
                                                     (cancelAddingEditingMeasureEvent)="cancelAddingEditingMeasure()"
                                                     (removeMeasureEvent)="removeMeasure()">
    </cybake-factory-production-plan-recipe-line-card>
    }

    <!--Recorded Measures Card-->
    @if (selectedProductionPlanProductRecipeLine && selectedProductionPlanProductRecipeLineUsedLines.length) {
    <cybake-factory-production-plan-product-measures-card [measures]="selectedProductionPlanProductRecipeLineUsedLines"
                                                          [parentRecipeLine]="selectedProductionPlanProductRecipeLine"
                                                          [selectedUsedLineId]="usedLine!.Id"
                                                          (editRecipeLineUsedEvent)="editRecipeLineUsed($event)">
    </cybake-factory-production-plan-product-measures-card>
    }
    }

    <!--Loading Card - Plans, Product, Mixes, Methods, Normal Recipe Lines-->
    @if (loadingProductionPlans || loadingProductionProducts || loadingMixes  || loadingMethods || loadingRecipeLines) {
    <cybake-factory-card [pageSourceName]="pageSourceName"
                         [identifier]="'productionPlanLoadingCard'"
                         [loadingHeight]="loadingCardHeight"
                         [loading]="true">
    </cybake-factory-card>
    }

  </div>
</div>

<!--Floating Barcode Scan Button-->
@if (selectedProductionPlanProductRecipeLine && selectedProductionPlanProductRecipeLine.IsTraceable) {
<cybake-factory-button [translationKey]="barcodeScannerButton.TranslationKey"
                       [iconKey]="barcodeScannerButton.IconKey"
                       [pageSourceName]="barcodeScannerButton.PageSourceName"
                       [identifier]="barcodeScannerButton.Identifier"
                       [class]="barcodeScannerButton.Class"
                       [float]="barcodeScannerButton.Float"
                       [rounded]="true"
                       (clickEvent)="openBarcodeScanner()">
</cybake-factory-button>
}
<!--Dialog - Scan Barcode-->
<cybake-factory-dialog [title]="'BarcodeScanner.Header'"
                       [(visible)]="displayBarcodeScanner"
                       [identifier]="'barcodeScanProduction'"
                       [closable]="true"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate1">
  <ng-template #dialogContentTemplate1>
    <cybake-factory-barcode-scan></cybake-factory-barcode-scan>
  </ng-template>
</cybake-factory-dialog>

<!--Dialog - Production Locations Options-->
<cybake-factory-dialog-select [pageSourceName]="pageSourceName"
                              [identifier]="productionLocationsDialogSelect.Identifier"
                              [options]="productionLocationsDialogSelect.Options"
                              [title]="productionLocationsDialogSelect.Title"
                              [closable]="closableProductionLocationSelection"
                              [loading]="productionLocationsDialogSelect.Loading"
                              [failed]="productionLocationsDialogSelect.Failed"
                              [(visible)]="productionLocationsDialogSelect.Visible"
                              [(value)]="selectedProductionLocationId"
                              [optionLabel]="productionLocationsDialogSelect.OptionLabel"
                              [optionValue]="productionLocationsDialogSelect.OptionValue"
                              (confirmEvent)="confirmProductionLocation()">
</cybake-factory-dialog-select>


<!--Dialog - Stock Lots Options-->
<cybake-factory-dialog-select [pageSourceName]="pageSourceName"
                              [identifier]="stockLotsDialogSelect.Identifier"
                              [options]="stockLotsDialogSelect.Options"
                              [title]="stockLotsDialogSelect.Title"
                              [closable]="stockLotsDialogSelect.Closable"
                              [loading]="stockLotsDialogSelect.Loading"
                              [failed]="stockLotsDialogSelect.Failed"
                              [dialogType]="stockLotsDialogSelect.DialogType"
                              [listboxHeight]="stockLotsDialogSelect.ListboxHeight"
                              [(visible)]="stockLotsDialogSelect.Visible"
                              [(value)]="selectedStockLotId"
                              [filterListbox]="stockLotsDialogSelect.FilterListbox"
                              [optionLabel]="stockLotsDialogSelect.OptionLabel"
                              [optionLabel2]="stockLotsDialogSelect.OptionLabel2"
                              [optionValue]="stockLotsDialogSelect.OptionValue"
                              [filterListboxBy]="stockLotsDialogSelect.FilterListboxBy"
                              [additionalButton]="stockLotsDialogSelect.AdditionalButton"
                              (additionalButton1ClickEvent)="displayBarcodeScanner = true"
                              (confirmEvent)="confirmStockLot()">
</cybake-factory-dialog-select>

<!--Dialog - Complete Item (Product or Recipe Line)-->
<cybake-factory-dialog [title]="completeItemDialog.Title"
                       [type]="completeItemDialog.Type"
                       [(visible)]="displayCompleteItem"
                       [identifier]="completeItemDialog.Identifier"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate4">
  <ng-template #dialogContentTemplate4>
    <cybake-factory-complete-production-item [product]="selectedProductionPlanProduct!"
                                             [traceableItem]="selectedProductionPlanProduct!.IsTraceable ? true : false"
                                             [mixItem]="selectedProductionPlanProductMix ? true : false"
                                             [value]="selectedProductionPlanProduct!.TotalNumberOfLines"
                                             [loading]="completingProdutcionPlanProduct"
                                             (comfirmComplete)="confirmCompleteAction($event)">
    </cybake-factory-complete-production-item>
  </ng-template>
</cybake-factory-dialog>

<!--Dialog - Production Plan Note-->
<cybake-factory-dialog [titlePrefix]="'Production.ProductionPlanNote.Header'"
                       [type]="'info'"
                       [(visible)]="displayPlanNote"
                       [identifier]="'productionPlanNote'"
                       [width]="35"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <cybake-factory-production-plan-note [note]="productionPlanForNoteDialog!.Note!"
                                         [planName]="productionPlanForNoteDialog!.Name"
                                         [shiftName]="productionShift!.Name"
                                         [startTime]="productionPlanForNoteDialog!.StartTime"
                                         (closeNoteEvent)="displayPlanNote = false">
    </cybake-factory-production-plan-note>
  </ng-template>
</cybake-factory-dialog>

<!--Dialog - Production Plan Product Info-->
<cybake-factory-dialog [(visible)]="displayProductInfo"
                       [identifier]="'productionPlanProductInfo'"
                       [width]="35"
                       [dialogContentTemplate]="productionPlanProductInfo">
  <ng-template #productionPlanProductInfo>
    <cybake-factory-product-info [name]="productionPlanProductInfoDialog!.ProductName!"
                                 [code]="productionPlanProductInfoDialog!.ProductCode!"
                                 [isTraceable]="productionPlanProductInfoDialog!.IsTraceable!"
                                 [image]="productionPlanProductInfoDialog?.Image"
                                 (closeEvent)="displayProductInfo = false">
    </cybake-factory-product-info>
  </ng-template>

</cybake-factory-dialog>
