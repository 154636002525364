import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor',
  standalone: true,
  pure: false
})
export class FloorPipe implements PipeTransform
{
  transform(value: number): number {
    if (typeof value !== 'number') {
      console.warn('FloorPipe: Input value is not a number.');
      return value;
    }
    return Math.floor(value);
  }
}
