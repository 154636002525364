import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockTransferOptions } from '../../_shared/models/stock-transfers/stocks/stock-transfer-options.model';
import { StockTransferLotContainer } from '../../_shared/models/stock-transfers/product/container.model';
import { Trolley } from '../../_shared/models/stock-transfers/product/trolley.model';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { LotCardComponent } from '../components/lot-card/lot-card.component';
import { LotsListComponent } from '../components/lots-list/lots-list.component';
import { TrolleyItem } from '../../_shared/models/stock-transfers/product/trolley-item.model';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';

@Component({
  selector: 'cybake-factory-select-transfer-lots',
  standalone: true,
  imports: [
    CyBakeCardComponent,
    LotCardComponent,
    LotsListComponent],
  templateUrl: './select-transfer-lots.component.html',
  styleUrl: './select-transfer-lots.component.scss'
})
export class SelectTransferLotsComponent implements OnInit {
  pageSourceName: string = 'selectTransferLots';


  @Input() stockTransferOptions?: StockTransferOptions;
  @Input() loadingContainers: boolean = false;
  @Input() containers: StockTransferLotContainer[] = [];
  @Input() basketLot?: Trolley;
  @Input() lots: TrolleyItem[] = [];
  @Input() basketItems: Trolley[] = [];
  
  // card component config
  locationCard!: CyBakeCard;

  loadingCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'lotLotsCard',
    Title: '',
    Loading: true
  });

  ngOnInit() {
    this.locationCard = new CyBakeCard({
      Title: this.stockTransferOptions?.selectedProduct?.Name || '',
      PageSourceName: this.pageSourceName,
      Identifier: 'lotProductCard',
      OverrideImageClick: true,
      ImageClass: 'vertically-center-left-align',
      SubTitle: this.stockTransferOptions?.selectedProduct?.Code || '',
      Image: this.stockTransferOptions?.selectedProduct?.ImageUrl || '',
      TagValue: this.stockTransferOptions?.selectedProduct?.IsTraceable ? ['StockTransferLots.TraceableLabel'] : undefined
    });
  }

  
  @Output() viewProductEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() transferEvent: EventEmitter<TrolleyItem> = new EventEmitter<TrolleyItem>();

  viewProductInfo(img: string) {
    this.viewProductEvent.emit(img);
  }

  handleValueChange(lot: TrolleyItem) {
    this.transferEvent.emit(lot);
  }

}
