<div
  id="{{ pageSourceName }}_{{ identifier }}_btn_container"
  class="{{ floatClass ? floatClass : 'h-full' }} cybake-button-container-div"
  [ngClass]="{ 'cybake-btn-pulse-primary': pulse }"
  pTooltip="{{ tooltipFromData ? tooltip : (tooltip | translate) }}"
  tooltipPosition="{{ tooltipPosition }}"
  [tooltipDisabled]="!tooltip"
  (click)="disabled ? this.disabledClickEvent.emit() : null">

  <button
    #cybakeButton
    id="{{pageSourceName}}_{{ identifier }}_btn"
    pButton
    cybake-text-style="body"
    pRipple
    style="padding: 0.25rem 0.5rem; font-size: 1.3em"
    class="btn p-button h-full cybake-btn button-{{ class }} {{customClass}} {{ fullWidth ? 'w-full' : '' }}  {{ countIndicatorValue ? 'overflkow-auto' : '' }} {{ floatButtonClass }} {{
      rounded ? 'rounded-full' : ''
    }} {{ raisedButton ? 'shadow-2' : '' }}"
    title="{{ prefixText ? (prefixText | translate) + ' ' + (translationKey ? translationFromData ? translationKey : (translationKey | translate) : '') : translationKey ? translationFromData ? translationKey : (translationKey | translate) : '' }}"
    [disabled]="loading || disabled"
    (click)="executeClickEvent()">

    <div id="{{ pageSourceName }}_{{ identifier }}_btn_div"
         class="cybake-button-div"
         [ngClass]="{
        'd-inline-flex': iconKey && !translationKey,
        'vertically-center h-full': !bigIcon,
        'm-auto': !overrideCenterAlign
      }">

      <!--Failed Icon-->
      @if (failed && !loading) {
      <i id="{{ pageSourceName }}_{{ identifier }}_btn_failed_icon"
         class="fa-icon c-danger fa-solid fa-triangle-exclamation me-2"
         pTooltip="{{ 'Button failed to load. Please try again' | translate }}"
         [escape]="true"
         [ngClass]="{ 'mr-2': translationKey }">
      </i>
      }
      <!--Loading Icon-->
      @if (loading) {
      <i id="{{ pageSourceName }}_{{ identifier }}_btn_loading_icon"
         class="fa-icon fa-regular fa-circle-notch fa-spin"
         [ngClass]="{ 'mr-2': translationKey }">
      </i>
      }

      <!--Button Icon-->
      @if (iconKey && !loading) {
      <i id="{{ pageSourceName }}_{{ identifier }}_btn_icon"
         class="fa-icon {{ iconClass }} {{ iconKey[0] }} {{ iconKey[1] }}"
         [ngClass]="{
            'mr-1': (translationKey || prefixText || prefix) && !bigIcon,
            'bigIcon-icon-size': bigIcon
          }">
      </i>
      }

      @if (prefix) {
      <span id="{{ pageSourceName }}_{{ identifier }}_btn_prefix_text"
            class="pr-1 cybake-button-prefix-text"
            [ngClass]="{ 'pl-1': iconKey }"
            cybake-text-style="body-small"
            [translate]="prefix">
      </span>
      }

      <!--Translation Key Text-->
      @if (translationKey || prefixText) {
      <div id="{{ pageSourceName }}_{{ identifier }}_btn_text_div"
           [ngClass]="{ 'text-center': bigIcon, 'vertically-center': !bigIcon }">

        <!--Prefix Text based on Button Type-->
        @if (prefixText) {
        <span id="{{ pageSourceName }}_{{ identifier }}_btn_autoPrefix_text"
              class="pe-1 vertically-center"
              [ngClass]="{ 'fs-08': bigIcon }"
              [translate]="prefixText"
              cybake-text-style="body-small">
        </span>
        }

        @if (translationKey) {
        <span id="{{ pageSourceName }}_{{ identifier }}_btn_text"
              class="button-translation-text"
              [ngClass]="{ 'fs-08': bigIcon }"
              cybake-text-style="body-small">
          {{prefixText ? translationFromData ? translationKey : (translationKey | translate: translationParams | lowercase) : translationFromData ? translationKey : (translationKey | translate: translationParams | sentenceCase)}}
        </span>
        }
      </div>
      }
    </div>
  </button>


  @if(countIndicatorValue){
  <span class="cart-count-indicator">
    {{countIndicatorValue}}
  </span>
  }
</div>
