import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductionPlanProductRecipeMethod } from '../../_shared/models/production/plans/production-plan-recipe-methods.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageComponent } from '../../_shared/components/image/image.component';
import { CyBakeProgressPieComponent } from '../../_shared/components/progress-pie/progress-pie.component';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';

@Component({
  selector: 'cybake-factory-production-plan-product-method-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ImageComponent,
    CyBakeProgressPieComponent,
    CyBakeCardComponent
  ],
  templateUrl: './production-plan-product-method.component.html',
  styleUrl: './production-plan-product-method.component.scss'
})
export class ProductionPlanProductMethodCardComponent {

  // Inputs/Outputs
  @Input() method!: ProductionPlanProductRecipeMethod;
  @Input() selected!: boolean;
  @Input() index!: number;
  @Input() loadingRecipeLines!: boolean;

  @Output() viewRecipeMethodEvent: EventEmitter<null> = new EventEmitter();

  // Variables
  pageSourceName: string = 'productionPlanProductMethodCard';

}
