import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Site } from '../models/sites/site.model';
import { GetSitesResponse } from '../models/sites/get-sites-response.model';
import { GetUserResponse } from '../models/user/get-user-response.model';
import { UserDetails } from '../models/user/user-details.model';
import moment from 'moment';
import { Settings } from '../models/settings/settings.model';
import { LocalStorageService } from './common/local-storage.service';

@Injectable({
  providedIn: 'root',
})

export class DashboardService {

  // Services
  http: HttpClient = inject(HttpClient);
  localStorageService: LocalStorageService = inject(LocalStorageService);

  // Service Calls
  getSitesForDashboard(): Observable<Site[]> {
    const url = 'api/dashboard/sites';

    return this.http.get<GetSitesResponse>(environment.api + url).pipe(
      map((getSitesResponse: GetSitesResponse) => {
        return getSitesResponse.Sites;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getUserDetails(): Observable<UserDetails> {
    if (this.localStorageService.getItem('user')) {
      return of(this.localStorageService.getItem('user'))
    }

    const url = `api/dashboard/user`;

    return this.http.get<GetUserResponse>(environment.api + url).pipe(
      map((getUserDetailsResponse: GetUserResponse) => {
        return this.logUserDetails(getUserDetailsResponse);
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  logUserDetails(userDetails: GetUserResponse): UserDetails {
    // get list of logged users from local storage
    let existingUsers: UserDetails[] = this.localStorageService.getItem('users');
    let newUserDetails: UserDetails;
    let existingUser: UserDetails;

    if (existingUsers) {

      // See if the user that just logged in exists in the existing users list
      existingUser = existingUsers.find((user: UserDetails) => {
        return user.UserId === userDetails.User.UserId;
      }) as UserDetails;

      // If it exists, log the user in local storage with previous settings
      // ELSE - create new user settings and then log user and add them to users list
      if (existingUser) {
        this.localStorageService.setItem('user', existingUser);
        return existingUser;
      } else {
        newUserDetails = this.setUserDetails(userDetails);
        existingUsers.push(newUserDetails);
        this.localStorageService.setItem('users', existingUsers);
        return newUserDetails;
      }

    } else {
      newUserDetails = this.setUserDetails(userDetails);
      existingUsers = [newUserDetails];
      this.localStorageService.setItem('users', existingUsers);
      return newUserDetails
    }
  }

  setUserDetails(loginDetails: GetUserResponse): UserDetails {
    const userDetails: UserDetails = {
      UserId: loginDetails.User.UserId,
      UserName: loginDetails.User.UserName,
      DisplayName: loginDetails.User.DisplayName,
      LastLogin: moment().toDate(),
      MasterCompanyId: loginDetails.User.MasterCompanyId,
      TimeZoneId: loginDetails.User.TimeZoneId,
      Settings: new Settings({
        ThemeId: 1,
        FontSizeId: 2,
        HighContrast: false,
        HapticFeedback: true,
        Sounds: true,
        DateFormat: loginDetails.CompanyDateFormat,
        TimeFormat: loginDetails.CompanyTimeFormat
      }),
    };

    this.localStorageService.setItem('user', userDetails);
    return userDetails;
  }
}
