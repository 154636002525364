import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BarcodeData } from '../models/barcode/barcode-data.model';
import { ReadBarcodeResponse } from '../models/barcode/read-barcode-response.model';
import { BarcodeTypeEnum } from '../models/barcode/barcode-type.enum';
import { GenerateBarcodeResponse } from '../models/barcode/generate-barcode-response.model';
import { BarcodeStockLotResponse } from '../models/barcode/barcode-stock-lot-response.model';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService {

  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls
  readBarcode(barcodeValue: string): Observable<BarcodeData> {
    const url = `api/barcode/read?barcodeValue=${barcodeValue}`;

    return this.http.get<ReadBarcodeResponse>(environment.api + url).pipe(
      map((getProductionLocationsResponse: ReadBarcodeResponse) => {
        return getProductionLocationsResponse.BarcodeData;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  generateBarcode(barcodeType: BarcodeTypeEnum, identifier: number): Observable<string> {
    const url = `api/barcode/generate?barcodeType=${barcodeType}&identifier=${identifier}`;

    return this.http.get<GenerateBarcodeResponse>(environment.api + url).pipe(
      map((getProductionLocationsResponse: GenerateBarcodeResponse) => {
        return getProductionLocationsResponse.BarcodeValue;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getStockLot(stockLotId: number): Observable<BarcodeStockLotResponse> {
    const url = `api/barcode/stocklot/${stockLotId}`;

    return this.http.get<BarcodeStockLotResponse>(environment.api + url).pipe(
      map((getStockLotResponse: BarcodeStockLotResponse) => {
        return getStockLotResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

}
