import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { CyBakeButtonComponent } from '../button/button.component';

@Component({
  selector: 'cybake-factory-input-number',
  standalone: true,
  imports: [
    InputNumberModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    CyBakeButtonComponent
  ],
  templateUrl: './input-number.component.html',
  styleUrl: './input-number.component.scss',
})
export class CyBakeInputNumberComponent {

  // Children
  @ViewChild('inputNumberDecreaseButton') decreaseBtn!: CyBakeButtonComponent;
  @ViewChild('inputNumberIncreaseButton') increaseBtn!: CyBakeButtonComponent;

  // Inputs/Outputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() value?: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() placeholder?: string;
  @Input() label?: string;
  @Input() suffix?: string;
  @Input() showSteppers: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled?: boolean;
  @Input() showClear: boolean = false;
  @Input() decimal: boolean = false;
  @Input() step: number = 1;
  @Input() min?: number;
  @Input() max?: number;
  @Input() minFractionDigits?: number;
  @Input() maxFractionDigits?: number;

  @Output() changeEvent: EventEmitter<number> = new EventEmitter();

  // Variables
  decreaseTimer: null | ReturnType<typeof setTimeout> = null;
  decreaseInterval: number = 0
  increaseTimer: null | ReturnType<typeof setTimeout> = null;
  increaseInterval: number = 0

  // Functions

  decreaseNumber() {

    if (this.value === this.min) {
      return;
    }

    if ((Number(this.value) - this.step) < 0) {
      this.value = 0;
      this.valueChange.emit(this.value);
      this.changeEvent.emit(this.value);
      return;
    }

    this.value = Number(this.value) - this.step;
    this.valueChange.emit(this.value);
    this.changeEvent.emit(this.value);

    this.decreaseTimer = setTimeout(() => {
      this.decreaseInterval++;
      this.decreaseNumber();
    }, (this.decreaseInterval < 15 ? 100 : (this.decreaseInterval < 30 ? 50 : (this.decreaseInterval < 50) ? 20 : 5)));
  }

  decreaseTimeoutClear() {
    this.decreaseInterval = 0;
    clearTimeout(this.decreaseTimer!);
  }

  increaseNumber() {

    if (this.value === this.max) {
      return;
    }

    this.value = Number(this.value) + this.step;
    this.valueChange.emit(this.value);
    this.changeEvent.emit(this.value);

    this.increaseTimer = setTimeout(() => {
      this.increaseInterval++;
      this.increaseNumber();
    }, (this.increaseInterval < 15 ? 100 : (this.increaseInterval < 30 ? 50 : (this.increaseInterval < 50) ? 20 : 5)));
  }

  increaseTimeoutClear() {
    this.increaseInterval = 0;
    clearTimeout(this.increaseTimer!);
  }

  // eslint-disable-next-line
  onInputFocus(event: any) {
    event.target!.select()
  }

}
