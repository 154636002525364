<div class="">
  <p-dialog [(visible)]="visible"
            [modal]="true"
            [style]="{ width: (width ? width : 30) + 'em' }"
            [maximizable]="maximizable"
            [baseZIndex]="99"
            [draggable]="true"
            [closable]="closable"
            [resizable]="resizable"
            [focusOnShow]="false"
            [transitionOptions]="'0ms'"
            [dismissableMask]="dismissableMask"
            (onHide)="this.visibleChange.emit(false); this.hideEvent.emit(true)"
            id="{{ identifier }}_dialog"
            styleClass="{{ identifier }}-dialog dialog-show {{fixedBottom ? 'dialog-fixed-bottom' : ''}}">

    <div>
      @if (cybakeAppIcon) {
      <div style="margin-top: 15px; margin-bottom: 15px">
        <div class="ios-app-icon m-auto">
          <div class="vertically-center">
            <img src="assets/images/cybake_bean_white.png"
                 alt="{{ 'General.CyBakeBeanAltText' | translate }}"
                 style="height: 50px" />
          </div>
        </div>
      </div>
      }

      @if (type) {
      <div class="pt-2" style="width: 80%; margin: auto">
        <!--Info Icon-->
        @if (type === 'info') {
        <div class="sa sa-icon sa-info sa-icon-show">
          <div class="sa-icon-content">i</div>
        </div>
        }

        <!--Success Icon-->
        @if (type === 'success') {
        <div class="sa">
          <div class="sa-success m-auto">
            <div class="sa-success-tip"></div>
            <div class="sa-success-long"></div>
            <div class="sa-success-placeholder"></div>
            <div class="sa-success-fix"></div>
          </div>
        </div>
        }

        <!--Error Icon-->
        @if (type === 'error') {
        <div class="sa">
          <div class="sa-error m-auto">
            <div class="sa-error-x">
              <div class="sa-error-left"></div>
              <div class="sa-error-right"></div>
            </div>
            <div class="sa-error-placeholder"></div>
            <div class="sa-error-fix"></div>
          </div>
        </div>

        }

        <!--Warning Icon-->
        @if (type === 'warning') {
        <div class="sa">
          <div class="sa-warning m-auto">
            <div class="sa-warning-body"></div>
            <div class="sa-warning-dot"></div>
          </div>
        </div>
        }

        <!--Question-->
        @if (type === 'question') {
        <div class="sa sa-icon sa-question sa-icon-show">
          <div class="sa-icon-content">?</div>
        </div>
        }
      </div>
      }

      <div class="pt-2"
           style="width: 80%; margin: auto"
           [ngClass]="{
          'pb-4': !message && !validation && !confirmButton && !declineButton
        }">
        <div class="vertically-center text-center text-3xl"
             style="position: relative">
          @if (titlePrefix) {
          <span>{{ titlePrefix | translate: titleTranslationParams }}</span>
          }

          {{
            !titleFromData ? (title | translate: titleTranslationParams) : title
          }}
        </div>
      </div>

      @if (message && messageHtml) {
      <div class="w-full pt-4">
        <div class="vertically-center-left-align pl-2 pr-2">
          <div class="text-xl" [innerHTML]="message | translate: messageTranslationParams"></div>
        </div>
      </div>
      }

      @if (message && !messageHtml) {
      <div class="w-full pt-4">
        <div class="vertically-center pl-2 pr-2">
          <div class="text-xl text-center">
            {{ message | translate: messageTranslationParams }}
          </div>
        </div>
      </div>
      }

      @if (validation) {
      <div class="w-full pt-4 mt-3"
           [ngClass]="{ 'pb-4': !confirmButton && !declineButton }">
        <cybake-factory-slide-to-confirm (comfirmed)="confirm()"
                                         [loading]="loading"></cybake-factory-slide-to-confirm>
      </div>
      }

      @if (confirmButton || declineButton) {
      <div class="w-full pt-4"
           [ngClass]="{ 'inline-flex': declineButton, 'mt-4': !message }">
        @if (declineButton) {
        <div class="w-full mr-2">
          <cybake-factory-button [pageSourceName]="declineButton.PageSourceName"
                                 [identifier]="declineButton.Identifier"
                                 [translationKey]="declineButton.TranslationKey"
                                 [type]="declineButton.Type"
                                 [class]="declineButton.Class"
                                 [iconKey]="declineButton.IconKey"
                                 [iconClass]="declineButton.IconClass"
                                 [fullWidth]="true"
                                 [loading]="declineButton.Loading"
                                 (clickEvent)="decline()">
          </cybake-factory-button>
        </div>
        }

        @if (confirmButton) {
        <div class="w-full" [ngClass]="{ 'ml-2': declineButton }">
          <cybake-factory-button [pageSourceName]="confirmButton.PageSourceName"
                                 [identifier]="confirmButton.Identifier"
                                 [translationKey]="confirmButton.TranslationKey"
                                 [type]="confirmButton.Type"
                                 [class]="confirmButton.Class"
                                 [iconKey]="confirmButton.IconKey"
                                 [iconClass]="confirmButton.IconClass"
                                 [fullWidth]="true"
                                 [loading]="confirmButton.Loading"
                                 (clickEvent)="confirm()">
          </cybake-factory-button>
        </div>
        }
      </div>
      }
    </div>

    <ng-container *ngTemplateOutlet="visible ? dialogContentTemplate : null"></ng-container>

  </p-dialog>
</div>
