import { CyBakeButton } from "../button/button.model";
import { CyBakeTemplate } from "../cybake-template.model";
import { CyBakeConfirmationPrompTypeEnum } from "../dialogs/confirmation-prompt/confirmation-prompt-type.enum";

export class DialogSelectModel extends CyBakeTemplate {

  Options!: unknown[];
  Title!: string;
  DialogType?: CyBakeConfirmationPrompTypeEnum | string;
  Visible!: boolean;
  Closable?: boolean;
  Value?: unknown;
  OptionValue?: string;
  OptionLabel?: string;  
  OptionLabel2?: string;  
  FilterListbox?: boolean = false;  
  FilterListboxBy?: string;  
  ListboxHeight?: number;
  DialogWidth?: number;
  AdditionalButton?: CyBakeButton;

  constructor(values: DialogSelectModel) {
    super(values);
    Object.assign(this, values);
  }
}
