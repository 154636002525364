import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductionPlanProduct } from '../../_shared/models/production/plans/production-plan-product.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeProgressPieComponent } from '../../_shared/components/progress-pie/progress-pie.component';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'cybake-factory-production-plan-card-product',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CyBakeCardComponent,
    CyBakeProgressPieComponent
  ],
  templateUrl: './production-plan-product.component.html',
  styleUrl: './production-plan-product.component.scss'
})
export class ProductionPlanProductCardComponent implements OnInit, OnChanges {

  // Inputs/Outputs
  @Input() product!: ProductionPlanProduct;
  @Input() image?: string | SafeResourceUrl;
  @Input() imageLoading?: boolean = true;
  @Input() imageFailed?: boolean = false;
  @Input() index!: number;

  @Output() viewProductInfoEvent: EventEmitter<null> = new EventEmitter();
  @Output() viewProductionPlanProductEvent: EventEmitter<null> = new EventEmitter();
  @Output() getProductionPlanProductImageEvent: EventEmitter<null> = new EventEmitter();

  // Variables
  pageSourceName: string = 'productionPlanProductCard';
  productionPlanProductCard!: CyBakeCard;

  ngOnInit() {
    this.productionPlanProductCard = new CyBakeCard({
      PageSourceName: this.pageSourceName,
      Identifier: 'productionPlanProduct' + this.product.ProductId,
      Title: this.product.ProductName,
      SubTitle: this.product.ProductCode + (this.product.ProductContainerDisplayName ? ' - ' + this.product.ProductContainerDisplayName : ''),
      Image: this.image,
      LoadingImage: this.imageLoading,
      FailedImage: this.imageFailed,
      ImageClass: 'vertically-center-left-align',
      TagValue: this.product.IsTraceable ? ['Production.ProductionPlanProductCard.TraceableLabel'] : undefined,
      RouterLink: true,
      OverrideImageClick: true
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['image'] && !changes['image'].firstChange) {
      this.productionPlanProductCard.Image = this.image;
    }

    if (changes['imageLoading'] && !changes['imageLoading'].firstChange) {
      this.productionPlanProductCard.LoadingImage = this.imageLoading;
    }

    if (changes['imageFailed'] && !changes['imageFailed'].firstChange) {
      this.productionPlanProductCard.FailedImage = this.imageFailed;
    }

  }
}
