 @if(showSearch){
  <div class="w-full  animate__animated animate__fadeIn">
    <cybake-factory-card [pageSourceName]="breadcrumbCard.PageSourceName"
                         [hideCardHeader]="breadcrumbCard.HideCardHeaderhideCardHeader"
                         [cardStyle]="breadcrumbCard.CardStyle"
                         [customClass]="breadcrumbCard.CustomClass"
                         [cardContentTemplate]="breadcrumbCardContentTemplate2"
                         [identifier]="breadcrumbCard.Identifier">
    </cybake-factory-card>
    <ng-template #breadcrumbCardContentTemplate2>
      <div class="w-full flex items-center">
        <cybake-factory-input-text class="w-full"
                                   [pageSourceName]="searchTextInput.PageSourceName"
                                   [identifier]="searchTextInput.Identifier"
                                   [fullWidth]="!!searchTextInput.FullWidth"
                                   (clearClickEvent)="searchEventFilter('')"
                                   [autofocus]="!!searchTextInput.Autofocus"
                                   [iconKey]="searchTextInput.IconKey"
                                   [translationKey]="searchTextInput.TranslationKey"
                                   (valueChange)="searchEventFilter( $event || '')"
                                   [placeholder]="!!searchTextInput.Placeholder">
        </cybake-factory-input-text>
      </div>
    </ng-template>
  </div>
}
@if(loadingProducts){
<div class="flex flex-col gap-2">
  @for ( item of [1,2,3,4,5,6,7,8,9,10,11,12]; track item ) {
  <cybake-factory-card [loading]="true"></cybake-factory-card>
  }
</div>
}
<div class="{{isPortraitMode?'page-portrait':'page-landscape'}}">

  @if (paginatedProducts.length){
  <div class="product-list flex flex-col gap-2"
       infinite-scroll
       [infiniteScrollDistance]="scrollDistance"
       [infiniteScrollThrottle]="scrollThrottle"
       [scrollWindow]="false"
       (scrolled)="updatePaginatedProducts()">

    @for ( product of paginatedProducts; track product.Code ) {
      <cybake-factory-card [title]="product?.Name || ''"
                           [subTitle]="product?.Code || ''"
                           [subSubTitle]="product?.Quantity?product?.Quantity + ' '+ product?.Measure:''"
                           [pageSourceName]="productCard.PageSourceName"
                           [image]="product?.ImageUrl || ''"
                           (clickEvent)="selectProduct(product)"
                           [selected]="!!(selectedProduct && selectedProduct === product?.Id)"
                           (longPressEvent)="viewProductInfo(product?.ImageUrl || '')"
                           (imageClickEvent)="viewProductInfo(product?.ImageUrl || '')"
                           [overrideImageClick]="productCard.OverrideImageClick"
                           [pImageClass]="productCard.ImageClass"
                           [identifier]="productCard.Identifier"
                           [tagValue]="product?.IsTraceable ? ['StockTransferProducts.TraceableLabel']: undefined ">
      </cybake-factory-card>
    }
  </div>
  }
</div>
   @if (searchText && !paginatedProducts.length){
    <div class="p-4 flex flex-col gap-2 justify-center align-items-center">
      <i class="fa-solid fa-magnifying-glass text-4xl"></i>
      <p cybake-text-style='body-bold'>
        {{ 'StockTransferProducts.NoSearchResults' | translate }}
      </p>
      <p cybake-text-style='body-xsmall'>
        {{ 'StockTransferProducts.CheckSpelling' | translate }}
      </p>
      <p></p>
    </div>
   }
<div class="fixed bottom-1 left-0 right-0 py-4 px-32">
  <cybake-factory-button [translationKey]="confirmButton.TranslationKey"
                         [identifier]="confirmButton.Identifier"
                         (clickEvent)="onContinue()"
                         [fullWidth]="!!confirmButton.FullWidth"
                         [disabled]="!!confirmButton.Disabled">
  </cybake-factory-button>
</div>
