@if (!loading) {
<div class="w-full h-full {{ class }}"
     [ngClass]="{
      'p-input-icon-right': showClear || maximumCharacters,
      'p-input-icon-left': iconKey,
      'p-float-label': translationKey
    }"
     pTooltip="{{ tooltip | translate }}"
     tooltipPosition="{{ tooltipPosition }}"
     [tooltipDisabled]="!tooltip">
  <!--Custom start Icon-->
  @if (iconKey) {
  <button (click)="this.iconClickEvent.emit(); (value ? showPassword = !showPassword : null)"
          class="vertically-center pl-2 pr-2"
          style="position: absolute"
          tabindex="0">

    <i id="{{pageSourceName}}_{{identifier}}_passwordInput_icon"
       class="fa-icon input-left-icon-with-right cursor-pointer {{iconClass}} {{value ? (showPassword ? 'fa-light fa-eye' : 'fa-light fa-eye-slash') : 'fa-light fa-lock'}}"
       tabindex="0"
       [ngClass]="{ 'model-required': required && !value }"
       pTooltip="{{iconTooltipTranslation | translate: iconTooltipTranslationParams}}"
       tooltipPosition="{{iconTooltipPosition ? iconTooltipPosition : 'right'}}"
       [escape]="false"
       [tooltipDisabled]="!iconTooltipTranslation"
       style="max-width: fit-content">
    </i>
  </button>
  }

  <!--Input-->
  <input #cybakeInput
         id="{{pageSourceName}}_{{identifier}}_passwordInput_input"
         pInputText
         class="h-full w-full cybake-input {{ inputClass }}"
         [type]="showPassword ? 'text' : 'password'"
         [(ngModel)]="value"
         (input)="this.valueChange.emit(value)"
         (keydown.enter)="this.enterEvent.emit()"
         autocomplete=""
         maxlength="{{ maximumCharacters }}"
         [ngClass]="{ 'model-required': required && !value }"
         placeholder="{{placeholder ? (translationKey ? (translationKey | translate: translationParams) : null) : null}}"
         [disabled]="disabled"
         onmousewheel="return false;"
         onwheel="return false;" />

  <!--Clear Icon-->
  @if (value && showClear && !maximumCharacters) {
  <button tabindex="0"
          (click)="value = null; this.clearClickEvent.emit()"
          class="pl-2 pr-2 h-full"
          style="position: absolute; right: 0">
    <i id="{{pageSourceName}}_{{identifier}}_search_input_clear"
       class="cursor-pointer input-right-icon input-clear-icon input-right-icon-with-left fa-icon fa-light fa-xmark">
    </i>
  </button>
  }

  @if (translationKey && !placeholder) {
  <label id="{{pageSourceName}}_{{identifier}}_input_label"
         for="{{pageSourceName}}_{{identifier}}_input"
         [ngClass]="{ 'model-required': required && !value }"
         [translate]="translationKey">
  </label>
  }
</div>
}

@if (loading) {
<p-skeleton id="{{pageSourceName}}_{{identifier}}_input_skeleton"
            styleClass="h-full w-full"
            width="100%"
            height="100%">
</p-skeleton>
}
