import { Component, Input, OnChanges, OnInit, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { environment } from '../../environments/environment';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { MessageService } from 'primeng/api';
import { DialogComponent } from '../_shared/components/dialog/dialog.component';
import { Site } from '../_shared/models/sites/site.model';
import { SettingsComponent } from '../settings/settings.component';
import { forkJoin } from 'rxjs';
import { ToastSeverity } from '../_shared/models/cybake/toast/toast-severity.enum';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogOptionsModel } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { DashboardService } from '../_shared/services/dashboard.service';
import { DashboardServiceMock } from '../_shared/services/mock-services/dashboard.service.mock';
import { DataService } from '../_shared/services/common/data.service';
import { LocalStorageService } from '../_shared/services/common/local-storage.service';
import { DialogSelectModel } from '../_shared/models/cybake/dialog-select/dialog-select.model';
import { DialogSelectComponent } from '../_shared/components/dialog-select/dialog-select.component';
import { SortByPipe } from '../_shared/pipes/sort-by.pipe';

@Component({
  selector: 'cybake-factory-user-details',
  standalone: true,
  imports: [
    AvatarModule,
    CardModule,
    TranslateModule,
    CyBakeButtonComponent,
    DialogComponent,
    SettingsComponent,
    SkeletonModule,
    CommonModule,
    DialogSelectComponent,
    SortByPipe
  ],
  providers: [
    SortByPipe
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
})
export class UserDetailsComponent implements OnInit, OnChanges {

  // Inputs/Outputs
  @Input() hasSites: boolean = false;
  @Input() refreshSites: boolean = false;
  @Input() loadingSites: boolean = false;

  // Services
  dashboardService: DashboardService | DashboardServiceMock = environment.mock ? inject(DashboardServiceMock) : inject(DashboardService);
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  translateService: TranslateService = inject(TranslateService);
  messageService: MessageService = inject(MessageService);
  localStorageService: LocalStorageService = inject(LocalStorageService);
  dataService: DataService = inject(DataService);
  sortPipe: SortByPipe = inject(SortByPipe);

  // Variables
  pageSourceName: string = 'userDetails';
  currentLocation: string = '';
  userDetails!: UserDetails;
  bottomSheetVisible: boolean = false;
  deviceSite?: Site;
  deviceSiteId?: number;

  // Location Button
  locationButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'location',
    Prefix: 'UserDetails.LoacationPrefixLabel',
    IconKey: ['fa-duotone', 'fa-location-dot'],
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.transparent,
    IconClass: 'text-xl',
    Loading: false,
  });

  // Locations Dialog
  userLocationsPrompOptions: DialogOptionsModel = new DialogOptionsModel({
    PageSourceName: this.pageSourceName,
    Identifier: 'selectSite',
    Type: CyBakeConfirmationPrompTypeEnum.question,
    Title: 'UserLocation.Header',
  });

  // Settings Button
  settingsButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'mainMenu',
    IconKey: ['fa-duotone', ' fa-gear'],
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.transparent,
    IconClass: 'text-xl',
    Loading: false,
  });

  // Locations/Sites Dialog
  sitesDialogSelect: DialogSelectModel = new DialogSelectModel({
    PageSourceName: this.pageSourceName,
    Identifier: 'userSite',
    Options: [],
    Loading: true,
    Title: 'UserLocation.Title',
    Visible: false,
    OptionLabel: 'Name',
    OptionValue: 'Id'
  });
  missingSetLocation: boolean = false;

  // Screen Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  ngOnInit() {
    this.getUserDetails();

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((state: BreakpointState) => {
        this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
        this.isMobileLandscape = state.breakpoints[Breakpoints.HandsetLandscape];
        this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
        this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
      });
  }

  ngOnChanges() {
    if (this.hasSites) {
      this.deviceSite = this.localStorageService.getItem('deviceSite');

      if (this.deviceSite) {
        this.deviceSiteId = this.deviceSite.Id;
      }

      if (this.hasSites && !this.deviceSite) {
        this.missingSetLocation = true;
        this.openLocations();
      }
    }
  }

  getUserDetails() {
    this.dashboardService.getUserDetails().subscribe({
      next: (getUserDetailsResponse: UserDetails) => {
        this.userDetails = getUserDetailsResponse;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: ToastSeverity.error,
            detail: translations[0],
            summary: translations[1],
          });
        });
      },
    });
  }

  getSites() {
    this.sitesDialogSelect.Loading = true;
    this.sitesDialogSelect.Failed = false;

    this.dashboardService.getSitesForDashboard().subscribe({
      next: (getSitesResponse: Site[]) => {
        getSitesResponse = this.sortPipe.transform(getSitesResponse, 'Sequence');
        this.sitesDialogSelect.Options = getSitesResponse;
        this.sitesDialogSelect.Loading = false;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: 'error',
            detail: translations[0],
            summary: translations[1],
          });
        });

        this.sitesDialogSelect.Loading = false;
        this.sitesDialogSelect.Failed = true;
      },
    });
  }

  comfirmLocation() {
    this.deviceSite = (this.sitesDialogSelect.Options as Site[]).find((site: Site) => site.Id == this.deviceSiteId) as Site;
    this.localStorageService.setItem('deviceSite', this.deviceSite);
  }

  openSettings() {
    this.dataService.openSettingsDialog(true);
  }

  openLocations() {
    this.getSites();
    this.sitesDialogSelect.Visible = true;
  }

  toggleBottomSheet() {
    this.bottomSheetVisible = !this.bottomSheetVisible;
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }
}
