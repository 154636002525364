import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NavbarTitle } from '../../models/navbar/navbar-title.model';
import { OutputFunction } from '../../models/common/output-function.model';
import { DialogOptionsModel } from '../../models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { Trolley } from '../../models/stock-transfers/product/trolley.model';
import { ToastMessage } from '../../models/cybake/toast/toast-message.model';
import { StockTransferOptions } from '../../models/stock-transfers/stocks/stock-transfer-options.model';
import { BarcodeData } from '../../models/barcode/barcode-data.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // Navbar Title
  private updateNavbarTitleSubject = new Subject<NavbarTitle>();
  updateNavbarTitleState = this.updateNavbarTitleSubject.asObservable();

  updateNavbarTitle(updateNavbarTitle: NavbarTitle) {
    this.updateNavbarTitleSubject.next(updateNavbarTitle);
  }

  private clearNavbarTitleSubject = new Subject();
  clearNavbarTitleState = this.clearNavbarTitleSubject.asObservable();

  clearNavbarTitle() {
    this.clearNavbarTitleSubject.next(null);
  }

  private outputFromNavbarTitleSubject = new Subject<OutputFunction>();
  outputFromNavbarTitleState = this.outputFromNavbarTitleSubject.asObservable();

  outputFromNavbarTitle(outputFromNavbarTitle: OutputFunction) {
    this.outputFromNavbarTitleSubject.next(outputFromNavbarTitle);
  }

  // Barcode Output
  private barcodeOutputSubject = new Subject<BarcodeData>();
  barcodeOutputState = this.barcodeOutputSubject.asObservable();

  barcodeOutput(barcodeOutput: BarcodeData) {
    this.barcodeOutputSubject.next(barcodeOutput);
  }

  // Toast Message
  private openToastSubject = new Subject<ToastMessage>();
  openToastState = this.openToastSubject.asObservable();

  openToast(toast: ToastMessage) {
    this.openToastSubject.next(toast);
  }

  private toastOutputFunctionSubject = new Subject<OutputFunction>();
  toastOutputFunctionState = this.toastOutputFunctionSubject.asObservable();

  toastOutputFunction(outputFunction: OutputFunction) {
    this.toastOutputFunctionSubject.next(outputFunction);
  }

  // Open Settings Dialog
  private openSettingsDialogSubject = new Subject<boolean>();
  openSettingsDialogState = this.openSettingsDialogSubject.asObservable();

  openSettingsDialog(show: boolean) {
    this.openSettingsDialogSubject.next(show);
  }

  // Open App Info Dialog
  private openAppInfoDialogSubject = new Subject<boolean>();
  openAppInfoDialogState = this.openAppInfoDialogSubject.asObservable();

  openAppInfoDialog(show: boolean) {
    this.openAppInfoDialogSubject.next(show);
  }

  // ------------------------- App Update Available -------------------------

  // Show Update Icon in navbar
  private showUpdateIconNavbarSubject = new Subject<boolean>();
  showUpdateIconNavbarState = this.showUpdateIconNavbarSubject.asObservable();

  showUpdateIconNavbar(showIcon: boolean) {
    this.showUpdateIconNavbarSubject.next(showIcon);
  }

  // Open Update Dialog
  private openUpdateDialogSubject = new Subject<boolean>();
  openUpdateDialogState = this.openUpdateDialogSubject.asObservable();

  openUpdateDialog(show: boolean) {
    this.openUpdateDialogSubject.next(show);
  }  

  // Open Add to Home Screen Dialog
  private showAddToHomeScreenSubject = new Subject<number>();
  showAddToHomeScreenState = this.showAddToHomeScreenSubject.asObservable();

  showAddToHomeScreen(device: number) {
    this.showAddToHomeScreenSubject.next(device);
  }

  // ------------------------- Confirmation Prompts -------------------------

  // Confirmation Prompt - Open
  private openConfirmationPromptSubject = new Subject<DialogOptionsModel>();
  openConfirmationPromptState =
    this.openConfirmationPromptSubject.asObservable();

  openConfirmationPrompt(toast: DialogOptionsModel) {
    this.openConfirmationPromptSubject.next(toast);
  }

  // Confirmation Prompt - Close
  private closeConfirmationPromptSubject = new Subject<boolean>();
  closeConfirmationPromptState =
    this.closeConfirmationPromptSubject.asObservable();

  closeConfirmationPrompt() {
    this.closeConfirmationPromptSubject.next(true);
  }

  // Confirmation Prompt - Output Function
  private confirmationPromptOutputFunctionSubject =
    new Subject<OutputFunction>();
  confirmationPromptOutputFunctionState =
    this.confirmationPromptOutputFunctionSubject.asObservable();

  confirmationPromptOutputFunction(outputFunction: OutputFunction) {
    this.confirmationPromptOutputFunctionSubject.next(outputFunction);
  }

  // ------------------------- Stock Transfer Lot Functions -------------------------

  private basketItemsSource = new BehaviorSubject<Trolley[] | null>(null);
  basketItems$ = this.basketItemsSource.asObservable();

  private stockTransferOptionsSource = new BehaviorSubject<StockTransferOptions | null>(null);
  stockTransferOptions$ = this.stockTransferOptionsSource.asObservable();

  updateStockTransferOptions(data: StockTransferOptions) {
    this.stockTransferOptionsSource.next(data);
  }

  updateBasketItemsSource(data: Trolley[]) {
    this.basketItemsSource.next(data);
  }

  // ------------------------- Common Functions -------------------------

  // RM - Deepclone will never know the class thus has to be any
  // eslint-disable-next-line
  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  countDecimals(value: number): string | number {

    if (Math.floor(value) === value) return 0;

    const str = value.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
      return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
      return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
  }

  rgbToHex(rgb: string): string {
    // Check if the input is in the correct format (e.g., "rgb(255, 0, 0)")
    const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (!match) {
      throw new Error('Invalid RGB format');
    }

    // Extract the individual colour values
    const [, r, g, b] = match;

    // Convert the colour values to hexadecimal format
    const hex = ((parseInt(r) << 16) | (parseInt(g) << 8) | parseInt(b)).toString(16);

    // Ensure that the hexadecimal value has 6 digits (padding with zeros if necessary)
    return "#" + ("000000" + hex).slice(-6);
  }
}
