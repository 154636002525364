<div class="w-full slide-right-animation"
     [ngClass]="{ 'pt-3': (index || selectedMethod) && !selected }">

  <cybake-factory-card [title]="recipeLine.ProductName"
                       [subTitle]="recipeLine.ProductCode"
                       [pageSourceName]="pageSourceName"
                       [image]="recipeLine.Image"
                       [loadingImage]="recipeLine.ImageLoading"
                       [identifier]="'productionPlanItemRecipeLine' + recipeLine.Id"
                       [pImageClass]="'vertically-center-left-align'"
                       [tagValue]="[recipeLine.IsTraceable ? 'Production.ProductionPlanProductCard.TraceableLabel': '']"
                       [routerLink]="!selected ? true : false"
                       [cardContentTemplate]="recipeLineCardContentTemplate"
                       [overrideClickStyling]="selected ? true : false"
                       (clickEvent)="!selected ? this.viewProductionPlanProductRecipeEvent.emit() : null">
  </cybake-factory-card>

  <ng-template #recipeLineCardContentTemplate>
    @if (!selected) {
    <div class="inline-flex w-full vertically-center-left-align text-lg">
      <div class="inline-flex w-full">
        <strong class="pr-1">
          {{'Production.ProductionPlanRecipeLineCard.RemainingLabel' | translate}}:
        </strong>
        <span class="pr-1">{{( ( ((recipeLine.TotalQuantity - recipeLine.QuantityUsed) >= 0 ) ? recipeLine.TotalQuantity - recipeLine.QuantityUsed : 0)  | number: '1.0-3')}}</span>{{ recipeLine.Measure }}
      </div>

      <div>
        <div class="vertically-center-right-align pr-3">
          <strong>{{ recipeLine.QuantityUsedPercentage | floor }}%</strong>
        </div>
      </div>

      <div class="float-end vertically-center-right-align">
        <cybake-factory-progress-pie [total]="100"
                                     [completed]="(recipeLine.QuantityUsedPercentage | floor)"
                                     [showCheckmark]="recipeLine.Completed">
        </cybake-factory-progress-pie>
      </div>
    </div>
    }

    @if (selected) {

    <div class="w-full pt-2">
      <cybake-factory-dial #recipleLineDial
                           [pageSourceName]="pageSourceName"
                           [identifier]="recipeLine.ProductName + '_mix'"
                           [value]="(usedLine ? usedLine!.Quantity : 0)"
                           [quantityUsed]="recipeLine!.QuantityUsed"
                           [measure]="recipeLine.Measure"
                           [complete]="recipeLine!.Completed"
                           [completeLabel]="'Production.ProductionPlanRecipeLineCard.CompletedLabel'"
                           [max]="recipeLine.TotalQuantity"
                           [min]="0"
                           [size]="300"
                           [loading]="loading || !recipeLine"
                           [valueColor]="recipeLineDialColour"
                           [rangeColor]="'SlateGray'"
                           [labelClass]="'text-3xl font-semibold'"
                           [readOnly]="false"
                           [label]="(recipeLine.Completed ? 'Production.ProductionPlanRecipeLineCard.TotalUsedLabel' : 'Production.ProductionPlanRecipeLineCard.StillRequiredLabel')"
                           [subLabel]="(recipeLine.Tolerance ? ('+/-' + recipeLine.Tolerance + ' ' + recipeLine.Measure) : '')"
                           [labelTranslationParams]="{requiredTotal: recipeLine.TotalQuantity, used: recipeLine.QuantityUsed, measure: recipeLine.Measure}"
                           (dialTouchChange)="updateDialTouchEvent($event)"
                           (dialCenterClick)="addRemainingRequiredAmount()"
                           (valueChange)="updateDialTouchEvent($event)"
                           (stepperValueChange)="updateDialSteppersEvent($event)">
      </cybake-factory-dial>

    </div>

    @if (!recipeLine!.Completed && usedLine) {

    @if (recipeLine.IsTraceable) {
    <div class="w-full pt-3"
         style="border-bottom: 1px solid var(--gray-300) !important">
      <cybake-factory-button [translationKey]="usedLine.SystemLotNumber ? usedLine.SystemLotNumber.toString() : 'Production.ProductionPlanRecipeLineCard.StockLotBtnPlaceholder'"
                             [iconKey]="stockLotsButton.IconKey"
                             [pageSourceName]="pageSourceName"
                             [identifier]="stockLotsButton.Identifier"
                             [class]="stockLotsButton.Class"
                             [fullWidth]="true"
                             [translationFromData]="stockLot || usedLine.SystemLotNumber ? true : false"
                             [loading]="stockLotsButton.Loading"
                             (clickEvent)="this.openStockLotsEvent.emit()">
      </cybake-factory-button>
    </div>
    }

    <div class="w-full pt-3">
      <cybake-factory-button [translationKey]="(usedLine.Id ? 'Production.ProductionPlanRecipeLineCard.ConfirmUpdateMeasureBtn' : confirmAddingMeasureButton.TranslationKey)"
                             [iconKey]="confirmAddingMeasureButton.IconKey"
                             [pageSourceName]="pageSourceName"
                             [identifier]="confirmAddingMeasureButton.Identifier"
                             [class]="confirmAddingMeasureButton.Class"
                             [type]="usedLine.Id ? defaultButtonType : confirmAddingMeasureButton.Type"
                             [fullWidth]="true"
                             [disabled]="(recipeLine.IsTraceable ? !usedLine!.StockLotId : false) || !usedLine!.Quantity || loading"
                             [loading]="confirmAddingMeasureButton.Loading"
                             (clickEvent)="this.confirmAddEditRecipeLineUsedEvent.emit()">
      </cybake-factory-button>
    </div>

    @if (usedLine.Id) {
    <div class="w-full inline-flex pt-3">
      <div class="w-full mr-1">
        <cybake-factory-button [translationKey]="cancelAddingMeasureButton.TranslationKey"
                               [iconKey]="cancelAddingMeasureButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="cancelAddingMeasureButton.Identifier"
                               [class]="cancelAddingMeasureButton.Class"
                               [fullWidth]="true"
                               [loading]="cancelAddingMeasureButton.Loading"
                               (clickEvent)="this.cancelAddingEditingMeasureEvent.emit()">
        </cybake-factory-button>
      </div>

      <div class="w-full ml-1">
        <cybake-factory-button [translationKey]="removeAddingMeasureButton.TranslationKey"
                               [iconKey]="removeAddingMeasureButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="removeAddingMeasureButton.Identifier"
                               [class]="removeAddingMeasureButton.Class"
                               [fullWidth]="true"
                               [loading]="removeAddingMeasureButton.Loading"
                               (clickEvent)="this.removeMeasureEvent.emit()">
        </cybake-factory-button>
      </div>
    </div>
    }
    }

    }
  </ng-template>
</div>
