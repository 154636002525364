import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeButtonComponent } from '../../../_shared/components/button/button.component';
import { Product } from '../../../_shared/models/stock-transfers/product/product.model';
import { StockTransferOptions } from '../../../_shared/models/stock-transfers/stocks/stock-transfer-options.model';
import { DataService } from '../../../_shared/services/common/data.service';
import { TrolleyItem } from '../../../_shared/models/stock-transfers/product/trolley-item.model';

@Component({
  selector: 'cybake-factory-trolley-card',
  standalone: true,
  imports: [CyBakeButtonComponent, TranslateModule],
  templateUrl: './trolley-card.component.html',
  styleUrl: './trolley-card.component.scss',
})
export class TrolleyCardComponent implements OnInit {
  // Inputs/Outputs
  @Input() lot!: TrolleyItem;
  @Output() editEvent: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() deleteEvent: EventEmitter<unknown> = new EventEmitter<unknown>();

  // Services
  dataService: DataService = inject(DataService);

  // Variables
  product!: Product;

  ngOnInit() {
    this.dataService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          if (stockTransferOptions?.selectedProduct) {
            this.product = stockTransferOptions?.selectedProduct;
          }
        }
      },
    );
  }

  editItem() {
    this.editEvent.emit();
  }

  deleteItem() {
    this.deleteEvent.emit();
  }
}
