import { CyBakeInput } from './input.model';

export class CyBakeInputText extends CyBakeInput {
  Value: string | null = null;
  Autofocus?: boolean = false;
  constructor(values: CyBakeInputText) {
    super(values);
    Object.assign(this, values);
  }
}
