 @if(showSearch){
<div class="w-full  animate__animated animate__fadeIn">
  <cybake-factory-card [pageSourceName]="breadcrumbCard.PageSourceName"
                       [hideCardHeader]="breadcrumbCard.HideCardHeaderhideCardHeader"
                       [cardStyle]="breadcrumbCard.CardStyle"
                       [customClass]="breadcrumbCard.CustomClass"
                       [cardContentTemplate]="breadcrumbCardContentTemplate2"
                       [identifier]="breadcrumbCard.Identifier">
  </cybake-factory-card>
  <ng-template #breadcrumbCardContentTemplate2>
    <div class="w-full flex items-center">
      <cybake-factory-input-text class="w-full"
                                 [pageSourceName]="searchTextInput.PageSourceName"
                                 [identifier]="searchTextInput.Identifier"
                                 [autofocus]="!!searchTextInput.Autofocus"
                                 [fullWidth]="!!searchTextInput.FullWidth"
                                 [iconKey]="searchTextInput.IconKey"
                                 (clearClickEvent)="searchText = ''"
                                 [translationKey]="searchTextInput.TranslationKey"
                                 (valueChange)="searchText = $event || ''"
                                 [placeholder]="!!searchTextInput.Placeholder">
      </cybake-factory-input-text>
    </div>

  </ng-template>
</div>
}

@if(loadingLocations){
<div class="flex flex-col gap-2">
  @for ( item of [1,2,3,4,5,6]; track item ) {
  <cybake-factory-card [loading]="true"></cybake-factory-card>
  }
</div>
}
<cybake-factory-accordion class="w-full"
                          [accordionTabs]="stockLocationTypes"
                          [searchText]="searchText"
                          [expandFirstTab]="stockLocationTypes.length === 1"
                          itemsKey="StockLocations"
                          [pageSourceName]="pageSourceName">
  <ng-template let-item
               let-pageSourceName="pageSourceName"
               let-identifier="identifier">
    @if(item.StockLocationProductIds?.length){
    <cybake-factory-card [pageSourceName]="locationCard.PageSourceName"
                         [title]="item?.Name || ''"
                         [customClass]="locationCard.CustomClass"
                         [selected]="selectedtransferLocation === item.Id"
                         (clickEvent)="selectLocation(item)"
                         [identifier]="locationCard.Identifier">
    </cybake-factory-card>
    }
  </ng-template>
</cybake-factory-accordion>
<div class="fixed bottom-1 left-0 right-0 py-4 px-32">
  <cybake-factory-button [translationKey]="confirmButton.TranslationKey"
                         (clickEvent)="onContinue()"
                         [fullWidth]="!!confirmButton.FullWidth"
                         [disabled]="!selectedtransferLocation">
  </cybake-factory-button>
</div>
