import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StockLocationType } from '../../_shared/models/stock-transfers/stocks/stock-location-types.model';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { StockLocation } from '../../_shared/models/stock-transfers/stocks/stock-location.model';
import { AccordionComponent } from '../../_shared/components-review/accordion/accordion.component';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { Trolley } from '../../_shared/models/stock-transfers/product/trolley.model';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';

@Component({
  selector: 'cybake-factory-select-transfer-destination',
  standalone: true,
  imports: [
    CyBakeButtonComponent,
    AccordionComponent,
    CyBakeCardComponent,
    TranslateModule
  ],
  templateUrl: './select-transfer-destination.component.html',
  styleUrl: './select-transfer-destination.component.scss'
})
export class SelectTransferDestinationComponent {
  pageSourceName: string = 'selectTransferDestination';

  // Add to continue button
  continueButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'destinationContinueBtn',
    Class: ButtonClassEnum.success,
    TranslationKey: 'StockTransferDestination.Confirm',
    FullWidth: true,
    Disabled: true
  });

  // card component config
  locationCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'selectLocationCard',
    Title: '',
    CustomClass: 'p-4'
  });
  // input to filter accepting locations only
  @Input() showAcceptingOnly: boolean = false; 
  @Input() stockLocationTypes: StockLocationType[] = [];
  @Input() basketItems: Trolley[] = [];
  @Input() loadingLocations: boolean = true;
  @Input() selectedtransferLocation?: number = 0;
  @Input() selectedtransferSource?: number = 0;
  @Output() selectLocationEvent: EventEmitter<StockLocation> = new EventEmitter<StockLocation>();
  @Output() continueEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectLocation(location: StockLocation) {
    this.selectLocationEvent.emit(location);
  }

  continue() {
    this.continueEvent.emit(true);
  }

  //Check if destination accepts all products or only specific products
  canTransferToLocation(stockLocation: StockLocation): boolean {
    // Check if AllProducts is true
    if (stockLocation.AllProducts) {
      return true;
    }

    // Extract ProductIds from trolley items
    const trolleyProductIds = this.basketItems.map(item => item.ProductId);

    // Check if any of the trolley ProductIds exist in StockLocationProductIds
    return trolleyProductIds.some(productId => stockLocation.StockLocationProductIds?.includes(productId ?? 0));
  }
}
