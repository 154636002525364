import { Component, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { CyBakeCardComponent } from '../../_shared/components/card/card.component';
import { CyBakeInputTextComponent } from '../../_shared/components/input-text/input-text.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Product } from '../../_shared/models/stock-transfers/product/product.model';
import { StockTransferOptions } from '../../_shared/models/stock-transfers/stocks/stock-transfer-options.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { CyBakeInputText } from '../../_shared/models/cybake/input/input-text.model';
import { CyBakeCard } from '../../_shared/models/cybake/card/card.model';
import { CardStyleEnum } from '../../_shared/models/cybake/card/card-style.enum';

@Component({
  selector: 'cybake-factory-select-transfer-products',
  standalone: true,
  imports: [
    CyBakeCardComponent,
    CyBakeButtonComponent,
    TranslateModule,
    CyBakeInputTextComponent,
    InfiniteScrollDirective
    ],
  templateUrl: './select-transfer-products.component.html',
  styleUrl: './select-transfer-products.component.scss'
})
export class SelectTransferProductsComponent {
  translateService: TranslateService = inject(TranslateService);
  pageSourceName: string = 'selectTransferProduct';
  searchText: string = '';

  // Infinite scroll config
  scrollDistance: number = 2;
  scrollThrottle: number = 50;

  // confirm button
  confirmButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'ConfirmBtn',
    Class: ButtonClassEnum.default,
    Disabled: true,
    FullWidth: true,
    TranslationKey: 'StockTransferProducts.Confirm',
  });

  // Text Input Model
  searchTextInput: CyBakeInputText = new CyBakeInputText({
    Value: null,
    PageSourceName: this.pageSourceName,
    Identifier: 'listboxSearch',
    TranslationKey: 'StockTransferProducts.SearchProduct',
    IconKey: ['fa-duotone', 'fa-magnifying-glass'],
    Placeholder: true,
    FullWidth: true,
    Autofocus: true
  });


  // card component config
  breadcrumbCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'selectTransferBreadcrumb',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light,
    HideCardHeaderhideCardHeader: true,
  });


  productCard: CyBakeCard = new CyBakeCard({
    PageSourceName: this.pageSourceName,
    Identifier: 'productCard',
    Title: '',
    OverrideImageClick: true,
    CustomClass: 'stock-count-path',
    CardStyle: CardStyleEnum.light,
    HideCardHeaderhideCardHeader: true,
    ImageClass: 'vertically-center-left-align',
  });

  @Input() showSearch: boolean = false;
  @Input() paginatedProducts: Product[] = [];
  @Input() loadingProducts: boolean = false;
  @Output() filterProductsEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() updatePaginationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectProductEvent: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() viewProductEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() continueEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() stockTransferOptions?: StockTransferOptions;
   isPortraitMode: boolean = false;
   selectedProduct!: number;


  searchEventFilter(searchText: string) {
    this.searchText = searchText;
    this.filterProductsEvent.emit(searchText);
  }
  viewProductInfo(img: string) {
    this.viewProductEvent.emit(img);
  }
  updatePaginatedProducts() {
    this.updatePaginationEvent.emit(true);
  }

  selectProduct(product: Product) {
    this.confirmButton.Disabled = false;
    this.selectedProduct = product?.Id;
    this.selectProductEvent.emit(product);
  }

  //event listeners to check page orientation; portrait or landscape

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkOrientation();
  }

  onContinue() {
    this.continueEvent.emit(true);
  }

  checkOrientation() {
    this.isPortraitMode = window.innerHeight > window.innerWidth;
  }

}
