import {
    AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { ImageComponent } from '../image/image.component';
import { Accordion, AccordionModule } from 'primeng/accordion';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import {
  LayoutModule,
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { HammerModule, SafeResourceUrl } from '@angular/platform-browser';
import { CyBakeTagComponent } from '../tag/tag.component';
import { SkeletonModule } from 'primeng/skeleton';
import { CardStyleEnum } from '../../models/cybake/card/card-style.enum';

@Component({
  selector: 'cybake-factory-card',
  standalone: true,
  imports: [
    ImageComponent,
    TranslateModule,
    CommonModule,
    AccordionModule,
    LayoutModule,
    HammerModule,
    CyBakeTagComponent,
    SkeletonModule
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CyBakeCardComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  // Children
  @ViewChild(Accordion) cardAccordion!: Accordion;
  @ViewChild('cardElement') cardElement!: ElementRef;

  // Inputs/Outputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() title: string = '';
  @Input() titleParams?: object;
  @Input() titleFromData?: boolean = false;

  @Input() subTitle?: string;
  @Input() subTitleFromData?: boolean = false;

  @Input() subSubTitle?: string;
  @Input() subSubTitleFromData?: boolean = false;

  @Input() toggleableContent?: boolean = false;
  @Input() openToggleableContent?: boolean = false;
  @Input() hideCardHeader?: boolean = false;
  @Input() fullHeight?: boolean = false;

  @Input() loading?: boolean = false;
  @Input() loadingHeight?: number = 50;

  @Input() tagValue?: string[];
  @Input() tagSeverity?: string[];

  @Input() routerLink?: string | boolean;

  @Input() image?: string | SafeResourceUrl;
  @Input() loadingImage?: boolean;
  @Input() failedImage?: boolean;
  @Input() pImageClass?: string;
  @Input() selected?: boolean = false;
  @Input() overrideImageClick?: boolean = false;
  @Input() overrideClickStyling?: boolean = false;

  @Input() disabled?: boolean = false;

  @Input() iconKey?: string[];

  @Input() customClass?: string;
  @Input() cardStyle?: CardStyleEnum = CardStyleEnum.default;

  @Input() cardTitleContentTemplate!: TemplateRef<unknown> | null;
  @Input() cardContentTemplate!: TemplateRef<unknown> | null;

  @Output() longPressEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() imageClickEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() imageFailedClickEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() cardHeight: EventEmitter<number> = new EventEmitter();

  // Services
  router: Router = inject(Router);
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  renderer: Renderer2 = inject(Renderer2);

  // Variables
  activeAccordionIndex: number[] = [];  
  elementHeight: number = 0;
  resizeObserver!: ResizeObserver;

  // Screen Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;
  isWeb: boolean = false;

  ngOnInit() {
    if (this.openToggleableContent) {
      this.activeAccordionIndex = [0];
    }

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.Web,
      ])
      .subscribe((state: BreakpointState) => {
        this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
        this.isMobileLandscape =
          state.breakpoints[Breakpoints.HandsetLandscape];
        this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
        this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
        this.isWeb = state.breakpoints[Breakpoints.Web];
      });
  }

  ngAfterViewInit() {
    // Accessing the initial height of the element
    this.updateHeight();

    // Initializing the ResizeObserver to watch for size changes
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === this.cardElement.nativeElement) {
          this.updateHeight();  // Update the height whenever the element's size changes
        }
      }
    });

    // Observing the element
    this.resizeObserver.observe(this.cardElement.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.activeAccordionIndex = changes['openToggleableContent'] ? changes['openToggleableContent']?.currentValue ? [0] : [] : [];
  }

  naviagteToRoute() {
    if (this.routerLink && typeof this.routerLink === 'string') {
      this.router.navigateByUrl(this.routerLink.toString());
    }
  }

  activeAccordionIndexChange(index: number) {
    this.activeAccordionIndex = [index];
  }

  toggleCardContent() {
    if (this.toggleableContent) {
      if (this.activeAccordionIndex.length) {
        this.activeAccordionIndex = [];
      } else {
        this.activeAccordionIndex = [0];
      }

      this.cardAccordion.activeIndex = this.activeAccordionIndex;
    }
  }

  cardLongPress() {
    this.longPressEvent.emit();
  }

  updateHeight() {
    this.cardHeight.emit(this.cardElement.nativeElement.offsetHeight);    
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
